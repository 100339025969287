import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import axios from 'axios';
import {
    GridLayout,
    GridLayoutItem,
} from "@progress/kendo-react-layout";
import {
    Chart,
    ChartArea,
    ChartSeries,
    ChartSeriesItem,
    ChartTooltip, ChartTitle, ChartCategoryAxisItem, ChartCategoryAxis, ChartLegend
} from "@progress/kendo-react-charts";
import { DropDownList  } from "@progress/kendo-react-dropdowns";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}


function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}


const FinanceCash = () => {
    const [CashA, setCashA] = useState([]);
    const [CashP, setCashP] = useState([]);
    const [department, setdepartment] = useState([]);
    const { height, width } = useWindowDimensions();
    const [lastmonth, setlastmonth] = useState();


    useEffect(() => {
        const fetchData = async () => {

            let body =
            {
                "bpname": "Dashboard (PL&CF)",
                "lineitem": "no",
                "filter_criteria": {
                    "join": "AND",
                    "filter": [
                        {
                            "field": "status",
                            "value": "Active",
                            "condition_type": "eq"
                        }
                    ]
                }
            }

            const res = await axios.post(`https://dash.dtsolution.kr/api/getbprecords?path=HW-01`, body)

            const filter = res.data.data.data.filter(v => v.PlanActual_srb === '실적' && v.yearMonth !== null && v.YearSPD !== null)
                .map((v) => ({ ...v, yearMonth: v.YearSPD + '-' + v.umuMonthPD }))

            const last = _.sortBy(filter, 'yearMonth').reverse()[0]['yearMonth']

            setlastmonth(last)

            const data = filter.filter(v => v.yearMonth === last).map((v) => (
                [
                    { month: '1월', 순차입금: v.NetDebt01, 사업부: v.Department_spd },
                    { month: '2월', 순차입금: v.NetDebt02, 사업부: v.Department_spd },
                    { month: '3월', 순차입금: v.NetDebt03, 사업부: v.Department_spd },
                    { month: '4월', 순차입금: v.NetDebt04, 사업부: v.Department_spd },
                    { month: '5월', 순차입금: v.NetDebt05, 사업부: v.Department_spd },
                    { month: '6월', 순차입금: v.NetDebt06, 사업부: v.Department_spd },
                    { month: '7월', 순차입금: v.NetDebt07, 사업부: v.Department_spd },
                    { month: '8월', 순차입금: v.NetDebt08, 사업부: v.Department_spd },
                    { month: '9월', 순차입금: v.NetDebt09, 사업부: v.Department_spd },
                    { month: '10월', 순차입금: v.NetDebt10, 사업부: v.Department_spd },
                    { month: '11월', 순차입금: v.NetDebt11, 사업부: v.Department_spd },
                    { month: '12월', 순차입금: v.NetDebt12, 사업부: v.Department_spd },
                ]
                // .map(function (obj) { var index = obj.사업부; obj[index] = obj.순차입금;delete obj['세전이익']; delete obj['사업부']; delete obj['순차입금'];  return obj; })


            )).flat()


            const filter2 = res.data.data.data.filter(v => v.PlanActual_srb === '사업계획')

            const data2 =

                filter2.map((v) => (
                    [
                        { month: '1월', 순차입금: v.NetDebt01, 사업부: v.Department_spd },
                        { month: '2월', 순차입금: v.NetDebt02, 사업부: v.Department_spd },
                        { month: '3월', 순차입금: v.NetDebt03, 사업부: v.Department_spd },
                        { month: '4월', 순차입금: v.NetDebt04, 사업부: v.Department_spd },
                        { month: '5월', 순차입금: v.NetDebt05, 사업부: v.Department_spd },
                        { month: '6월', 순차입금: v.NetDebt06, 사업부: v.Department_spd },
                        { month: '7월', 순차입금: v.NetDebt07, 사업부: v.Department_spd },
                        { month: '8월', 순차입금: v.NetDebt08, 사업부: v.Department_spd },
                        { month: '9월', 순차입금: v.NetDebt09, 사업부: v.Department_spd },
                        { month: '10월', 순차입금: v.NetDebt10, 사업부: v.Department_spd },
                        { month: '11월', 순차입금: v.NetDebt11, 사업부: v.Department_spd },
                        { month: '12월', 순차입금: v.NetDebt12, 사업부: v.Department_spd },
                    ]
                    // .map(function (obj) { var index = obj.사업부; obj[index] = obj.순차입금;delete obj['세전이익']; delete obj['사업부']; delete obj['순차입금'];  return obj; })


                )).flat()

            var Revenue1 =
                _(data)
                    .groupBy('month')
                    .map((objs, key) => ({
                        'month': key,
                        'array': objs.reduce((r, { 사업부, 순차입금, month }) => ({ [사업부]: 순차입금, 'month': month, ...r }), {}),

                    }))
                    .value();

            setCashA(Revenue1.map((v) => (v.array)).map((v) => ({ ...v, 기타: typeof v.조정값 !== 'undefined' ? v.기타 + v.조정값 : v.기타 })))

            var Revenue2 =
                _(data2)
                    .groupBy('month')
                    .map((objs, key) => ({
                        'month': key,
                        'array': objs.reduce((r, { 사업부, 순차입금, month }) => ({ [사업부]: 순차입금, 'month': month, ...r }), {}),

                    }))
                    .value()


            setCashP(Revenue2.map((v) => (v.array)).map((v) => ({ ...v, 기타: typeof v.조정값 !== 'undefined' ? v.기타 + v.조정값 : v.기타 })))


            setdepartment(
                _.uniqBy([...filter, ...filter2], 'Department_spd').map(v => v.Department_spd).filter(v => v !== '조정값').concat(['전사'])

            )

        }

        fetchData();
    }, []);

    const title1 = '순차입금 추정 (' + lastmonth + ")"

    const pbandNo = Number(lastmonth && lastmonth.slice(5, 7).slice(0, 1)) === 0 ? Number(lastmonth && lastmonth.slice(6, 7)) : Number(lastmonth && lastmonth.slice(5, 7))

    const categoryPlotBands = [{
        from: pbandNo - 1,
        to: pbandNo,
        color: '#f9f3ec',
        opacity: 0.6
    }];

    delete CashP.조정값
    delete CashA.조정값

    console.log(CashP)

    const cash_plan_all = CashP && CashP.map((v) => ({ month: v.month, 전사: _.sum(_.filter(Object.values(v), x => typeof x === 'number' && !isNaN(x))) }))
    .map(f => ({ ...f, ...CashP.find(sf => sf.month === f.month) }))
    const cash_actual_all = CashA && CashA.map((v) => ({ month: v.month, 전사: _.sum(_.filter(Object.values(v), x => typeof x === 'number'&& !isNaN(x))) }))
    .map(f => ({ ...f, ...CashA.find(sf => sf.month === f.month) }))


    console.log(cash_plan_all)
    console.log(cash_actual_all)


    console.log(CashP)

    const labelContentL = (e) =>
     e.value.toFixed(0) + '억';


     const seriesLabels = {
        visible: true,
        // Note that visible defaults to false
        // margin: { right: 140, bottom: 10 },
        font: "bold 0.8rem Arial, sans-serif",
        position: "left",
        background: "none",
        content: labelContentL,
    };

const seriesLabels2 = {
    visible: true,
    // Note that visible defaults to false
    // margin: { right: 140, bottom: 10 },
    font: "bold 0.8rem Arial, sans-serif",
    position: "right",
    background: "none",
    content: labelContentL,
};


const [value, setValue] = React.useState('전사');


const handleChange = (event) => {
    setValue(event.value);
  };


    return (<>
        <GridLayout
            style={{ width: width, height: height }}
            rows={[
                {
                    height: "7%",
                },                {
                    height: "93%",
                },



            ]}
            cols={[
                {
                    width: "100%",
                },
            ]}
            gap={{
                rows: 0,
                cols: 0,
            }}
        >

<GridLayoutItem row={1} col={1} style={{ position:'relative'}}>
<DropDownList 
        style={{
          width: "300px",
          position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'

        }}
        data={department}
        value={value}
        onChange={handleChange}
      />
</GridLayoutItem>
            <GridLayoutItem row={2} col={1} style={{ margin: "0 auto" }}>
                <Chart>
                    <ChartTitle text={title1} position="top" />
                    <ChartArea width={width} height={height * 0.7} margin={10} />
                    {/* <ChartTooltip shared={true} render={sharedTooltipRender} /> */}
                    {/* <ChartCategoryAxis>
                        <ChartCategoryAxisItem labels={serieslabelcategory} />
                        <ChartCategoryAxisItem labels={serieslabelcategory2} />

                    </ChartCategoryAxis> */}
                    <ChartLegend  position="bottom" orientation="horizontal"/>
                    <ChartCategoryAxis>
                        <ChartCategoryAxisItem plotBands={categoryPlotBands} />
                    </ChartCategoryAxis>
                    <ChartSeries>
                        <ChartSeriesItem
                            type="line"
                            data={cash_plan_all}
                            field={value ===null?'전사':value}
                            categoryField='month'
                            name='계획'

                        labels={seriesLabels}

                        />

                        <ChartSeriesItem
                            type="line"
                            data={cash_actual_all}
                            field={value ===null?'전사':value}
                            categoryField='month'
                            name='추정'
                            labels={seriesLabels2}

                        />

                    </ChartSeries>

                </Chart>



            </GridLayoutItem>
      
        </GridLayout>


    </>)

}

export default FinanceCash;
