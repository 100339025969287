import React, { useEffect, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import "hammerjs";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import { Card, CardTitle, CardBody, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import useBreakpoint from "use-breakpoint";

const initialSort = [];

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280, };

const QCard2 = ({ positiondata }) => {

    const [sort, setSort] = useState(initialSort);

    const { breakpoint } = useBreakpoint(BREAKPOINTS);

    const [qamcount, setqamcount] = useState([]);
    const [qimvalid, setqim] = useState([]);
    const [qam, setqam] = useState([]);
    const [iivalid, setii] = useState([]);
    const [ervalid, seter] = useState([]);


    useEffect(() => {
        const fetchData = async () => {

            const qim = await axios.get(`https://dash.dtsolution.kr/api/getqim`)
            setqim(_.filter(qim.data.data.data, function (elem) { return elem.status === 'Active'; }));


            const qam = await axios.get(`https://dash.dtsolution.kr/api/getqam`)

            setqam(qam.data.data.data);

            setqamcount(_.filter(qam.data.data.data, function (elem) { return elem.status === 'Active'; }).length);

            const er = await axios.get(`https://dash.dtsolution.kr/api/geter`)
            seter(_.filter(er.data.data.data, function (elem) { return elem.EligibilityReviewDate !== null; }));

            const ii = await axios.get(`https://dash.dtsolution.kr/api/getinstrument`)

            setii(_.filter(ii.data.data.data, function (elem) { return elem.status === 'Use'; }));

        };

        fetchData();
    }, []);



    const er_with_over = ervalid.map(function (v) {
        if (new Date(v.EligibilityReviewDate.slice(6, 10) + '-' + v.EligibilityReviewDate.slice(3, 5) + '-' + v.EligibilityReviewDate.slice(0, 2)) < new Date()) {
            return { Department: v.Department, over: 1, date: v.EligibilityReviewDate.slice(6, 10) + '-' + v.EligibilityReviewDate.slice(3, 5) + '-' + v.EligibilityReviewDate.slice(0, 2) }
        }
        else {
            return { Department: v.Department, over: 0, date: v.EligibilityReviewDate.slice(6, 10) + '-' + v.EligibilityReviewDate.slice(3, 5) + '-' + v.EligibilityReviewDate.slice(0, 2) }
        }
    })


    const ertable = er_with_over.map((v) => {
        return {
            부서: v.Department,
            인원수: _.countBy(er_with_over, { 'Department': v.Department })['true'],
            지난인원수: _.sumBy(er_with_over, 'over'),
        }
    })

    const ertable2 = _.uniqBy(ertable, '부서')


    //over date

    const er_over_o = ervalid.map(function (v) {
        if (v.NextEvalDate !== null) {
            return { 유효날짜: v.NextEvalDate }
        }
        else {
            return { 유효날짜: '03-03-2100' }
        }
    })

    const qim_over_o = qimvalid.map(function (v) {
        if (v.NextEvalDateDO !== null) {
            return { 유효날짜: v.NextEvalDateDO }
        }
        else {
            return { 유효날짜: '03-03-2100' }
        }
    })


    const ii_over_o = iivalid.map(function (v) {
        if (v.TI_NextEvaluationDate !== null) {
            return { 유효날짜: v.TI_NextEvaluationDate }
        }
        else {
            return { 유효날짜: '03-03-2100' }
        }
    })

    

    const qim_over = _.filter(er_over_o, function (v) { return parseInt(((new Date() - (new Date(v.유효날짜.slice(0, 10)))))) > 0 }).length
    const er_over = _.filter(qim_over_o, function (v) { return parseInt(((new Date() - (new Date(v.유효날짜.slice(0, 10)))))) > 0 }).length
    const ii_over = _.filter(ii_over_o, function (v) { return parseInt(((new Date() - (new Date(v.유효날짜.slice(0, 10)))))) > 0 }).length

    const [BoxHeight, setBoxHeight] = useState();

    useEffect(() => {
        if (positiondata.length > 0) {
            const boxHeight = positiondata[6];
            setBoxHeight( 40 * (boxHeight.rowSpan ));

        }
    }, [positiondata]);

    const [visibleDialog, setVisibleDialog] = useState(true);

    const toggleDialog = () => {
        setVisibleDialog(!visibleDialog);
    };


    const [visibleDialog2, setVisibleDialog2] = useState(true);

    const toggleDialog2 = () => {
        setVisibleDialog2(!visibleDialog2);
    };

    const [visibleDialog3, setVisibleDialog3] = useState(true);

    const toggleDialog3 = () => {
        setVisibleDialog3(!visibleDialog3);
    };
    const [visibleDialog4, setVisibleDialog4] = useState(true);

    const toggleDialog4 = () => {
        setVisibleDialog4(!visibleDialog4);
    };


    const formatdate = (props) => {
        const field = props.field || "";
        const date = props.dataItem[field]

        if (date) {

        return (
            <td colSpan={props.colSpan} style={props.style} className='desktop'>
                {date.toString().slice(8, 10) + "년 " + date.toString().slice(0, 2) + "월 " + date.toString().slice(3, 5) + "일"}
        </td>
        ); 
        }
        
            return (
                <td colSpan={props.colSpan} style={props.style} className='desktop'>
            </td>
            ); 

        
    }

    const formatname = (props) => {
        const field = props.field || "";
        const name = props.dataItem[field]

        if (name) {

        return (
            <td colSpan={props.colSpan} style={props.style}  className='desktop'>
                {name.slice(0, name.indexOf("("))}
        </td>
        ); 
        }
        
            return (
                <td colSpan={props.colSpan} style={props.style}  className='desktop'>
            </td>
            ); 

        
    }




    return (
        <>
            <GridLayout style={{ height: BoxHeight }}
                rows={[
                    {
                        height: '25%',
                    }
                    , {
                        height: '25%'
                    }, { height: '25%' }, { height: '25%' },

                ]}

                cols={[
                    {
                        width: '25%'
                    },
                    {
                        width: '25%'
                    }, {
                        width: '25%'
                    }, {
                        width: '25%'
                    },
                ]}
                gap={{
                    rows: 0,
                    cols: 0,
                }}>

{breakpoint ==='desktop' ?
<>
                <GridLayoutItem col={1} row={1} colSpan={1} rawSpan={4}  >
                    <Card
                        orientation='horizontal'
                        style={{ width: '80%', position:'relative', left:'10%'}}
                    >

                        <CardBody className='cardcenter'>
                            <CardTitle>인정 검사자 수</CardTitle>
                            <CardTitle ><span onClick={toggleDialog} className='hovertext bolder'>
                                {qim_over ? qim_over : qimvalid.length}
                            </span>
                            </CardTitle>
                        </CardBody>
                    </Card>
                </GridLayoutItem>

                <GridLayoutItem col={2} row={1} colSpan={1} rawSpan={4}  >
                    <Card
                        orientation='horizontal'
                        style={{ width: '80%', position:'relative', left:'10%'}}
                    >

                        <CardBody className='cardcenter'>
                            <CardTitle>내부 심사자 수</CardTitle>
                            <CardTitle ><span onClick={toggleDialog2} className='hovertext bolder'>
                            {qamcount}
                            </span>
                            </CardTitle>
                        </CardBody>
                    </Card>
                </GridLayoutItem>               

                <GridLayoutItem col={3} row={1} colSpan={1} rawSpan={4}  >
                    <Card
                        orientation='horizontal'
                        style={{ width: '80%', position:'relative', left:'10%'}}
                    >

                        <CardBody className='cardcenter'>
                            <CardTitle>사용 중 계측기 수</CardTitle>
                            <CardTitle ><span onClick={toggleDialog3} className='hovertext bolder'>
                            {ii_over ? ii_over : iivalid.length}
                            </span>
                            </CardTitle>
                        </CardBody>
                    </Card>
                </GridLayoutItem>

                <GridLayoutItem col={4} row={1} colSpan={1} rawSpan={4}  >
                    <Card
                        orientation='horizontal'
                        style={{ width: '80%', position:'relative', left:'10%'}}
                    >

                        <CardBody className='cardcenter'>
                            <CardTitle> 적격 인력 수</CardTitle>
                            <CardTitle ><span onClick={toggleDialog4} className='hovertext bolder'>
                            {er_over ? `${ervalid.length} (${er_over})` : ervalid.length}
                            </span>
                            </CardTitle>
                        </CardBody>
                    </Card>
                </GridLayoutItem>
                </>
                :
                <>                
                
            <GridLayoutItem col={1} row={1} colSpan={4} rawSpan={1}  >
                <Card
                    orientation='horizontal'
                    style={{ width: '100%', }}
                >

                    <CardBody className='cardcenter'>
                        <CardTitle>인정 검사자 수</CardTitle>
                        <CardTitle ><span onClick={toggleDialog} className='hovertext bolder'>
                            {qim_over ? qim_over : qimvalid.length}
                        </span>
                        </CardTitle>
                    </CardBody>
                </Card>
            </GridLayoutItem>

            <GridLayoutItem col={1} row={2} colSpan={4} rawSpan={1}>
                <Card
                    orientation='horizontal'
                    style={{ width: '100%', }}
                >

                    <CardBody className='cardcenter'>
                        <CardTitle>내부 심사자 수</CardTitle>
                        <CardTitle ><span onClick={toggleDialog2} className='hovertext bolder'>
                        {qamcount}
                        </span>
                        </CardTitle>
                    </CardBody>
                </Card>
            </GridLayoutItem>               

            <GridLayoutItem col={1} row={3} colSpan={4} rawSpan={1}  >
                <Card
                    orientation='horizontal'
                    style={{ width: '100%', }}
                >

                    <CardBody className='cardcenter'>
                        <CardTitle>사용 중 계측기 수</CardTitle>
                        <CardTitle ><span onClick={toggleDialog3} className='hovertext bolder'>
                        {ii_over ? ii_over : iivalid.length}
                        </span>
                        </CardTitle>
                    </CardBody>
                </Card>
            </GridLayoutItem>

            <GridLayoutItem col={1} row={4} colSpan={4} rawSpan={1}>
                <Card
                    orientation='horizontal'
                    style={{ width: '100%', }}
                >

                    <CardBody className='cardcenter'>
                        <CardTitle> 적격 인력 수</CardTitle>
                        <CardTitle ><span onClick={toggleDialog4} className='hovertext bolder'>
                        {er_over ? er_over : ervalid.length}
                        </span>
                        </CardTitle>
                    </CardBody>
                </Card>
            </GridLayoutItem>
            </>
}


            </GridLayout>

            <div>
                {!visibleDialog && (
                    <Dialog title={"인정 검사자 수 목록"} onClose={toggleDialog}>
                        <Grid
                            sortable={true}
                            sort={sort}
                            onSortChange={(e) => {
                                setSort(e.sort);
                            }}
                            data={orderBy(qimvalid, sort)}
                        >
                            <GridColumn field="NameTB120" title="이름" width="100px"  cell={formatname} />
                            <GridColumn field="InspFieldMI" title="검사분야" width="100px" />
                            <GridColumn field="InspGradeSPD" title="등급" width="80px" />
                            <GridColumn field="QualExpDate" title="만료일" width="100px" cell={formatdate} />

                        </Grid>

                    </Dialog>
                )
                }
                {
                    !visibleDialog2 && (
                        <Dialog title={"내부 심사자 목록"} onClose={toggleDialog2}>
                            <Grid
                                sortable={true}
                                sort={sort}
                                onSortChange={(e) => {
                                    setSort(e.sort);
                                }}
                                data={orderBy(qam, sort)}
                            >
                                <GridColumn field="OrganizNameDPK" title="이름" width="100px"  cell={formatname}/>
                                <GridColumn field="Department" title="부서" width="100px" />
                                <GridColumn field="TotalNumberDA" title="감사 횟수" width="100px" />
                            </Grid>
                        </Dialog>
                    )
                }

                {
                    !visibleDialog3 && (
                        <Dialog title={"사용중 계측기 목록"} onClose={toggleDialog3}>
                            <Grid
                                sortable={true}
                                sort={sort}
                                onSortChange={(e) => {
                                    setSort(e.sort);
                                }}
                                data={orderBy(iivalid, sort)}
                            >
                                <GridColumn field="TI_ItemName" title="품명" width="250px" />
                                <GridColumn field="TI_Model" title="모델" width="100px" className="desktop"/>
                                <GridColumn field="TI_NextEvaluationDate" title="차기 교정 일자" width="100px" cell={formatdate}/>
                            </Grid>
                        </Dialog>
                    )
                }

                {
                    !visibleDialog4 && (
                        <Dialog title={"적격 인력 목록"} onClose={toggleDialog4}>
                            <Grid
                                sortable={true}
                                sort={sort}
                                onSortChange={(e) => {
                                    setSort(e.sort);
                                }}
                                data={orderBy(ertable2, sort)}
                            >
                                <GridColumn field="부서" title="부서" width="150px" />
                                <GridColumn field="인원수" title="적격 인원수" width="100px" />
                                <GridColumn field="지난인원수" title="평가 일지 지난 인원수" width="100px" />
                            </Grid>
                        </Dialog>
                    )
                }




            </div>
        </>
    )

};

export default QCard2


