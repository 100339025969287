import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import axios from 'axios';
import {
    GridLayout,
    GridLayoutItem,
} from "@progress/kendo-react-layout";
import {
    Chart,
    ChartArea,
    ChartSeries,
    ChartSeriesItem,
    ChartTooltip, ChartTitle, ChartCategoryAxisItem, ChartCategoryAxis
} from "@progress/kendo-react-charts";
import { DropDownList  } from "@progress/kendo-react-dropdowns";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}


function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}


const FinancePPV = () => {
    const [ppvdata, setppvdata] = useState([]);
    const [projectlist, setprojectlist] = useState([]);
    const { height, width } = useWindowDimensions();
    const [lastmonth, setlastmonth] = useState();
    const [department, setdepartment] = useState([]);


    useEffect(() => {
        const fetchData = async () => {

            let body =
            {
                "bpname": "Dashboard (PPV)",
                "lineitem": "yes",
                "filter_criteria": {
                    "join": "AND",
                    "filter": [
                        {
                            "field": "status",
                            "value": "Active",
                            "condition_type": "eq"
                        }
                    ]
                }
            }

            const res = await axios.post(`https://dash.dtsolution.kr/api/getbprecords?path=HW-01`, body)


            const filter = res.data.data.data.filter(v => v.actualRB === '실적' && v.yearMonth !== null && v.YearSPD !== null)
                .map((v) => ({ ...v, yearMonth: v.YearSPD + '-' + v.umuMonthPD }))


            const last = _.sortBy(filter, 'yearMonth').reverse()[0]['yearMonth']


            setlastmonth(last)

            const data = filter.filter(v => v.yearMonth === last)
                .reduce((c, v) => c.concat(v._bp_lineitems.map(o => Object.assign(o, { yearMonth: v.yearMonth, Department_spd: v.Department_spd }))), []);

            setppvdata(_.sortBy(data, 'Department_spd'))
            setprojectlist(_.uniq(_.sortBy(data, 'Department_spd').map((v) => ({Department_spd:v.Department_spd  ,ProjectName:v.ProjectName}))))

            
            setdepartment(
                _.uniqBy(data, 'Department_spd').map(v => v.Department_spd).concat(['전사'])

            )

        }

        fetchData();
    }, []);

    // const planPPV = ppvdata
    //     .map(function (obj) {
    //         var index = obj.ProjectName; obj[index] = obj.TargetPPV;
    //         delete obj['사업부']; delete obj['li_num']; delete obj['short_desc']; delete obj['tab_id']; delete obj['uuu_tab_id'];
    //         return obj;
    //     })

    // const actualPPV = ppvdata2
    //     .map(function (obj) {
    //         var index2 = obj.ProjectName2; obj[index2] = obj.actualppv;
    //         delete obj['사업부']; delete obj['li_num']; delete obj['short_desc']; delete obj['tab_id']; delete obj['uuu_tab_id'];
        
    //         return obj;
    //     })

        console.log(ppvdata)


    const COLORS = [
        "#FFA500",
        "#56C6A9",
        "#6F9FD8",
        "#E8A798",
        "#D9CE52",
        "#D19C97",
        "#ECDB54",
    ];

    const labelContent2 = (e) =>
        Math.abs(e.value) === 0 || e.value === null || typeof e.value === "undefined"
            ? ""
            : e.value.toFixed(1) + '억';

    const seriesLabels2 = {
        visible: true,
        // Note that visible defaults to false
        padding: 3,
        font: "0.8rem Arial, sans-serif",
        position: "outsideEnd",
        background: "none",
        content: labelContent2,
    };

    const seriesLabels = {
        visible: true,
        // Note that visible defaults to false
        padding: 3,
        font: "0.8rem Arial, sans-serif",
        position: "below",
        background: "none",
        content: labelContent2,
    };


    const SharedTooltip = (props) => {
        const { points } = props;

        return (
            <>
                <div>{points[0].category}</div>
                {points.map((point, index) => (
                <div key={index}>
                <span>{point.series.field ==='TargetPPV'?'계획':'추정'} :{point.value.toFixed(0) + '억'}</span>
                </div> 
                ))}

            </>
        )


    };


    const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

    const title1 = 'PPV (' + lastmonth + ")"

    const [value, setValue] = React.useState('전사');

    const handleChange = (event) => {
        setValue(event.value);
    };


    return (<>
        <GridLayout
            style={{ width: width, height: height }}
            rows={[
                {
                    height: "7%",
                },
                {
                    height: "63%",
                },
                {
                    height: "10%",
                },

            ]}
            cols={[
                {
                    width: "100%",
                },
            ]}
            gap={{
                rows: 0,
                cols: 0,
            }}
        >
            <GridLayoutItem row={1} col={1} style={{ position: 'relative' }}>
                <DropDownList 
                    style={{
                        width: "300px",
                        position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'

                    }}
                    data={department}
                    value={value}
                    onChange={handleChange}
                />
            </GridLayoutItem>
            <GridLayoutItem row={2} col={1} style={{ margin: "0 auto" }}>
                <Chart>
                    <ChartTitle text={title1} position="top" />
                    {/* <ChartLegend position="bottom" orientation="horizontal" visible={false} /> */}

                    <ChartArea width={width} height={height * 0.63} margin={10} />
                    <ChartTooltip shared={true} render={sharedTooltipRender} />

                    <ChartCategoryAxis>
                        <ChartCategoryAxisItem
                            labels={{
                                rotation: "auto",
                            }}
                            categories={value ==='전사'? projectlist.map(v=>v.ProjectName):projectlist.filter(v=>v.Department_spd=== value).map(v=>v.ProjectName)}

                        />
                    </ChartCategoryAxis>

                    <ChartSeries>
                            <ChartSeriesItem
                                type="column"
                                // data={planPPV }
                                data={ value ==='전사'? ppvdata:ppvdata.filter(v=>v.Department_spd === value)}
                                field='TargetPPV'
                                color="#FFA500"
                                labels={seriesLabels}
                                // border={{ width: '10' }}
                                // name='계획'
                            spacing={0} 
                            gap={2}

                            />

                            <ChartSeriesItem
                                type="column"
                                // data={ actualPPV }
                                data={ value ==='전사'? ppvdata:ppvdata.filter(v=>v.Department_spd === value)}
                                field='actualppv'
                                color= "#56C6A9"
                                labels={seriesLabels2}
                                // border={{ width: '10' }}
                                // name='실적'
                            spacing={0} 
                            gap={2}


                            />



                    </ChartSeries>

                </Chart>



            </GridLayoutItem>
            <GridLayoutItem row={3} col={1} style={{ margin: "0 auto" }}>
                <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                    <span>
                        <span
                            style={{
                                float: "left",
                                fontSize: "1.2rem",
                                color: COLORS[0],
                            }}
                            className="k-icon k-i-circle"
                        ></span>
                        <span
                            style={{
                                float: "left",
                                fontSize: "0.8rem",
                                color: COLORS[0],
                            }}


                        >
                            계획 &nbsp;&nbsp;
                        </span>
                    </span>
                    <span>
                        <span
                            style={{
                                float: "left",
                                fontSize: "1.2rem",
                                color: COLORS[1],
                            }}
                            className="k-icon k-i-circle"
                        ></span>
                        <span
                            style={{
                                float: "left",
                                fontSize: "0.8rem",
                                color: COLORS[1],
                            }}


                        >
                            실적 &nbsp;&nbsp;
                        </span>
                    </span>
                </div>
            </GridLayoutItem>

        </GridLayout>


    </>)

}

export default FinancePPV;
