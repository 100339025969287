import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import moment from 'moment'
import {
    GridLayout,
    GridLayoutItem, 
} from "@progress/kendo-react-layout";

const ProgressComment = ({ positiondata }) => {

    const { project_code } = useParams();
    const [ment, setment] = useState([]);
  
    const today = moment().format("YYYY-MM")
    const [BoxHeight, setBoxHeight] = useState(250);

  
    useEffect(() => {
        const boxHeight = positiondata[4];
        setBoxHeight((boxHeight && boxHeight.rowSpan*180)-60);
    }, [positiondata]);
    useEffect(() => {
      const fetchData = async () => {
        const qn = await axios.get(`https://dash.dtsolution.kr/api/getmeeting1?path=${project_code}`)
        setment(qn.data.data.data.filter(item=>item.smmMeetingDO.slice(6,10)+'-'+item.smmMeetingDO.slice(0,2) ===today).map(v => v.smmProgressRT))
  
      }
  
      fetchData();
    }, [project_code,today]);


    return(
    <>
         <GridLayout style={{ height: BoxHeight }}
                rows={[
                    {height: '100%',},
                ]}

                cols={[   
                    {width: '100%',},]}

                gap={{
                    rows: 10,
                    cols: 10,
                }}>
                <GridLayoutItem  col={1} row={1}rowSpan={1} style={{backgroundColor:'#fafafa',padding:'16px', overflow:'auto'}} >
                <div style={{textAlign:'left', fontSize:'0.9rem'}} dangerouslySetInnerHTML={{ __html:ment}} className='ment2'></div>
                </GridLayoutItem>
           



                </GridLayout>
    </>)
}

export default ProgressComment
