import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import axios from 'axios';
import {
    GridLayout,
    GridLayoutItem,
} from "@progress/kendo-react-layout";
import {
    Chart,
    ChartArea,
    ChartSeries,
    ChartSeriesItem,
    ChartTooltip, ChartTitle, ChartCategoryAxisItem, ChartCategoryAxis, ChartLegend
} from "@progress/kendo-react-charts";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}


function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}


const FinanceF = () => {
    const [Finance, setFinance] = useState([]);
    const { height, width } = useWindowDimensions();
    const [lastmonth, setlastmonth] = useState();


    useEffect(() => {
        const fetchData = async () => {

            let body =
            {
                "bpname": "Dashboard (Finance)",
                "lineitem": "yes",
                "filter_criteria": {
                    "join": "AND",
                    "filter": [
                        {
                            "field": "status",
                            "value": "Active",
                            "condition_type": "eq"
                        }
                    ]
                }
            }

            const res = await axios.post(`https://dash.dtsolution.kr/api/getbprecords?path=HW-01`, body)

            const filter = res.data.data.data.filter(v => v.PlanActual_srb === '실적' && v.yearMonth !== null && v.YearSPD !== null)
                .map((v) => ({ ...v, yearMonth: v.YearSPD + '-' + v.umuMonthPD }))

            const last = _.sortBy(filter, 'yearMonth').reverse()[0]['yearMonth']
            const lastyear = Number(_.sortBy(filter, 'yearMonth').reverse()[0]['YearSPD']) - 1

            setlastmonth(last)


            const lastmonthdata = filter.filter(v => v.yearMonth === last)[0]['_bp_lineitems']
            const plandata = res.data.data.data.filter(v => v.PlanActual_srb === '사업계획')[0]['_bp_lineitems']
            const lastyearata = res.data.data.data.filter(v => Number(v.YearSPD) === lastyear)[0]['_bp_lineitems']

            const array = [
                {
                    type: lastyear,
                    유동자산: _.sumBy(lastyearata.filter(v => v.FinanceClass02 === '유동자산'), 'FinanceDA12'),
                    비유동자산: _.sumBy(lastyearata.filter(v => v.FinanceClass02 === '비유동자산'), 'FinanceDA12'),
                    유동부채: _.sumBy(lastyearata.filter(v => v.FinanceClass02 === '유동부채'), 'FinanceDA12'),
                    비유동부채: _.sumBy(lastyearata.filter(v => v.FinanceClass02 === '비유동부채'), 'FinanceDA12'),
                    자본: _.sumBy(lastyearata.filter(v => v.FinanceClass01 === '자본'), 'FinanceDA12'),
                },
                {
                    type: '연간 계획',
                    유동자산: _.sumBy(plandata.filter(v => v.FinanceClass02 === '유동자산'), 'FinanceDA12'),
                    비유동자산: _.sumBy(plandata.filter(v => v.FinanceClass02 === '비유동자산'), 'FinanceDA12'),
                    유동부채: _.sumBy(plandata.filter(v => v.FinanceClass02 === '유동부채'), 'FinanceDA12'),
                    비유동부채: _.sumBy(plandata.filter(v => v.FinanceClass02 === '비유동부채'), 'FinanceDA12'),
                    자본: _.sumBy(plandata.filter(v => v.FinanceClass01 === '자본'), 'FinanceDA12'),
                },
                {
                    type: '연간 추정',
                    유동자산: _.sumBy(lastmonthdata.filter(v => v.FinanceClass02 === '유동자산'), 'FinanceDA12'),
                    비유동자산: _.sumBy(lastmonthdata.filter(v => v.FinanceClass02 === '비유동자산'), 'FinanceDA12'),
                    유동부채: _.sumBy(lastmonthdata.filter(v => v.FinanceClass02 === '유동부채'), 'FinanceDA12'),
                    비유동부채: _.sumBy(lastmonthdata.filter(v => v.FinanceClass02 === '비유동부채'), 'FinanceDA12'),
                    자본: _.sumBy(lastmonthdata.filter(v => v.FinanceClass01 === '자본'), 'FinanceDA12'),
                },

            ]

            setFinance(array)

            




        }

        fetchData();
    }, []);





    const COLORS = [
        "#FFA500",
        "#56C6A9",
        "#6F9FD8",
        "#E8A798",
        "#D9CE52",
        "#D19C97",
        "#ECDB54",
    ];

    const labelContent2 = (e) =>
        Math.abs(e.value) === 0 || e.value === null || typeof e.value === "undefined" || Math.abs(e.value) < 100
            ? ""
            : e.value.toFixed(1);

    const seriesLabels2 = {
        visible: true,
        // Note that visible defaults to false
        padding: 3,
        font: "0.8rem Arial, sans-serif",
        position: "center",
        background: "none",
        content: labelContent2,
    };



    const SharedTooltip = (props) => {
        const { points } = props;

        return (

            <>
                <div>{points[0].category}</div>
                {points.map((point, index) => (
                <div key={index}>
                            
                                <span>{point.series.field} :{point.value.toFixed(0) + '억'}</span>
                </div> 
                ))}

            </>
        )


    };


    const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

    const title1 = '전사 재무상태표 추정 (' + lastmonth + ")"

    const Ftype = ['유동자산', '비유동자산', '유동부채', '비유동부채', '자본']


    return (<>
        <GridLayout
            style={{ width: width, height: height }}
            rows={[
                {
                    height: "70%",
                },
                {
                    height: "10%",
                },

            ]}
            cols={[
                {
                    width: "100%",
                },
            ]}
            gap={{
                rows: 0,
                cols: 0,
            }}
        >
            <GridLayoutItem row={1} col={1} style={{ margin: "0 auto" }}>
                <Chart>
                    <ChartTitle text={title1} position="top" />
                    <ChartLegend position="bottom" orientation="horizontal" visible={false} />

                    <ChartArea width={width} height={height * 0.7} margin={10} />
                    <ChartTooltip shared={true} render={sharedTooltipRender} />
                    {/* <ChartCategoryAxis>
                        <ChartCategoryAxisItem labels={serieslabelcategory} />
                        <ChartCategoryAxisItem labels={serieslabelcategory2} />

                    </ChartCategoryAxis> */}

                    <ChartSeries>
                        <ChartSeriesItem
                            type="column"
                            stack={true}
                            data={Finance}
                            field='유동자산'
                            categoryField='type'
                            color={COLORS[0]}
                            labels={seriesLabels2}
                            border={{ width: '0' }}
                        />
                        <ChartSeriesItem
                            type="column"
                            stack={true}
                            data={Finance}
                            field='비유동자산'
                            categoryField='type'
                            color={COLORS[1]}
                            labels={seriesLabels2}
                            border={{ width: '0' }}
                        />
                        <ChartSeriesItem
                            type="column"
                            stack={true}
                            data={Finance}
                            field='유동부채'
                            categoryField='type'
                            color={COLORS[2]}
                            labels={seriesLabels2}
                            border={{ width: '0' }}
                        />
                        <ChartSeriesItem
                            type="column"
                            stack={true}
                            data={Finance}
                            field='비유동부채'
                            categoryField='type'
                            color={COLORS[3]}
                            labels={seriesLabels2}
                            border={{ width: '0' }}
                        />
                        <ChartSeriesItem
                            type="column"
                            stack={true}
                            data={Finance}
                            field='자본'
                            categoryField='type'
                            color={COLORS[4]}
                            labels={seriesLabels2}
                            border={{ width: '0' }}
                        />

                    </ChartSeries>

                </Chart>



            </GridLayoutItem>
            <GridLayoutItem row={2} col={1} style={{ margin: "0 auto" }}>
                <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                    {Ftype.map((v, index) => (
                        <React.Fragment key={index}>
                            <span>
                                <span
                                    style={{
                                        float: "left",
                                        fontSize: "1.2rem",
                                        color: COLORS[index],
                                    }}
                                    className="k-icon k-i-circle"
                                ></span>
                                <span
                                    style={{
                                        float: "left",
                                        fontSize: "0.8rem",
                                        color: COLORS[index],
                                    }}
                                  
                                >
                                    {v} &nbsp;&nbsp;
                                </span>
                            </span>
                        </React.Fragment>
                    ))}
                </div>
            </GridLayoutItem>

        </GridLayout>


    </>)

}

export default FinanceF;
