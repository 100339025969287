import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Chart, ChartCategoryAxisItem, ChartCategoryAxis, ChartArea, ChartLegend, ChartSeries, ChartSeriesItem, ChartValueAxis, ChartValueAxisItem, ChartTooltip, exportVisual } from "@progress/kendo-react-charts";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { orderBy } from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import moment from 'moment';
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import { exportImage } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";


const Progress = ({ positiondata }) => {

  const [sort, setSort] = useState([{}]);

  const _export = useRef(null);

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const [charttotal, settotal] = useState([]);
  const [visibleDialog, setVisibleDialog] = useState(true);

  const [month, setmonth] = useState("");
  const [last_progressv, setlastv] = useState(0);
  const [last_progressp, setlastp] = useState(0);
  const { project_code } = useParams();

  const [planmodule, setplanmodule] = useState(0);
  const [acutalmodule, setacutalmodule] = useState(0);





  useEffect(() => {
    const fetchData = async () => {
      //플랜
      const res = await axios.get(`https://dash.dtsolution.kr/api/getWBS?path=${project_code}`)

      // 레코드들을 각 라인아이템으로 이동 

      //수정사항: ugenUnitofMeasurePD(대호호 현장) 필드 추가 
      const progress = res.data.data.data.filter(com => com._bp_lineitems !== undefined && !com.ActID40.includes('미사용') )
        .reduce((c, v) => c.concat(v._bp_lineitems.map(o => Object.assign(o, { moduleyn:v.ModuleActCheck,watt:v.ModuleWatt,quantity: v.uuu_quantity, "Activity_WV": v.WeightValue, "ActID40": v.ActID40, "Activity": v.ActIDNMforPK, "Facilty": v.FacilityL1CDNM, "Phase": v.PhaseforPK, "Discipline": v.WorkPkgforPK, "xaxis": o.YearText.slice(-2) + "년 " + o.MonthText + "월", ugenUnitofMeasurePD: v.ugenUnitofMeasurePD }))), []);

      progress.sort((a, b) => a.xaxis.localeCompare(b.xaxis));


      const total = progress.reduce(function (a, b) {
        return a + b.WeightValue
      }, 0);

      progress.forEach(function (v) {
        v.percentage = (v.WeightValue * 100 / total)

      });

      let cumsum = 0;
      const newData = progress.map(({ Activity, Facilty, Phase, xaxis, Discipline, WeightValue, percentage }) => ({ Activity, Facilty, Phase, Discipline, xaxis, WeightValue, c_percentage: cumsum += percentage }));


      var reduced = newData.reduce(function (final, item) {
        if (final[item.xaxis]) {
          final[item.xaxis] = { WeightValue: item.WeightValue + final[item.xaxis].WeightValue, c_percentage: item.c_percentage.toFixed(2), xaxis: item.xaxis };
        } else {
          final[item.xaxis] = { WeightValue: item.WeightValue, c_percentage: item.c_percentage, xaxis: item.xaxis };
        }
        return final;
      }, {});

      var pv = Object.values(reduced);

      //모듈 플랜
      const module_plan = _.filter(progress, { 'moduleyn': 'Yes' });
      const module_plan_wv = _.sumBy(module_plan, 'WeightValue');

      //수정사항: ugenUnitofMeasurePD(대호호 현장(MV)) 조건 추가
      const m_pv = module_plan
                  .map(({ xaxis, WeightValue, quantity, watt, ugenUnitofMeasurePD }) => 
                    {
                      if(ugenUnitofMeasurePD === "MW"){
                        return { xaxis,ugenUnitofMeasurePD, moudule: (WeightValue/module_plan_wv)*(quantity)}
                      } else {
                        return { xaxis,ugenUnitofMeasurePD, moudule: (WeightValue/module_plan_wv)*(quantity*watt)/1000000}
                      }
                    }
                  )

      var m_ev_by_mont =
        _(m_pv)
          .groupBy('xaxis')
          .map((objs, key) => ({
            'xaxis': key,
            'module_p': _.sumBy(objs, 'moudule')
          }))
          .value();


      setplanmodule(_.sumBy(m_ev_by_mont, 'module_p').toFixed(2))



      //설계 실적
      const res2 = await axios.get(`https://dash.dtsolution.kr/api/geteng?path=${project_code}`)

      const e_actul_progress = res2.data.data.data.filter(v=>v.usubDecisionDP !== null).map(v => ({ ...v, Phase: "E | Engineering" })).map(v => ({ ...v, xaxis: v.usubDecisionDP.toString().slice(8, 10) + "년 " + v.usubDecisionDP.toString().slice(0, 2) + "월" }))

      // 같은 것 끼리 모으기
      var reduced_e = e_actul_progress.reduce(function (final, item) {
        if (final[item.xaxis]) {
          final[item.xaxis] = { EarnedValue: item.EarnedValue + final[item.xaxis].EarnedValue, xaxis: item.xaxis };
        } else {
          final[item.xaxis] = { EarnedValue: item.EarnedValue, xaxis: item.xaxis };
        }
        return final;
      }, {});

      var e_ev = Object.values(reduced_e);



      //작업일보
      const res3 = await axios.get(`https://dash.dtsolution.kr/api/getdr?path=${project_code}`)

      // 레코드들을 각 라인아이템으로 이동 
      //수정사항: ugenUnitofMeasurePD(대호호 현장(MV)) 조건 추가
      const c_progress = res3.data.data.data.filter(item => typeof item._bp_lineitems !== 'undefined' && item.status === "Approved")
        .reduce((c, v) => c.concat(v._bp_lineitems.map(o => Object.assign(o, { "YearMonth": v.ReportDateDO.toString().slice(8, 10) + "년 " + v.ReportDateDO.toString().slice(0, 2) + "월" }))), [])
        .map(com => {
          if(com.ugenUnitofMeasurePD === "MW"){
            return {
              ...com, 
              todayqty: com.TodayWorkQtyDA, 
              ev_raw_rate: (com.TodayWorkQtyDA / com.uuu_quantity),
            }
          } else {
            return {
              ...com, 
              todayqty: com.TodayWorkQtyDA * 415 / 1000000, 
              ev_raw_rate: (com.TodayWorkQtyDA / com.uuu_quantity),
            }
          }
        }
        )

      c_progress.sort((a, b) => a.YearMonth.localeCompare(b.YearMonth));

      

      const c_activity = _.filter(c_progress, { uuu_tab_id: "Activities" });

      const module_act = _.filter(progress, { 'moduleyn': 'Yes' }).map(v=>v.ActID40)

      //모듈 실적
      const m_actual_count = c_activity.filter(v => module_act.includes(v.ActID40))

      var m_actual =
        _(m_actual_count)
          .groupBy('YearMonth')
          .map((objs, key) => ({
            'xaxis': key,
            'module_a': _.sumBy(objs, 'todayqty')
          }))
          .value();

      setacutalmodule((_.sumBy(m_actual, 'module_a').toFixed(2)))


      const c_activity_with_WV = c_activity.map(v => ({ ...v, ...progress.find(sp => sp.ActID40 === v.ActID40) }));

      const c_progress_with_ev = c_activity_with_WV.map(({ YearMonth, ActID40, Activity_WV, ev_raw_rate }) => ({ YearMonth, ActID40, ev: Activity_WV * ev_raw_rate }))

      var c_ev =
        _(c_progress_with_ev.map(com => ({...com, ev: isNaN(com.ev) ? 0 : com.ev})))
          .groupBy('YearMonth')
          .map((objs, key) => {
            return {
            'xaxis': key,
            'EarnedValue': _.sumBy(objs, 'ev')
          }
        })
          .value();



      


      //  구매 실적
      const res4 = await axios.get(`https://dash.dtsolution.kr/api/getpr?path=${project_code}`)


      // 레코드들을 각 라인아이템으로 이동 
      const p_a_progress = res4.data.data.data.filter(com => com._bp_lineitems !== undefined)
        .reduce((c, v) => c.concat(v._bp_lineitems.map(o => (o.actual_date != null) ? Object.assign(o, { "EarnedValue": v.WeightValue * o.pms_wf / 100, "xaxis": o.actual_date.toString().slice(8, 10) + "년 " + o.actual_date.toString().slice(0, 2) + "월" }) :
          Object.assign(o, { "xaxis": "없음" }))), []);          

      p_a_progress.sort((a, b) => a.xaxis.localeCompare(b.xaxis));

      const p_a_progress_f = _.remove(p_a_progress, o => o.xaxis !== '없음')

      var p_ev =
        _(p_a_progress_f.map(com => ({...com, EarnedValue: isNaN(com.EarnedValue) ? 0 : com.EarnedValue})))
          .groupBy('xaxis')
          .map((objs, key) => ({
            'xaxis': key,
            'EarnedValue': _.sumBy(objs, 'EarnedValue')
          }))
          .value();


      // EV Sum

      const sum_ev = c_ev.concat(p_ev).concat(e_ev)

      

      var ev =
        _(sum_ev)
          .groupBy('xaxis')
          .map((objs, key) => 
            {
              
              return {
            'xaxis': key,
            'EarnedValue': _.sumBy(objs, 'EarnedValue')
          }
        }
          )
          .value();



      ev.forEach(function (v) {
        v.e_percentage = (v.EarnedValue * 100 / total)

      });

      ev.sort((a, b) => a.xaxis.localeCompare(b.xaxis));



      let cumsumev = 0;
      const sum_ev_c = ev.map(({ xaxis, EarnedValue, e_percentage }) => ({ xaxis, EarnedValue, c_e_percentage: cumsumev += e_percentage }));


      const module_result = m_ev_by_mont.map(v => ({ ...v, ...m_actual.find(sp => sp.xaxis === v.xaxis) }));


      // 두 배열 같은 값을 기준으로 합치기
      const result = pv.map(v => ({ ...v, ...sum_ev_c.find(sp => sp.xaxis === v.xaxis) }));

      const result_total = result.map(v => ({ ...v, ...module_result.find(sp => sp.xaxis === v.xaxis) }))

      

      // const result2 = pv.map(v => ({ ...v, ...sum_ev_c.find(sp => sp.xaxis === v.xaxis) }));

      const current_progress = result_total.filter(function (v) {
        return typeof v.EarnedValue !== 'undefined';
      });

      const last_v = _.get(current_progress[current_progress.length - 1], 'c_e_percentage')

      const last_p = _.get(current_progress[current_progress.length - 1], 'c_percentage')

      setlastp(last_p);
      setlastv(last_v);

      settotal(result_total.map((v)=>({
        EarnedValue:v.EarnedValue,
        WeightValue:v.WeightValue,
        c_e_percentage:v.c_e_percentage,
        c_percentage:v.c_percentage,
        xaxis:v.xaxis,
        module_p: v.module_p ?v.module_p :0,
        module_a: v.module_a ?v.module_a :0,

      })))

      setmonth(_.get(current_progress[current_progress.length - 1], 'xaxis'))


    }

    fetchData();
  }, [project_code, visibleDialog]);

  const [BoxHeight, setBoxHeight] = useState();

  useEffect(() => {
    if (positiondata.length > 0) {
      const boxHeight = positiondata[5];
      setBoxHeight(250 + 150 * (boxHeight.rowSpan - 2));
    }
  }, [positiondata]);


  const labelContentAxis = (e) => e.value > 100000000 ? (e.value / 100000000).toFixed(2) + '억' : e.value;
  const labelContentAxisM = (e) => e.value + 'MW';
  const labelContentAxisP = (e) => e.value + '%';

  const SharedTooltip = (props) => {
    const { points } = props;
    return (
      <div>
           <div>{points[0].category}</div>
        {points.map((point, index) => (
          point.series.name === '월간 실적' || point.series.name === '월간 계획' ?
            <div key={index}>
            {point.series.name} : {Number.parseFloat(point.value / 1000000).toFixed(0) + '백만'}
            </div> :
            point.series.name === '누적 실적' || point.series.name === '누적 계획' ?
              <div key={index}>

              {point.series.name} : {Number.parseFloat(point.value).toFixed(2) + '%'}
              </div> :
              <div key={index}>

               {point.series.name} : {Number.parseFloat(point.value).toFixed(2) + 'MW'}
              </div>
        ))}
      </div>
    );
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;


  const toggleDialog = () => {
    setVisibleDialog(!visibleDialog);
  };

  const [zoomableyn, setzoomableyn] = useState(false);

  const toggleZoom = () => {
    setzoomableyn(!zoomableyn);
  };

  const chart = useRef(null);

  const onImageExportClick = () => {
    const chartVisual = exportVisual(chart.current);

    if (chartVisual) {
      exportImage(chartVisual).then((dataURI) => saveAs(dataURI, "chart.png"));
    }
  };



  const maxfooter = (props) => {
    const field = props.field || "";
    // const min = charttotal.reduce(
    //   (acc, current) => Math.min(acc, current[field]),
    //   Number.MAX_VALUE
    // );
    const max = _.maxBy(charttotal, field)[field]
    return (
      <td colSpan={props.colSpan} style={props.style}>
        {max.toFixed(2)}%
      </td>
    );
  };

  const sumfooter = (props) => {
    const field = props.field || "";
    const total = _.sumBy(charttotal, field)
    return (
      <td colSpan={props.colSpan} style={props.style}>
        합계: {(total / 1000000).toFixed(0) + '백만'}
      </td>
    );
  };

  const sumMfooter = (props) => {
    const field = props.field || "";
    const total = _.sumBy(charttotal, field) || "";
    return (
      <td colSpan={props.colSpan} style={props.style}>
        {total ?
        <span>합계: {(total).toFixed(2) + 'MW'}</span> :""}
      </td>
    );
  };


  const cellWithBackGround = (props) => {
    const variance = props.dataItem.c_e_percentage - Number(props.dataItem.c_percentage)

    if (variance < 0) {
      return (
        <td
          style={{
            color: "red",
          }}
        >
          {variance.toFixed(2) + '%'}
        </td>
      );
    }
    else if (variance > 0) {
      return (
        <td
          style={{
            color: "blue",
          }}
        >
          {variance.toFixed(2) + '%'}
        </td>
      );
    }
    return (<td></td>);
  }

  const formatWV = (props) => {
    const planWV = props.dataItem.WeightValue

    return (
      <td

      >
        {(planWV / 1000000).toFixed(0) + '백만'}
      </td>
    );


  }

  const formatEV = (props) => {
    const planWV = props.dataItem.EarnedValue
    const max = _.maxBy(charttotal, 'EarnedValue')['EarnedValue']

    if (planWV === max) {
      return (
        <td style={{ fontWeight: 'bolder' }}>
          {(planWV / 1000000).toFixed(0) + '백만'}
        </td>
      );
    }
    else if (planWV > 0) {
      return (
        <td>
          {(planWV / 1000000).toFixed(0) + '백만'}
        </td>
      );
    }

    return (<td></td>);

  }

  const formatMP = (props) => {

    const planP = props.dataItem.module_p

    const max =  _.maxBy(charttotal, 'module_p')['module_p']

    if (planP === max && max !== 0) {
      return (
        <td style={{ fontWeight: 'bolder' }}>
          {(planP).toFixed(2) + 'MW'}
        </td>
      );
    }
    else if (planP > 0) {
      return (
        <td>
          {(planP).toFixed(2) + 'MW'}
        </td>
      );
    }

    return (<td></td>);


  }


  const formatMA = (props) => {

    const planA = props.dataItem.module_a

    const max = _.maxBy(charttotal, 'module_a')['module_a']

    if (planA === max && max !== 0) {
      return (
        <td style={{ fontWeight: 'bolder' }}>
          {(planA).toFixed(2) + 'MW'}
        </td>
      );
    }
    else if (planA > 0) {
      return (
        <td>
          {(planA).toFixed(2) + 'MW'}
        </td>
      );
    }

    return (<td></td>);
  }


  const formatPerP = (props) => {
    const PerP = props.dataItem.c_percentage

    if (PerP > 0) {
      return (
        <td>
          {Number(PerP).toFixed(2) + '%'}
        </td>
      );
    }

    return (<td></td>);

  }

  const formatPerA = (props) => {
    const planA = props.dataItem.c_e_percentage

    if (planA > 0) {
      return (
        <td>
          {Number(planA).toFixed(2) + '%'}
        </td>
      );
    }

    return (<td></td>);

  }
  const today_month = moment(new Date()).format('YY년 MM월')

  const rowRender = (trElement, props) => {
    const available = props.dataItem.xaxis;
    const green = {
      backgroundColor: "rgb(55, 180, 0,0.32)",
    };

    const none = {
      fontWeight: "noraml",
    };


    const trProps = {
      style: available === today_month ? green : none,
    };
    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };

  const labelContentL = (e) =>
  e.index % 4 === 0 ? e.value :'';


  const seriesLabels = {
    visible: true,
    // Note that visible defaults to false
    padding: 0,
    font: "0.8rem Arial, sans-serif",
    background: 'none',
    // rotation:{angle:'auto'},
    content: labelContentL,
  };


  const seriesLabels2 = {
    visible: true,
    // Note that visible defaults to false
    padding: 0,
    font: "0.8rem Arial, sans-serif",
    background: 'none',
  };

  


  return (
    <div>
      <GridLayout style={{ height: BoxHeight }}
        rows={[
          {
            height: '12%',
          },
          {
            height: '12%',
          },
          {
            height: '12%',
          },
          {
            height: '12%',
          },
          {
            height: '12%',
          },
          {
            height: '12%',
          },
          {
            height: '12%',
          },
          {
            height: '12%',
          },
          {
            height: '12%',
          },
          {
            height: '12%',
          }



        ]}

        cols={[
          {
            width: '100%',
          },


        ]}
        gap={{
          rows: 5,
          cols: 5,
        }}>

        <GridLayoutItem col={1} row={1} colSpan={1} rowSpan={2}>

          <div>
            <div style={{ fontSize: `${(BoxHeight / 300) * 19}px`, paddingLeft: '5px', paddingTop: '5px' }}>
              {
                typeof last_progressv !== 'undefined' ?
                  <div>{month} 공정률 &#40;계획: {last_progressp}% 실적: <span style={{ fontWeight: 'bolder' }}>{last_progressv.toFixed(2)}%&#41;</span>  
                    {(last_progressv - parseFloat(last_progressp)) < 0 ?<span style={{ fontWeight: 'bolder' ,color:'#CD212A'}}>{(last_progressv - parseFloat(last_progressp)).toFixed(2)}% 지연</span>:
                     <span style={{ fontWeight: 'bolder' ,color:'#34568B'}}>{(last_progressv - parseFloat(last_progressp)).toFixed(2)}% 선행</span> }
                  </div> :
                  <span style={{ fontWeight: 'bolder' }}>실적 정보 없음</span>
              }

            </div>

            <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
              <ButtonGroup>
                <Button
                  title="확대/이동 켜기"
                  iconClass='k-icon k-i-zoom-in'
                  themeColor={zoomableyn ? "primary" : null} fillMode="flat"
                  onClick={toggleZoom}
                >
                </Button>

                <Button
                  title="이미지로 저장"
                  iconClass='k-icon k-i-image-export'
                  fillMode="flat"
                  onClick={onImageExportClick}
                >
                </Button>

                <Button
                  title="테이블로 보기"
                  iconClass='k-icon k-i-table'
                  fillMode="flat"

                  onClick={toggleDialog}

                >
                </Button>

              </ButtonGroup>
            </div>
            {/* <div style={{ fontSize: `${(BoxHeight / 300) * 19}px`, paddingLeft: '5px' , paddingTop:'5px'}}>
              {month} 모듈 설치 실적: {acutalmodule}MW / {planmodule}MW (<span style={{ fontWeight: 'bolder' }}>{acutalmodule === '0.00' ?0: (acutalmodule * 100 / planmodule).toFixed(2)}%</span> 달성)
            </div> */}

          </div>
        </GridLayoutItem>
        <GridLayoutItem col={1} row={3} colSpan={1} rowSpan={8}>
          <Chart ref={chart} pannable={zoomableyn} zoomable={zoomableyn}>
            <ChartArea height={BoxHeight*1.15} />
            <ChartLegend position="bottom" orientation="horizontal" labels={seriesLabels2} />
            <ChartValueAxis>
              <ChartValueAxisItem name='월간' labels={{ content: labelContentAxis, font: "0.7rem Arial, sans-serif" }} />
              <ChartValueAxisItem name='모듈' min={0} labels={{ content: labelContentAxisM, font: "0.7rem Arial, sans-serif" }} visible={false} />
              <ChartValueAxisItem name='누적(%)' min={0} labels={{ content: labelContentAxisP, font: "0.7rem Arial, sans-serif" }} visible={false} />
            </ChartValueAxis>
            <ChartTooltip shared={true} render={sharedTooltipRender} />

            <ChartSeries>
              <ChartSeriesItem
                color={charttotal.color}
                type="column"
                data={charttotal}
                field="EarnedValue"
                categoryField="xaxis"
                name="월간 실적"
                autoFit={true}
                axis='월간'
              />
              <ChartSeriesItem
                color={charttotal.color}
                type="column"
                data={charttotal}
                field="WeightValue"
                categoryField="xaxis"
                name="월간 계획"
                autoFit={true}
                axis='월간'

              />
              <ChartSeriesItem
                color={charttotal.color}
                type="column"
                data={charttotal}
                field="module_p"
                categoryField="xaxis"
                name="월간 모듈 계획"
                autoFit={true}
                visible={false}
                axis='모듈'

              />
              <ChartSeriesItem
                color={charttotal.color}
                type="column"
                data={charttotal}
                field="module_a"
                categoryField="xaxis"
                name="월간 모듈 실적"
                autoFit={true}
                axis='모듈'
                visible={false}

              />


              <ChartSeriesItem
                color={charttotal.color}
                type="line"
                data={charttotal}
                field="c_percentage"
                categoryField="xaxis"
                name="누적 계획"
                autoFit={true}
                axis='누적(%)'

              />
              <ChartSeriesItem
                color={charttotal.color}
                type="line"
                data={charttotal}
                field="c_e_percentage"
                categoryField="xaxis"
                name="누적 실적"
                axis='누적(%)'
                autoFit={true}
              />
            </ChartSeries>
            <ChartCategoryAxis>
              <ChartCategoryAxisItem labels={seriesLabels}>
              </ChartCategoryAxisItem>
            </ChartCategoryAxis>

          </Chart>
        </GridLayoutItem>
      </GridLayout>

      <div>

        {!visibleDialog && (
          <Dialog title={"공정률"} onClose={toggleDialog} width='80%' height='600px'>
            <ExcelExport data={charttotal} ref={_export}>
              <Grid

                data={orderBy(charttotal, sort)}
                sortable={true}
                sort={sort}
                onSortChange={(e) => {
                  setSort(e.sort);
                }}
                rowRender={rowRender}
                style={{height:'500px'}}
            

              >
                <GridToolbar>
                  <button
                    title="Export Excel"
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                    onClick={excelExport}
                    style={{ position: 'absolute', right: '5px' }}
                  >
                    <span className="k-icon k-i-file-excel"></span>
                  </button>
                </GridToolbar>

                <GridColumn field="xaxis" title="월" />
                <GridColumn field="WeightValue" title="월간 계획" footerCell={sumfooter} cell={formatWV} />
                <GridColumn field="EarnedValue" title="월간 실적" footerCell={sumfooter} cell={formatEV} />
                <GridColumn field="module_p" title="월간 모듈 계획" footerCell={sumMfooter} cell={formatMP} /> 
                <GridColumn field="module_a" title="월간 모듈 실적" footerCell={sumMfooter} cell={formatMA} />
                <GridColumn field="c_percentage" title="누적 계획" cell={formatPerP} />
                <GridColumn field="c_e_percentage" title="누적 실적" footerCell={maxfooter} cell={formatPerA} />
                <GridColumn title="누적 차이" cell={cellWithBackGround} />

              </Grid>
            </ExcelExport>
          </Dialog>
        )
        }
      </div>
    </div>
  );
};

export default Progress