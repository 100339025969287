import React, { useEffect, useState,useRef } from 'react';
import axios from 'axios';
import ReactImageMagnify from 'react-image-magnify';
import { useParams } from 'react-router-dom';
import { DropDownList } from "@progress/kendo-react-dropdowns";
import _ from 'lodash';
import { filterBy } from "@progress/kendo-data-query";


const config = {
    responseType: 'blob',

};




const Image = () => {

    const { project_code } = useParams();

    const [src, setsrc] = useState("");
    const [src2, setsrc2] = useState("");

    const [folder, setfolder] = useState([]);
    const [foldervalue, setfolderValue] = useState("");
    
    const [folder2, setfolder2] = useState([]);
    const [foldervalue2, setfolderValue2] = useState("");

    const [folder22, setfolder22] = useState([]);
    const [foldervalue22, setfolderValue22] = useState("");

    const [files, setfiles] = useState([]);
    const [filevalue, setfileValue] = useState("");

    
    const [files2, setfiles2] = useState([]);
    const [filevalue2, setfileValue2] = useState("");

    useEffect(() => {
        const fetchData = async () => {



            const meta = await axios.get(`https://dash.dtsolution.kr/api/getimg/?project=` + project_code + '&parent=/현장 사진/')


            // const meta_data = meta.data.data.map(o => Object.assign(o, {"value":o.Name, "label": o.Name }))
            const meta_data = meta.data.data.data.map(({ Name }) => ({ value: Name, label: Name }));

            setfolder(_.sortBy(meta_data, 'value'))


            const meta2 = await axios.get(`https://dash.dtsolution.kr/api/getimg/?project=` + project_code + '&parent=/10 공사/04 시공관리/02 작업일보 (하도급)/')


            // const meta_data = meta.data.data.map(o => Object.assign(o, {"value":o.Name, "label": o.Name }))
            const meta_data2 = meta2.data.data.data.map(({ Name }) => ({ value: Name, label: Name }));

            setfolder2(_.sortBy(meta_data2,'value'))

            const meta22 = await axios.get(`https://dash.dtsolution.kr/api/getimg/?project=` + project_code + '&parent=/10 공사/04 시공관리/02 작업일보 (하도급)/'+ foldervalue2)
            const meta_data22 = meta22.data.data.data.map(({ Name }) => ({ value: Name, label: Name }));

            setfolder22(foldervalue2 !== ''?_.sortBy(meta_data22,'value'):[])


            const meta_file = await axios.get(`https://dash.dtsolution.kr/api/getimg/?project=` + project_code + '&parent=/현장 사진/' + foldervalue)
            const meta_file2 = await axios.get(`https://dash.dtsolution.kr/api/getimg/?project=` + project_code + '&parent=/10 공사/04 시공관리/02 작업일보 (하도급)/'+ foldervalue2+'/' +foldervalue22 )



            const meta_file_data = meta_file.data.data.data
            .filter(v=>v.Name.toUpperCase().includes('.JPG') || v.Name.toUpperCase().includes('.JPEG') || v.Name.toUpperCase().includes('.PNG')|| v.Name.toUpperCase().includes('.GIF')|| v.Name.toUpperCase().includes('.BMP'))
            .map(o => Object.assign(o, { "value": o.Name, "label": o.Name }))
            const meta_file_data2 = meta_file2.data.data.data
            .filter(v=>v.Name.toUpperCase().includes('.JPG') || v.Name.toUpperCase().includes('.JPEG') || v.Name.toUpperCase().includes('.PNG')|| v.Name.toUpperCase().includes('.GIF')|| v.Name.toUpperCase().includes('.BMP'))
            .map(o => Object.assign(o, { "value": o.Name, "label": o.Name }))
            // const meta_file_data = meta_file.data.data.map(({ Name }) => ({ value: Name, label: Name }));


            setfiles(foldervalue !== ''?_.sortBy(meta_file_data,'value'):[])
            setfiles2(foldervalue22 !== ''?_.sortBy(meta_file_data2,'value'):[])

            const file_id = _.filter(meta_file_data, { "value": filevalue })
            const file_id2 = _.filter(meta_file_data2, { "value": filevalue2 })

            const res =  await axios.get(`https://dash.dtsolution.kr/api/getimgfile/?file_id=` + (filevalue2 !== ''? file_id2[0].file_id:file_id[0].file_id), config)

            const url = window.URL.createObjectURL(new Blob([res.data]));

            setsrc(url);


        }

        fetchData();

    }, [foldervalue, filevalue,foldervalue2,foldervalue22, filevalue2, project_code]);



    const handleChange = (event) => {
        setfolderValue(event.target.value.value);
    };

    const handleChange2 = (event) => {
        setfileValue(event.target.value.value);
    };

    const handleChangeD = (event) => {
      setfolderValue2(event.target.value.value);
  };

  const handleChangeDD = (event) => {
    setfolderValue22(event.target.value.value);
};

  const handleChangeDDD = (event) => {
      setfileValue2(event.target.value.value);
  };


    const defaultItem = { value: "현장 사진 폴더를 선택해주세요",  };
    const defaultItem2 = { value: "파일을 선택해주세요",  };

    const delay = 500;

 const [state, setState] = useState({
    data: folder,
    loading: false
  });

  useEffect(() => {
    setState({
        data: folder,
        loading: false
      });
  }, [folder]);

  const [state2, setState2] = useState({
    data: files,
    loading: false
  });

  useEffect(() => {
    setState2({
        data: files,
        loading: false
      });
  }, [files]);

  /////작업일보 state
  const defaultItemD = { value: "작업일보 업체를 선택해주세요",  };
  const defaultItemDD = { value: "날짜를 선택해주세요",  };
  const defaultItemDDD = { value: "파일을 선택해주세요",  };

  const [Dstate, setDState] = useState({
    data: folder2,
    loading: false
  });

  useEffect(() => {
    setDState({
        data: folder2,
        loading: false
      });
  }, [folder2]);

  const [Dstate1, setDState1] = useState({
    data: folder22,
    loading: false
  });

  useEffect(() => {
    setDState1({
        data: folder22,
        loading: false
      });
  }, [folder22]);

  const [Dstate2, setDState2] = useState({
    data: files2,
    loading: false
  });

  useEffect(() => {
    setDState2({
        data: files2,
        loading: false
      });
  }, [files2]);



  const timeout = useRef(false);

  
  const filterData = (filter) => {
    const data = folder.slice();
    return filterBy(data, filter);
  };



  const filterChange = (event) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setState({
        loading: false,
        data: filterData(event.filter)
      });
    }, delay);
    setState({ ...state, loading: true });
  };


  const filterData2 = (filter) => {
    const data = files.slice();
    return filterBy(data, filter);
  };



  const filterChange2 = (event) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setState2({
        loading: false,
        data: filterData2(event.filter)
      });
    }, delay);
    setState2({ ...state, loading: true });
  };

  ////작업일보 필터

  
  const filterDataD = (filter) => {
    const data = folder2.slice();
    return filterBy(data, filter);
  };



  const filterChangeD = (event) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setDState({
        loading: false,
        data: filterDataD(event.filter)
      });
    }, delay);
    setDState({ ...Dstate, loading: true });
  };

   
  const filterDataDD = (filter) => {
    const data = folder22.slice();
    return filterBy(data, filter);
  };



  const filterChangeDD = (event) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setDState1({
        loading: false,
        data: filterDataDD(event.filter)
      });
    }, delay);
    setDState1({ ...Dstate1, loading: true });
  };

  const filterDataDDD = (filter) => {
    const data = files2.slice();
    return filterBy(data, filter);
  };



  const filterChangeDDD = (event) => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      setDState2({
        loading: false,
        data: filterDataDDD(event.filter)
      });
    }, delay);
    setDState2({ ...Dstate2, loading: true });
  };

  console.log(Dstate.data)

    return (


        <div>
            <div className="fluid-c">
                    <DropDownList style={{width: "300px", margin:'5px'}} data={state.data} textField="value" defaultItem={defaultItem}  onChange={handleChange} filterable={true} onFilterChange={filterChange}/>
                    <DropDownList style={{width: "300px", margin:'5px'}} data={state2.data} textField="value" onChange={handleChange2} defaultItem={defaultItem2}  onFilterChange={filterChange2}/>
<div style={{marginLeft:50}}>
                    <DropDownList style={{width: "300px", margin:'5px'}} data={Dstate.data} textField="value" defaultItem={defaultItemD}  onChange={handleChangeD} filterable={true} onFilterChange={filterChangeD}/>
                    <DropDownList style={{width: "300px", margin:'5px'}} data={Dstate1.data} textField="value" defaultItem={defaultItemDD}  onChange={handleChangeDD} filterable={true} onFilterChange={filterChangeDD}/>
                    <DropDownList style={{width: "300px", margin:'5px'}} data={Dstate2.data} textField="value" onChange={handleChangeDDD} defaultItem={defaultItemDDD}  onFilterChange={filterChangeDDD}/>
                    </div>
            </div>
            <div className="fluid">

                <div className="fluid__image-container">
                    {src &&
                        <ReactImageMagnify {...{
                            smallImage: {
                                src: src,
                                isFluidWidth: true,
                                sizes: '(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px'
                            },
                            largeImage: {
                                src: src,
                                width: 3000,
                                height: 3000
                            }
                        }} />
                    }
                </div>
                <div className="fluid__instructions">

                </div>

            </div>
   
        </div>

    );


};

export default Image
