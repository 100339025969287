import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import axios from 'axios';
import {
    GridLayout,
    GridLayoutItem,
} from "@progress/kendo-react-layout";
import moment from 'moment';
import {
    Chart,
    ChartArea,
    ChartSeries,
    ChartSeriesItem,
    ChartTooltip, ChartTitle,ChartCategoryAxisItem, ChartCategoryAxis, ChartLegend
} from "@progress/kendo-react-charts";
import { Grid, GridColumn } from "@progress/kendo-react-grid";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}


function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}


const FinanceProfit = () => {
    const [RevenueA, setRevenueA] = useState([]);
    const [RevenueP, setRevenueP] = useState([]);
    const [ProfitP, setProfitP] = useState([]);
    const [ProfitA, setProfitA] = useState([]);
    const [department, setdepartment] = useState([]);
    const { height, width } = useWindowDimensions();
    const [lastmonth, setlastmonth] = useState();




    useEffect(() => {
        const fetchData = async () => {

            let body =
            {
                "bpname": "Dashboard (PL&CF)",
                "lineitem": "no",
                "filter_criteria": {
                    "join": "AND",
                    "filter": [
                        {
                            "field": "status",
                            "value": "Active",
                            "condition_type": "eq"
                        }
                    ]
                }
            }

            const res = await axios.post(`https://dash.dtsolution.kr/api/getbprecords?path=HW-01`, body)

            const filter = res.data.data.data.filter(v => v.PlanActual_srb === '실적' && v.yearMonth !== null && v.YearSPD !== null)
                .map((v) => ({ ...v, yearMonth: v.YearSPD + '-' + v.umuMonthPD }))

            const last = _.sortBy(filter, 'yearMonth').reverse()[0]['yearMonth']

            setlastmonth(last)

            const data = filter.filter(v => v.yearMonth === last).map((v) => (
                [
                    { month: '1월', 매출: v.Revenue01, 세전이익: v.PretaxProfit01, 사업부: v.Department_spd },
                    { month: '2월', 매출: v.Revenue02, 세전이익: v.PretaxProfit02, 사업부: v.Department_spd },
                    { month: '3월', 매출: v.Revenue03, 세전이익: v.PretaxProfit03, 사업부: v.Department_spd },
                    { month: '4월', 매출: v.Revenue04, 세전이익: v.PretaxProfit04, 사업부: v.Department_spd },
                    { month: '5월', 매출: v.Revenue05, 세전이익: v.PretaxProfit05, 사업부: v.Department_spd },
                    { month: '6월', 매출: v.Revenue06, 세전이익: v.PretaxProfit06, 사업부: v.Department_spd },
                    { month: '7월', 매출: v.Revenue07, 세전이익: v.PretaxProfit07, 사업부: v.Department_spd },
                    { month: '8월', 매출: v.Revenue08, 세전이익: v.PretaxProfit08, 사업부: v.Department_spd },
                    { month: '9월', 매출: v.Revenue09, 세전이익: v.PretaxProfit09, 사업부: v.Department_spd },
                    { month: '10월', 매출: v.Revenue10, 세전이익: v.PretaxProfit10, 사업부: v.Department_spd },
                    { month: '11월', 매출: v.Revenue11, 세전이익: v.PretaxProfit11, 사업부: v.Department_spd },
                    { month: '12월', 매출: v.Revenue12, 세전이익: v.PretaxProfit12, 사업부: v.Department_spd },


                ]
                // .map(function (obj) { var index = obj.사업부; obj[index] = obj.매출;delete obj['세전이익']; delete obj['사업부']; delete obj['매출'];  return obj; })


            )).flat()


            const filter2 = res.data.data.data.filter(v => v.PlanActual_srb === '사업계획')

            const data2 =

                filter2.map((v) => (
                    [
                        { month: '1월', 매출: v.Revenue01, 세전이익: v.PretaxProfit01, 사업부: v.Department_spd },
                        { month: '2월', 매출: v.Revenue02, 세전이익: v.PretaxProfit02, 사업부: v.Department_spd },
                        { month: '3월', 매출: v.Revenue03, 세전이익: v.PretaxProfit03, 사업부: v.Department_spd },
                        { month: '4월', 매출: v.Revenue04, 세전이익: v.PretaxProfit04, 사업부: v.Department_spd },
                        { month: '5월', 매출: v.Revenue05, 세전이익: v.PretaxProfit05, 사업부: v.Department_spd },
                        { month: '6월', 매출: v.Revenue06, 세전이익: v.PretaxProfit06, 사업부: v.Department_spd },
                        { month: '7월', 매출: v.Revenue07, 세전이익: v.PretaxProfit07, 사업부: v.Department_spd },
                        { month: '8월', 매출: v.Revenue08, 세전이익: v.PretaxProfit08, 사업부: v.Department_spd },
                        { month: '9월', 매출: v.Revenue09, 세전이익: v.PretaxProfit09, 사업부: v.Department_spd },
                        { month: '10월', 매출: v.Revenue10, 세전이익: v.PretaxProfit10, 사업부: v.Department_spd },
                        { month: '11월', 매출: v.Revenue11, 세전이익: v.PretaxProfit11, 사업부: v.Department_spd },
                        { month: '12월', 매출: v.Revenue12, 세전이익: v.PretaxProfit12, 사업부: v.Department_spd },


                    ]
                    // .map(function (obj) { var index = obj.사업부; obj[index] = obj.매출;delete obj['세전이익']; delete obj['사업부']; delete obj['매출'];  return obj; })


                )).flat()

            var Revenue1 =
                _(data)
                    .groupBy('month')
                    .map((objs, key) => ({
                        'month': key,
                        'array': objs.reduce((r, { 사업부, 매출, month }) => ({ [사업부]: 매출, 'month': month, ...r }), {}),

                    }))
                    .value();

            setRevenueA([...Revenue1.map((v) => (v.array))])

            var Profit1 =
                _(data)
                    .groupBy('month')
                    .map((objs, key) => ({
                        'month': key,
                        'array': objs.reduce((r, { 사업부, 세전이익, month }) => ({ [사업부]: 세전이익, 'month': month, ...r }), {}),

                    }))
                    .value()

            setProfitA(Profit1.map((v) => (v.array)))

            var Revenue2 =
                _(data2)
                    .groupBy('month')
                    .map((objs, key) => ({
                        'month': key,
                        'array': objs.reduce((r, { 사업부, 매출, month }) => ({ [사업부]: 매출, 'month': month, ...r }), {}),

                    }))
                    .value()

            setRevenueP(Revenue2.map((v) => (v.array)))

            var Profit2 =
                _(data2)
                    .groupBy('month')
                    .map((objs, key) => ({
                        'month': key,
                        'array': objs.reduce((r, { 사업부, 세전이익, month }) => ({ [사업부]: 세전이익, 'month': month, ...r }), {}),

                    }))
                    .value()

            setProfitP(Profit2.map((v) => (v.array)))

            setdepartment(
                _.uniqBy([...filter, ...filter2], 'Department_spd').map(v => v.Department_spd).filter(v => v !== '조정값')

            )

        }

        fetchData();
    }, []);

    const [isFollow, setIsFollow] = useState(department.map((v) => ({ value: v, status: true })).map(v => v.status));


    const handleChangeButton = index => {
        const newIsFollow = [...isFollow];
        newIsFollow[index] = !newIsFollow[index];
        setIsFollow(newIsFollow);
    };




    const COLORS = [
        "#FFA500",
        "#56C6A9",
        "#6F9FD8",
        "#E8A798",
        "#D9CE52",
        "#D19C97",
        "#ECDB54",
    ];

    const labelContent2 = (e) =>
        Math.abs(e.value) === 0 || e.value === null || typeof e.value === "undefined" || Math.abs(e.value) < 100
            ? ""
            : e.value.toFixed(1);

    const seriesLabels2 = {
        visible: true,
        // Note that visible defaults to false
        padding: 3,
        font: "0.8rem Arial, sans-serif",
        position: "center",
        background: "none",
        content: labelContent2,
    };



    const SharedTooltip = (props) => {
        const { points } = props;

        const data = points.filter(v => typeof v.series.stack !== 'undefined').map((v) => ({ field: v.series.field, value: v.value, group: v.series.stack.group }))
            .map(function (obj) { var index = obj.group; obj[index] = obj.value; return obj; })




        const array = data.filter(v => v.group === 'plan')
            .map((v) => ({
                field: v.field,
                actual: data.filter(f => typeof f.actual !== 'undefined').find(f => f.field === v.field).actual.toFixed(1),
                actual2: data.filter(f => typeof f.actual2 !== 'undefined').find(f => f.field === v.field).actual2.toFixed(1),
                plan: data.filter(f => typeof f.plan !== 'undefined').find(f => f.field === v.field).plan.toFixed(1),
                plan2: data.filter(f => typeof f.plan2 !== 'undefined').find(f => f.field === v.field).plan2.toFixed(1),
            }))

        const SimpleSumCell = (props) => {
            const field = props.field || "";
            const total = array.reduce((acc, current) => acc + parseFloat(current[field]), 0);
            return (
                <td
                    colSpan={props.colSpan}
                    style={{ fontSize: "0.8rem", textAlign: "center" }}
                >
                    {total.toFixed(2)}억
                </td>
            );
        };
        return (

            <>
                <div>{points[0].category}</div>
                <Grid
                    style={{ width: '420px' }}
                    className='tooltiptable'
                    data={array}
                >
                    <GridColumn field="field" title="사업부" />
                    <GridColumn field="plan" title="매출(계획)" footerCell={SimpleSumCell} />
                    <GridColumn field="actual" title="매출(추정)" footerCell={SimpleSumCell} />
                    <GridColumn field="plan2" title="세전이익(계획)" footerCell={SimpleSumCell} />
                    <GridColumn field="actual2" title="세전이익(추정)" footerCell={SimpleSumCell} />

                </Grid>

            </>
        )


    };


    const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

    const title1 = '전사 손익 (' + lastmonth + ")"

    const pbandNo = Number(lastmonth && lastmonth.slice(5,7).slice(0,1)) === 0 ? Number(lastmonth && lastmonth.slice(6,7)):Number(lastmonth && lastmonth.slice(5,7))

    const categoryPlotBands = [{
        from: pbandNo-1,
        to: pbandNo,
        color: '#f9f3ec',
        opacity: 0.6
    }];

    return (<>
        <GridLayout
            style={{ width: width, height: height }}
            rows={[
                {
                    height: "70%",
                },
                {
                    height: "10%",
                },

            ]}
            cols={[
                {
                    width: "100%",
                },
            ]}
            gap={{
                rows: 0,
                cols: 0,
            }}
        >
            <GridLayoutItem row={1} col={1} style={{ margin: "0 auto" }}>
                <Chart>
                    <ChartTitle text={title1} position="top" />
                    <ChartLegend  position="bottom" orientation="horizontal"visible={false}/>

                    <ChartArea width={width} height={height * 0.7} margin={10} />
                    <ChartTooltip shared={true} render={sharedTooltipRender} />
                    {/* <ChartCategoryAxis>
                        <ChartCategoryAxisItem labels={serieslabelcategory} />
                        <ChartCategoryAxisItem labels={serieslabelcategory2} />

                    </ChartCategoryAxis> */}

                    <ChartCategoryAxis>
                        <ChartCategoryAxisItem plotBands={categoryPlotBands} />
                    </ChartCategoryAxis>
                    <ChartSeries>
                        {department && department.map((v, index) => (
                            <ChartSeriesItem
                                key={index}
                                type="column"
                                stack={{ group: 'plan' }}
                                data={RevenueP}
                                field={v}
                                categoryField='month'
                                color={COLORS[index]}
                                labels={seriesLabels2}
                                border={{ width: '0' }}
                                visible={!isFollow[index]}
                                name='매출 계획'
                            />
                        ))}

                        {department && department.map((v, index) => (
                            <ChartSeriesItem
                                key={index}
                                type="column"
                                stack={{ group: 'actual' }}
                                data={RevenueA}
                                field={v}
                                categoryField='month'
                                color={COLORS[index]}
                                labels={seriesLabels2}
                                border={{ width: '0' }}
                                visible={!isFollow[index]}
                                name='매출 추정'


                            />
                        ))}

                        {department && department.map((v, index) => (
                            <ChartSeriesItem
                                key={index}
                                type="column"
                                stack={{ group: 'plan2' }}
                                data={ProfitP}
                                field={v}
                                categoryField='month'
                                color={COLORS[index]}
                                labels={seriesLabels2}
                                border={{ width: '0' }}
                                visible={!isFollow[index]}
                                name='세전이익 계획'

                            />
                        ))}

                        {department && department.map((v, index) => (
                            <ChartSeriesItem
                                key={index}
                                type="column"
                                stack={{ group: 'actual2' }}
                                data={ProfitA}
                                field={v}
                                categoryField='month'
                                color={COLORS[index]}
                                labels={seriesLabels2}
                                border={{ width: '0' }}
                                visible={!isFollow[index]}
                                name='세전이익 추정'

                            />
                        ))}

                    </ChartSeries>

                </Chart>



            </GridLayoutItem>
            <GridLayoutItem row={2} col={1} style={{ margin: "0 auto" }}>
                <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                    {department.map((v, index) => (
                        <React.Fragment key={index}>
                            <span>
                                <span
                                    style={{
                                        float: "left",
                                        fontSize: "1.2rem",
                                        color: isFollow[index] ? 'grey' : COLORS[index],
                                    }}
                                    className="k-icon k-i-circle"
                                ></span>
                                <span
                                    style={{
                                        float: "left",
                                        fontSize: "0.8rem",
                                        color: isFollow[index] ? 'grey' : COLORS[index],
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        handleChangeButton(index);
                                    }}

                                >
                                    {v} &nbsp;&nbsp;
                                </span>
                            </span>
                        </React.Fragment>
                    ))}
                </div>
            </GridLayoutItem>

        </GridLayout>


    </>)

}

export default FinanceProfit;
