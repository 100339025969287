import React, { useEffect, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import * as mnode from "../nodelibrary";
import "hammerjs";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import { Card, CardTitle, CardBody, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import useBreakpoint from "use-breakpoint";

const initialSort = [

];

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280, };

const QCard1 = ({positiondata}) => {

    const { breakpoint } = useBreakpoint(BREAKPOINTS);


    const [sort, setSort] = useState(initialSort);

    const [riskdata, setrisk1] = useState([]);
    const [lldata, setll] = useState([]);
    const [homework, sethomework] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const risk1 = await axios.get(`https://dash.dtsolution.kr/api/getpotential`)
            setrisk1(_.uniqBy(risk1.data.data.data, 'title'));

            const ll = await axios.get(`https://dash.dtsolution.kr/api/getlldb`)
            setll(ll.data.data.data.filter(item => item.ugenProjectName !== null));

            const risk2 = await axios.get(`https://dash.dtsolution.kr/api/getrandi`)
            const risk2_with = risk2.data.data.data.filter(item => typeof item._bp_lineitems !== 'undefined')
                .reduce((c, v) => c.concat(v._bp_lineitems.map(o => Object.assign(o, { uuu_tab_id: o.uuu_tab_id, Department: v.Department }))), []);
            const filtered_risk2 = _.filter(risk2_with, { 'uuu_tab_id': '대응 및 개선계획서' })
            sethomework(filtered_risk2);

        };

        fetchData();
    }, []);

    //op
    const [opcount, setopcount] = useState([]);
    const getOP = () => {
        let path = `/home/centos/download/file/op.json`;
        mnode.getJSON(path, setopcount);
    }
    useEffect(getOP, []);

    //ncr
    const [ncrcount, setncrcount] = useState([]);
    const getNCR = () => {
        let path = `/home/centos/download/file/ncr.json`;
        mnode.getJSON(path, setncrcount);
    }
    useEffect(getNCR, []);

     //ncr
     const [qcost, setqcost] = useState([]);
     const getQcost = () => {
         let path = `/home/centos/download/file/invioceforQ.json`;
         mnode.getJSON(path, setqcost);
     }
     useEffect(getQcost, []);

    //card count
    const op_count = opcount.length
    const ncr_count = ncrcount.length
    const riskcount = riskdata.length;
    const llcount = lldata.length;
    const homeworkcount = homework.length;

    //q cost
    const qcost_filter = _.filter(qcost, function (elem) { return elem.qcost > 0; });
    const qcost_sum =_.sumBy(qcost_filter, 'qcost');


    //q cost table
    var qcost_project =
    _(qcost_filter)
        .groupBy('project')
        .map((objs, key) => ({
            '프로젝트': key,
            '품질실패비용': _.sumBy(objs, 'qcost'),
            
        }))
        .value();


    //ll card table value
    const riskcount_project = _.map(_.countBy(riskdata, 'ProjectPK'), (val, key) => ({ name: key.slice(key.lastIndexOf('/') + 1), riskvalue: val }));
    const llcount_project = _.map(_.countBy(lldata, 'ugenProjectName'), (val, key) => ({ name: key, llvalue: val }));
    
    const llcount_with = llcount_project.map(v => ({ ...v, ...riskcount_project.find(sp => sp.name === v.name) }));

    //homework card table value
    const hwcount_project = _.map(_.countBy(homework, 'Department'), (val, key) => ({ name: key, value: val }));

    //ncr op table value
    const ncr_count_project = _.map(_.countBy(ncrcount, 'project_projectname'), (val, key) => ({ name: key, ncrvalue: val }));
    const op_count_project = _.map(_.countBy(opcount, 'project_projectname'), (val, key) => ({ name: key, opvalue: val }));

    var ncr_op_with =
    _([...ncr_count_project, ...op_count_project])
        .groupBy('name')
        .map((objs, key) => ({
            'name': key,
            'opvalue': _.sumBy(objs, 'opvalue'),
            'ncrvalue': _.sumBy(objs, 'ncrvalue'),
        }))
        .value();

        const [BoxHeight, setBoxHeight] = useState();

        useEffect(() => {
            if (positiondata.length > 0) {
                const boxHeight = positiondata[1];
                setBoxHeight(50 + 40 * (boxHeight.rowSpan - 2));
            }
        }, [positiondata]);
    
    
        const [visibleDialog, setVisibleDialog] = useState(true);
    
        const toggleDialog = () => {
            setVisibleDialog(!visibleDialog);
        };
    
    
        const [visibleDialog2, setVisibleDialog2] = useState(true);
    
        const toggleDialog2 = () => {
            setVisibleDialog2(!visibleDialog2);
        };
    
        const [visibleDialog3, setVisibleDialog3] = useState(true);
    
        const toggleDialog3 = () => {
            setVisibleDialog3(!visibleDialog3);
        };
        const [visibleDialog4, setVisibleDialog4] = useState(true);
    
        const toggleDialog4 = () => {
            setVisibleDialog4(!visibleDialog4);
        };
    
        const cellWithBackGround = (props) => {
            const total = props.dataItem.ncrvalue + Number(props.dataItem.opvalue)
        
            if (total < 0) {
              return (
                <td                
                >
                 
                </td>
              );
            }
            else if (total > 0) {
              return (
                <td                  
                >
                 {total}
                </td>
              );
            }
            return (<td></td>);
          }
        



    return (
        <>
        <GridLayout style={{height: BoxHeight}}
        rows={[
            {
                height: '25%',
            },
              {height: '25%'}, 
              {height: '25%'}, 
              {height: '25%'},
        ]}

        cols={[
            {
                width: '25%'
            },
            {
                width: '25%'
            }, {
                width: '25%'
            }, {
                width: '25%'
            },
        ]}
        gap={{
            rows: 0,
            cols: 0,
        }}> 

        
{breakpoint ==='desktop' ?
<>
                <GridLayoutItem col={1} row={1} colSpan={1} rawSpan={4} >
                    <Card
                        orientation='horizontal'
                        style={{ width: '80%', position:'relative', left:'10%'}}

                    >

                        <CardBody className='cardcenter'>
                            <CardTitle>품질 실패 비용</CardTitle>
                            <CardTitle ><span onClick={toggleDialog} className='hovertext bolder'>{qcost_sum !== 0 ? `${Number(qcost_sum/1000000).toLocaleString('ko-KR')}백만원`:'없음'}</span></CardTitle>
                        </CardBody>
                    </Card>
                    </GridLayoutItem>
                    <GridLayoutItem col={2} row={1} colSpan={1} rawSpan={4}  >

                    <Card
                        orientation='horizontal'
                        style={{ width: '80%', position:'relative', left:'10%'}}

                    >

                        <CardBody className='cardcenter'>
                            <CardTitle>부적합 & OP수</CardTitle>
                            <CardTitle > <span onClick={toggleDialog2} className='hovertext bolder'> {op_count + ncr_count}</span></CardTitle>
                        </CardBody>
                    </Card>
                    </GridLayoutItem>
                    <GridLayoutItem col={3} row={1} colSpan={1} rawSpan={4}  >

                    <Card
                        orientation='horizontal'
                        style={{ width: '80%', position:'relative', left:'10%'}}

                    >

                        <CardBody className='cardcenter'>
                            <CardTitle>Lessons Learned 적용 건수</CardTitle>
                            <CardTitle > <span onClick={toggleDialog3} className='hovertext bolder'>{riskcount} / {llcount}</span> </CardTitle>
                        </CardBody>
                    </Card>
                    </GridLayoutItem>
                    <GridLayoutItem col={4} row={1} colSpan={1} rawSpan={4}  >

                    <Card
                        orientation='horizontal'
                        style={{ width: '80%', position:'relative', left:'10%'}}

                    >

                        <CardBody className='cardcenter'>
                            <CardTitle>팀별 리스크 개선 수</CardTitle>
                            <CardTitle ><span onClick={toggleDialog4} className='hovertext bolder'>  {homeworkcount}</span> </CardTitle>
                        </CardBody>
                    </Card>
                    </GridLayoutItem>

                </>
                :
                <>
                <GridLayoutItem col={1} row={1} colSpan={4} rawSpan={1} style={{ padding: '1px'  }} >
                    <Card
                        orientation='horizontal'
                        style={{ width: '100%',  }}
                    >

                        <CardBody className='cardcenter'>
                            <CardTitle>품질 실패 비용</CardTitle>
                            <CardTitle ><span onClick={toggleDialog} className='hovertext bolder'>{qcost_sum !== 0 ? `${Number(qcost_sum/1000000).toLocaleString('ko-KR')}백만원`:'없음'}</span></CardTitle>
                        </CardBody>
                    </Card>
                </GridLayoutItem>
                <GridLayoutItem col={1} row={2} colSpan={4} rawSpan={1} style={{ padding: '1px' }} >
                    <Card
                        orientation='horizontal'
                        style={{ width: '100%', }}
                    >

                        <CardBody className='cardcenter'>
                            <CardTitle>부적합 & OP수</CardTitle>
                            <CardTitle > <span onClick={toggleDialog2} className='hovertext bolder'> {op_count + ncr_count}</span></CardTitle>
                        </CardBody>
                    </Card>
                </GridLayoutItem>
                <GridLayoutItem col={1} row={3} colSpan={4} rawSpan={1} style={{ padding: '1px' }} >
                    <Card
                        orientation='horizontal'
                        style={{ width: '100%', }}
                    >

                        <CardBody className='cardcenter'>
                            <CardTitle>Lessons Learned 적용 건수</CardTitle>
                            <CardTitle > <span onClick={toggleDialog3} className='hovertext bolder'>{riskcount} / {llcount}</span> </CardTitle>
                        </CardBody>
                    </Card>
                </GridLayoutItem>
                <GridLayoutItem col={1} row={4} colSpan={4} rawSpan={1} style={{ padding: '1px'}} >
                    <Card
                        orientation='horizontal'
                        style={{ width: '100%', }}
                    >

                        <CardBody className='cardcenter'>
                            <CardTitle>팀별 리스크 개선 수</CardTitle>
                            <CardTitle ><span onClick={toggleDialog4} className='hovertext bolder'>  {homeworkcount}</span> </CardTitle>
                        </CardBody>
                    </Card>
                </GridLayoutItem>
                </> }

        </GridLayout>

        <div>
                {!visibleDialog && (
                    <Dialog title={"프로젝트별 품질 실패 비용"} onClose={toggleDialog}>
                          <Grid
                            sortable={true}
                            sort={sort}
                            onSortChange={(e) => {
                                setSort(e.sort);
                            }}
                            data={orderBy(qcost_project, sort)}
                        >
                            <GridColumn field="프로젝트" title="프로젝트" width="300px" />
                            <GridColumn field="품질실패비용" title="품질실패비용" width="200px" />

                        </Grid>
                      
                    </Dialog>
                )
                }
                {
                    !visibleDialog2 && (
                        <Dialog title={"프로젝트별 부적합/OP 수"} onClose={toggleDialog2}>
                        <Grid
                            sortable={true}
                            sort={sort}
                            onSortChange={(e) => {
                                setSort(e.sort);
                            }}
                            data={orderBy(ncr_op_with, sort)}                         
                        >
                            <GridColumn field="name" title="프로젝트" width="200px" />
                            <GridColumn field="ncrvalue" title="NCR" width="100px" />
                            <GridColumn field="opvalue" title="OP" width="100px" />
                            <GridColumn title="Total" cell={cellWithBackGround} width="100px"  />
                        </Grid>
                        </Dialog>
                    )
                }

                {
                    !visibleDialog3 && (
                        <Dialog title={"프로젝트별 LL 적용 수"} onClose={toggleDialog3}>
                        <Grid
                            sortable={true}
                            sort={sort}
                            onSortChange={(e) => {
                                setSort(e.sort);
                            }}
                            data={orderBy(llcount_with, sort)}                         
                        >
                            <GridColumn field="name" title="프로젝트" width="200px" />
                            <GridColumn field="llvalue" title="LL" width="100px" />
                            <GridColumn field="riskvalue" title="적용" width="100px" />
                        </Grid>
                        </Dialog>
                    )
                }

{
                    !visibleDialog4 && (
                        <Dialog title={"부서별 개선 과제 수"} onClose={toggleDialog4}>
                                                   <Grid
                            sortable={true}
                            sort={sort}
                            onSortChange={(e) => {
                                setSort(e.sort);
                            }}
                            data={orderBy(hwcount_project, sort)}                         
                        >
                            <GridColumn field="name" title="부서" width="200px" />
                            <GridColumn field="value" title="건" width="50px" />
                        </Grid>
                        </Dialog>
                    )
                }




            </div>

        </>

        
    );


};

export default QCard1

