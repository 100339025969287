import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './table.css'


const containerStyle = {
    width: "100vw", height: "80vh"
};

const m_style = [
    {
        "featureType": "all",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "weight": "2.00"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#9c9c9c"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f2f2f2"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#eeeeee"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#7b7b7b"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#46bcec"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#c8d7d4"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#070707"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    }
]

const Maps = () => {

    const [projectinfo, setprojectinfo] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            const projects = await axios.get(`https://dash.dtsolution.kr/api/getProject`)

            const filterd_pjt = _.filter(projects.data.data.data, function (elem) { return elem.shell_type === 'Projects' && elem.uuu_shell_status === 'Active' && elem.uuu_latitude !== null; })


            const project_info = filterd_pjt.map((data) => {
                return {
                    ...data,
                    id: data.ugenProjectNumber,
                    position: { lat: parseFloat(data.uuu_latitude, 10), lng: parseFloat(data.uuu_longitude, 10) },
                    project_name: data.ugenProjectName,
                    project_start_date: data.uuu_project_start_date.slice(-4) + '년 ' + data.uuu_project_start_date.slice(0, 2) + '월 ' + data.uuu_project_start_date.slice(3, 5) + '일',
                    uuu_location: data.uuu_location.slice(9, 30),
                    ProjectType: data.ugenProjectTypePD,
                    status: data.ProjectClass01
                }
            })

            setprojectinfo(project_info.filter(v=>v.uuu_location ==='PLS사업부' ||v.uuu_location ==='도시개발사업부' ||v.uuu_location ==='에너지솔루션 사업부/태양광 사업'  ))


        };


        fetchData();
    }, []);


    const [activeMarker, setActiveMarker] = useState(null);

    const handleActiveMarker = (marker) => {
        if (marker === activeMarker) {
            return;
        }
        setActiveMarker(marker);
    };


    const handleOnLoad = (map) => {
        const bounds = new window.google.maps.LatLngBounds();
        projectinfo.forEach(({ position }) => bounds.extend(position));
        map.fitBounds(bounds);
    };




    return (
        <>
            <LoadScript
                googleMapsApiKey="AIzaSyBP-17SYLdi3X2v-qh8k-cK8obmONubMUU"
            >


                <GoogleMap options={{
                    styles: m_style,
                }}
                    mapContainerStyle={containerStyle}
                    onLoad={handleOnLoad}
                    center={{ lat: 37.567701, lng: 126.986545 }}
                    zoom={7}
                    onClick={() => setActiveMarker(null)}

                >
                    {projectinfo.map((v) => (
                        <Marker
                            key={v.id}
                            position={v.position}
                            onClick={() => handleActiveMarker(v.id)}
                            icon={
                                v.uuu_location === 'PLS사업부' && v.status === '사업추진' ?
                                    {
                                        url: (require('../asset/pb.png')), scaledSize: new window.google.maps.Size(25, 25),
                                        size: new window.google.maps.Size(25, 25)
                                    }
                                    :
                                    v.uuu_location === 'PLS사업부' && v.status === '사업수행' ?
                                        {
                                            url: (require('../asset/pg.png')), scaledSize: new window.google.maps.Size(25, 25),
                                            size: new window.google.maps.Size(25, 25)
                                        } :
                                        v.uuu_location === 'PLS사업부' && v.status === '완료' ?
                                            {
                                                url: (require('../asset/py.png')), scaledSize: new window.google.maps.Size(25, 25),
                                                size: new window.google.maps.Size(25, 25)
                                            }
                                            :
                                            v.uuu_location === 'PLS사업부' && v.status === '운영' ?
                                                {
                                                    url: (require('../asset/pr.png')), scaledSize: new window.google.maps.Size(25, 25),
                                                    size: new window.google.maps.Size(25, 25)
                                                }
                                                :

                                                v.uuu_location === '도시개발사업부' && v.status === '사업추진' ?
                                                    {
                                                        url: (require('../asset/bb.png')), scaledSize: new window.google.maps.Size(25, 25),
                                                        size: new window.google.maps.Size(25, 25)
                                                    }
                                                    :
                                                    v.uuu_location === '도시개발사업부' && v.status === '사업수행' ?
                                                        {
                                                            url: (require('../asset/bg.png')), scaledSize: new window.google.maps.Size(25, 25),
                                                            size: new window.google.maps.Size(25, 25)
                                                        } :
                                                        v.uuu_location === '도시개발사업부' && v.status === '완료' ?
                                                            {
                                                                url: (require('../asset/by.png')), scaledSize: new window.google.maps.Size(25, 25),
                                                                size: new window.google.maps.Size(25, 25)
                                                            }
                                                            :
                                                            v.uuu_location === '도시개발사업부' && v.status === '운영' ?
                                                                {
                                                                    url: (require('../asset/br.png')), scaledSize: new window.google.maps.Size(25, 25),
                                                                    size: new window.google.maps.Size(25, 25)
                                                                }
                                                                :


                                                                v.uuu_location === '에너지솔루션 사업부/태양광 사업' && v.status === '사업추진' ?
                                                                    {
                                                                        url: (require('../asset/eb.png')), scaledSize: new window.google.maps.Size(25, 25),
                                                                        size: new window.google.maps.Size(25, 25)
                                                                    }
                                                                    :
                                                                    v.uuu_location === '에너지솔루션 사업부/태양광 사업' && v.status === '사업수행' ?
                                                                        {
                                                                            url: (require('../asset/eg.png')), scaledSize: new window.google.maps.Size(25, 25),
                                                                            size: new window.google.maps.Size(25, 25)
                                                                        } :
                                                                        v.uuu_location === '에너지솔루션 사업부/태양광 사업' && v.status === '완료' ?
                                                                            {
                                                                                url: (require('../asset/ey.png')), scaledSize: new window.google.maps.Size(25, 25),
                                                                                size: new window.google.maps.Size(25, 25)
                                                                            }
                                                                            :
                                                                            v.uuu_location === '에너지솔루션 사업부/태양광 사업' && v.status === '운영' ?
                                                                                {
                                                                                    url: (require('../asset/er.png')), scaledSize: new window.google.maps.Size(25, 25),
                                                                                    size: new window.google.maps.Size(25, 25)
                                                                                }
                                                                                :
                                                                          ''

                            }

                        >
                            {activeMarker === v.id ? (
                                <InfoWindow onCloseClick={() => setActiveMarker(null)} >
                                    {v.uuu_location === '도시개발사업부' ?
                                        <table className='maptable'>
                                            <tbody  >
                                                <tr >
                                                    <td style={{fontWeight:'600'} } >프로젝트명</td>
                                                    <td style={{ textAlign:'right' }}>{v.project_name}</td>
                                                </tr>
                                                <tr>
                                                    <td  style={{fontWeight:'600'}}>프로젝트 위치</td>
                                                    <td style={{ textAlign:'right' }}>{v.ugenAddTXT250}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{fontWeight:'600'}} >규모 (면적)</td>
                                                    <td style={{ textAlign:'right'}}>{v.ProjectVolume}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{fontWeight:'600'}}>분양률</td>
                                                    <td style={{ textAlign:'right' }}>{v.SellProgress}%</td>
                                                </tr>
                                                <tr>
                                                    <td style={{fontWeight:'600'}}>보상률</td>
                                                    <td style={{ textAlign:'right'}}>{v.CompensateRate}%</td>
                                                </tr>
                                                <tr>
                                                    <td style={{fontWeight:'600' }}>공정률</td>
                                                    <td style={{ textAlign:'right'}}>{v.ProjectProgress}%</td>
                                                </tr>
                                                <tr>
                                                    <td style={{fontWeight:'600' }}>사업구도</td>
                                                    <td style={{ textAlign:'right'}}> {v.BusinessStructure}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{fontWeight:'600'}}>진행현황</td>
                                                    <td style={{ textAlign:'right'}}> {v.ProjectStatus}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        :
                                        v.uuu_location === '에너지솔루션 사업부/태양광 사업' && (v.ProjectClass02 !=='매전' || v.ProjectClass02 !=='O&M') ?
                                            <table className='maptable'>
                                                <tbody>
                                                    <tr>
                                                        <td  style={{fontWeight:'600' }}>프로젝트명</td>
                                                        <td style={{ textAlign:'right' }}>{v.project_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td  style={{fontWeight:'600' }}>프로젝트 위치</td>
                                                        <td style={{ textAlign:'right' }}>{v.ugenAddTXT250}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{fontWeight:'600' }} >규모(MW)</td>
                                                        <td style={{ textAlign:'right' }}>{v.ProjectVolume}</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{fontWeight:'600' }}>규모(억원)</td>
                                                        <td style={{ textAlign:'right' }}>{v.ProjectCost}</td>
                                                    </tr>
                                                    <tr>
                                                    <td style={{fontWeight:'600' }}>분양률</td>
                                                    <td style={{ textAlign:'right' }}>{v.SellProgress}%</td>
                                                </tr>
                                                <tr>
                                                    <td style={{fontWeight:'600' }}>보상률</td>
                                                    <td style={{ textAlign:'right' }}>{v.CompensateRate}%</td>
                                                </tr>
                                                <tr>
                                                    <td style={{fontWeight:'600' }}>공정률</td>
                                                    <td style={{ textAlign:'right' }}>{v.ProjectProgress}%</td>
                                                </tr>
                                                <tr>
                                                    <td style={{fontWeight:'600' }}>진행현황</td>
                                                    <td style={{ textAlign:'right' }}> {v.ProjectStatus}</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            :

                                            v.uuu_location === '에너지솔루션 사업부/태양광 사업' && (v.ProjectClass02 ==='매전' || v.ProjectClass02 ==='O&M') ?
                                            <table className='maptable'>
                                                <tbody>
                                                    <tr>
                                                        <td  style={{fontWeight:'600' }}>프로젝트명</td>
                                                        <td style={{ textAlign:'right' }}>{v.project_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td  style={{fontWeight:'600' }}>프로젝트 위치</td>
                                                        <td style={{ textAlign:'right' }}>{v.ugenAddTXT250}</td>
                                                    </tr>
                                                    <tr>
                                                        <td  style={{fontWeight:'600' }}>규모(MW)</td>
                                                        <td style={{ textAlign:'right' }}>{v.ProjectVolume}</td>
                                                    </tr>
                                                 
   
                                                <tr>
                                                    <td style={{fontWeight:'600' }}>누적발전량</td>
                                                    <td style={{ textAlign:'right' }}>{v.EnergyProduction}kw</td>
                                                </tr>
                                                <tr>
                                                    <td style={{fontWeight:'600' }}>일평균 발전시간</td>
                                                    <td style={{ textAlign:'right' }}> {v.EnergyProductionHour}h</td>
                                                </tr>
                                                </tbody>
                                            </table>
                                            :
                                            v.uuu_location === 'PLS사업부' ?
                                                <table className='maptable'>
                                                    <tbody>
                                                        <tr>
                                                            <td  style={{fontWeight:'600' }}>프로젝트명</td>
                                                            <td style={{ textAlign:'right' }}>{v.project_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <td  style={{fontWeight:'600' }}>프로젝트 위치</td>
                                                            <td style={{ textAlign:'right' }}>{v.ugenAddTXT250}</td>
                                                        </tr>
                                                        <tr>
                                                            <td  style={{fontWeight:'600' }}>규모(객실수)</td>
                                                            <td style={{ textAlign:'right' }}>{v.ProjectVolume}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{fontWeight:'600' }}>규모(억원)</td>
                                                            <td style={{ textAlign:'right' }}>{v.ProjectCost}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{fontWeight:'600' }}>발주처</td>
                                                            <td style={{ textAlign:'right' }}>{v.OwnerName}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{fontWeight:'600' }}>공정률</td>
                                                            <td style={{ textAlign:'right' }}>{v.ProjectProgress}%</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{fontWeight:'600' }}>COD</td>
                                                            <td style={{ textAlign:'right' }}> {v.ProjectCOD}</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{fontWeight:'600' }}>진행현황</td>
                                                            <td style={{ textAlign:'right' }}> {v.ProjectStatus}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                :
                                                ''
                                    }


                                </InfoWindow>
                            ) : null}
                        </Marker>
                    ))}
                </GoogleMap>
            </LoadScript>
            <div style={{ position: 'absolute', top: '10px', right: '10px' }}>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 180 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell  width={80}>구분</TableCell>
                                <TableCell align="center" width={40}>사업추진</TableCell>
                                <TableCell align="center" width={40}>사업수행</TableCell>
                                <TableCell align="center" width={40}>완료</TableCell>
                                <TableCell align="center" width={40}>운영</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    도시개발
                                </TableCell>
                                <TableCell align="center" style={{position:'relative'}}><img alt='by' width={20} src='/image/bb.png' style={{position: 'absolute', bottom: '5px', left: '50%',top:'50%', transform: `translate(-50%,-50%)`}}/></TableCell>
                                <TableCell align="center" style={{position:'relative'}}><img alt='bb' width={20} src='/image/bg.png'  style={{position: 'absolute', bottom: '5px', left: '50%',top:'50%', transform: `translate(-50%,-50%)`}}/></TableCell>
                                <TableCell align="center" style={{position:'relative'}}><img alt='bg' width={20} src='/image/by.png'  style={{position: 'absolute', bottom: '5px', left: '50%',top:'50%', transform: `translate(-50%,-50%)`}}/></TableCell>
                                <TableCell align="center" style={{position:'relative'}}><img alt='br' width={20} src='/image/br.png'  style={{position: 'absolute', bottom: '5px', left: '50%',top:'50%', transform: `translate(-50%,-50%)`}}/></TableCell>
                            </TableRow>

                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    큐셀E
                                </TableCell>
                                <TableCell align="center" style={{position:'relative'}}><img alt='eb' width={20} src='/image/eb.png'  style={{position: 'absolute', bottom: '5px', left: '50%',top:'50%', transform: `translate(-50%,-50%)`}}/></TableCell>
                                <TableCell align="center" style={{position:'relative'}}><img alt='eg' width={20} src='/image/eg.png'  style={{position: 'absolute', bottom: '5px', left: '50%',top:'50%', transform: `translate(-50%,-50%)`}}/></TableCell>
                                <TableCell align="center" style={{position:'relative'}}><img alt='ey' width={20} src='/image/ey.png'  style={{position: 'absolute', bottom: '5px', left: '50%',top:'50%', transform: `translate(-50%,-50%)`}}/></TableCell>
                                <TableCell align="center" style={{position:'relative'}}><img alt='er' width={20} src='/image/er.png'  style={{position: 'absolute', bottom: '5px', left: '50%',top:'50%', transform: `translate(-50%,-50%)`}}/></TableCell>
                            </TableRow>
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    PLS
                                </TableCell>
                                <TableCell align="center" style={{position:'relative'}}><img alt='pb' width={20} src='/image/pb.png'  style={{position: 'absolute', bottom: '5px', left: '50%',top:'50%', transform: `translate(-50%,-50%)`}}/></TableCell>
                                <TableCell align="center" style={{position:'relative'}}><img alt='pg' width={20} src='/image/pg.png'  style={{position: 'absolute', bottom: '5px', left: '50%',top:'50%', transform: `translate(-50%,-50%)`}}/></TableCell>
                                <TableCell align="center" style={{position:'relative'}}><img alt='py' width={20} src='/image/py.png'  style={{position: 'absolute', bottom: '5px', left: '50%',top:'50%', transform: `translate(-50%,-50%)`}}/></TableCell>
                                <TableCell align="center" style={{position:'relative'}}><img alt='pr' width={20} src='/image/pr.png'  style={{position: 'absolute', bottom: '5px', left: '50%',top:'50%', transform: `translate(-50%,-50%)`}}/></TableCell>
                            </TableRow>
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    칠레GES
                                </TableCell>
                                <TableCell align="center" style={{position:'relative'}}><img alt='eb' width={20} src='/image/eb.png'  style={{position: 'absolute', bottom: '5px', left: '50%',top:'50%', transform: `translate(-50%,-50%)`}}/></TableCell>
                                <TableCell align="center" style={{position:'relative'}}><img alt='eg' width={20} src='/image/eg.png'  style={{position: 'absolute', bottom: '5px', left: '50%',top:'50%', transform: `translate(-50%,-50%)`}}/></TableCell>
                                <TableCell align="center" style={{position:'relative'}}><img alt='ey' width={20} src='/image/ey.png'  style={{position: 'absolute', bottom: '5px', left: '50%',top:'50%', transform: `translate(-50%,-50%)`}}/></TableCell>
                                <TableCell align="center" style={{position:'relative'}}><img alt='er' width={20} src='/image/er.png'  style={{position: 'absolute', bottom: '5px', left: '50%',top:'50%', transform: `translate(-50%,-50%)`}}/></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

            </div>
        </>

    )
}

export default Maps


