import React, { useEffect, useState, } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { Card, CardTitle, CardBody, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import "hammerjs";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import useBreakpoint from "use-breakpoint";


const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280, };


const initialSort = [

];


const QCard = ({ positiondata }) => {

    const { breakpoint } = useBreakpoint(BREAKPOINTS);

    const [sort, setSort] = useState(initialSort);

    const { project_code } = useParams();

    const [llcount, setllcount] = useState(0);
    const [inspectioncount, setinspectioncount] = useState(0);
    const [inspectioncountnon, setinspectioncountnon] = useState(0);

    const [countqt, setcountqt] = useState(0);

    const [countncr, setcountncr] = useState(0);
    const [countncrchecked, setcountncrchecked] = useState(0);

    const [countop, setcountop] = useState(0);
    const [countopchecked, setcountopchecked] = useState(0);

    const [ncrvendor, setncrvendor] = useState([]);
    const [opvendor, setopvendor] = useState([]);
    const [countbyvendor, setcountbyvendor] = useState([]);


    useEffect(() => {
        const fetchData = async () => {


            const ll = await axios.get(`https://dash.dtsolution.kr/api/getll?path=${project_code}`)

            const count_ll = ll.data.data.data.length

            setllcount(count_ll)

            const inspection1 = await axios.get(`https://dash.dtsolution.kr/api/getrfi?path=${project_code}`)

            const inspection2 = await axios.get(`https://dash.dtsolution.kr/api/getrfpi?path=${project_code}`)

            const inspection = [...inspection1.data.data.data, ...inspection2.data.data.data];

            setinspectioncount(inspection.length);
            setinspectioncountnon(_.filter(inspection, { 'status': 'Rejected' }).length);

            const count_by_vendor1 = _.map(_.countBy(inspection, 'uuu_user_company'), (val, key) => ({ name: key, tvalue: val }));
            const count_by_vendor2 = _.map(_.countBy(_.filter(inspection, { 'status': 'Rejected' }), 'uuu_user_company'), (val, key) => ({ name: key, nvalue: val }));

            const count_by_vendor = count_by_vendor1.map(v => ({ ...v, ...count_by_vendor2.find(sp => sp.name === v.name) }));

            setcountbyvendor(count_by_vendor);

            const q_training = await axios.get(`https://dash.dtsolution.kr/api/getqt?path=${project_code}`)

            

            setcountqt(q_training.data.data.data.length);

            const ncr_data = await axios.get(`https://dash.dtsolution.kr/api/getncr?path=${project_code}`)

            const ncr_data_with = ncr_data.data.data.data.map(value => ({ unique: value.uveVendorNameTB50 + " (" + value.title + ")", ...value }));


            setcountncr(ncr_data.data.data.data.length);

            const filtered_ncr = _.filter(ncr_data.data.data.data, { 'status': "Closed" })

            setcountncrchecked(filtered_ncr.length)

            const count_ncr_by_vendor = _.map(_.countBy(ncr_data_with, 'unique'), (val, key) => ({ name: key, value: val }));

            setncrvendor(count_ncr_by_vendor);

            const op_data = await axios.get(`https://dash.dtsolution.kr/api/getop?path=${project_code}`)

            const op_data_with = op_data.data.data.data


            const op_data_x = _.filter(op_data_with, function (o) { return o.uuu_user_company === null; })
            const op_data_o = _.filter(op_data_with, function (o) { return o.uuu_user_company !== null; })

            const op_data_o_m = op_data_o.map(value => ({ unique: value.uuu_user_company + " (" + value.title.slice(value.title.indexOf(") ",) + 2) + ")", ...value }));
            const op_data_x_m = op_data_x.map(value => ({ unique: `업체 미할당(${value.title.slice(value.title.indexOf(") "))})`, ...value }));
            const op_total_v = [...op_data_o_m, ...op_data_x_m]

            setcountop(op_data.data.data.data.length);

            const filtered_op = _.filter(op_data.data.data.data, { 'status': "Closed" })

            setcountopchecked(filtered_op.length);

            const count_op_by_vendor = _.map(_.countBy(op_total_v, 'unique'), (val, key) => ({ name: key, value: val }));

            setopvendor(count_op_by_vendor);

        };

        fetchData();
    }, [project_code]);

    const [BoxHeight, setBoxHeight] = useState();

    useEffect(() => {
        if (positiondata.length > 0) {
            const boxHeight = positiondata[0];
            setBoxHeight(180 + 150 * (boxHeight.rowSpan - 1));
        }
    }, [positiondata]);


    const [visibleDialog, setVisibleDialog] = useState(true);

    const toggleDialog = () => {
        setVisibleDialog(!visibleDialog);
    };


    const [visibleDialog2, setVisibleDialog2] = useState(true);

    const toggleDialog2 = () => {
        setVisibleDialog2(!visibleDialog2);
    };

    const [visibleDialog3, setVisibleDialog3] = useState(true);

    const toggleDialog3 = () => {
        setVisibleDialog3(!visibleDialog3);
    };




    return (
        <div>
            <div>
            <GridLayout style={{height: BoxHeight}}
                rows={[
                    {height: '20%'} ,{ height: '20%'}, {height: '20%'}, {height: '20%'},{height: '20%'},                    
                ]}

                cols={[
                    { width: '20%' }, { width: '20%'}, { width: '20%'}, { width: '20%'}, {  width: '20%'},
                ]}
                gap={{
                    rows: 0,
                    cols: 0,
                }}>

                {breakpoint ==='desktop' ?
                <>
                <GridLayoutItem col={1} row={1} colSpan={1} rawSpan={5} className='qcardlayout'>
                    <Card
                        orientation='horizontal'
                        style={{ width: '100%',  }}
                    >

                        <CardBody className='cardcenter'>
                            <CardTitle   style={{fontSize:'1rem'}}>불합격수 / 총 검사수</CardTitle>
                            <CardTitle   style={{fontSize:'1rem'}}><span onClick={toggleDialog} className='hovertext bolder'>{inspectioncountnon}건 / {inspectioncount}건</span></CardTitle>
                        </CardBody>
                    </Card>
                </GridLayoutItem>
                <GridLayoutItem col={2} row={1} colSpan={1} rawSpan={5} className='qcardlayout'>
                    <Card
                        orientation='horizontal'
                        style={{ width: '100%', }}
                    >

                        <CardBody className='cardcenter'>
                            <CardTitle   style={{fontSize:'1rem'}}>품질교육</CardTitle>
                            <CardTitle className='bolder'  style={{fontSize:'1rem'}}>{countqt}건</CardTitle>
                        </CardBody>
                    </Card>
                </GridLayoutItem>
                <GridLayoutItem col={3} row={1} colSpan={1} rawSpan={5} className='qcardlayout'>
                    <Card
                        orientation='horizontal'
                        style={{ width: '100%', }}
                    >

                        <CardBody className='cardcenter'>
                            <CardTitle  style={{fontSize:'1rem'}}>조치 부적합 / 전체 부적합</CardTitle>
                            <CardTitle   style={{fontSize:'1rem'}}> <span onClick={toggleDialog2} className='hovertext bolder'>{countncrchecked}건 / {countncr}건</span> </CardTitle>
                        </CardBody>
                    </Card>
                </GridLayoutItem>
                <GridLayoutItem col={4} row={1} colSpan={1} rawSpan={5} className='qcardlayout'>
                    <Card
                        orientation='horizontal'
                        style={{ width: '100%', }}
                    >

                        <CardBody className='cardcenter'>
                            <CardTitle  style={{fontSize:'1rem'}}> 조치 OP / 전체 OP</CardTitle>
                            <CardTitle   style={{fontSize:'1rem'}}><span onClick={toggleDialog3} className='hovertext bolder'>{countopchecked}건 / {countop}건</span> </CardTitle>
                        </CardBody>
                    </Card>
                </GridLayoutItem>
                <GridLayoutItem col={5} row={1} colSpan={1} rawSpan={5} className='qcardlayout' >
                    <Card
                        orientation='horizontal'
                        style={{ width: '100%', }}
                    >

                        <CardBody className='cardcenter'>
                            <CardTitle  style={{fontSize:'1rem'}}>Lessons Learned</CardTitle>
                            <CardTitle  style={{fontSize:'1rem'}} className='bolder'>{llcount}건</CardTitle>
                        </CardBody>
                    </Card>
                </GridLayoutItem>
                </>
                 : 
                 <>
                <GridLayoutItem col={1} row={1} colSpan={4} rawSpan={1}  style={{ position: 'relative', left:'12%', top:'5%' }}>
                <Card
                    orientation='horizontal'
                    style={{ width: '100%',  }}
                >

                    <CardBody className='cardcenter'>
                        <CardTitle>불합격수 / 총 검사수</CardTitle>
                        <CardTitle ><span onClick={toggleDialog} className='hovertext bolder'>{inspectioncountnon}건 / {inspectioncount}건</span></CardTitle>
                    </CardBody>
                </Card>
            </GridLayoutItem>
            <GridLayoutItem col={1} row={2} colSpan={4} rawSpan={1} style={{ position: 'relative', left:'12%', top:'5%' }} >
                <Card
                    orientation='horizontal'
                    style={{ width: '100%', }}
                >

                    <CardBody className='cardcenter'>
                        <CardTitle>품질교육</CardTitle>
                        <CardTitle className='bolder'>{countqt}건</CardTitle>
                    </CardBody>
                </Card>
            </GridLayoutItem>
            <GridLayoutItem col={1} row={3} colSpan={4} rawSpan={1} style={{ position: 'relative', left:'12%', top:'5%' }} >
                <Card
                    orientation='horizontal'
                    style={{ width: '100%', }}
                >

                    <CardBody className='cardcenter'>
                        <CardTitle>조치 부적합 / 전체 부적합</CardTitle>
                        <CardTitle > <span onClick={toggleDialog2} className='hovertext bolder'>{countncrchecked}건 / {countncr}건</span> </CardTitle>
                    </CardBody>
                </Card>
            </GridLayoutItem>
            <GridLayoutItem col={1} row={4} colSpan={4} rawSpan={1} style={{ position: 'relative', left:'12%', top:'5%' }}>
                <Card
                    orientation='horizontal'
                    style={{ width: '100%', }}
                >

                    <CardBody className='cardcenter'>
                        <CardTitle> 조치 OP / 전체 OP</CardTitle>
                        <CardTitle ><span onClick={toggleDialog3} className='hovertext bolder'>{countopchecked}건 / {countop}건</span> </CardTitle>
                    </CardBody>
                </Card>
            </GridLayoutItem>
            <GridLayoutItem col={1} row={5} colSpan={4} rawSpan={1} style={{ position: 'relative', left:'12%', top:'5%' }}>
                <Card
                    orientation='horizontal'
                    style={{ width: '100%', }}
                >

                    <CardBody className='cardcenter'>
                        <CardTitle>Lessons Learned</CardTitle>
                        <CardTitle className='bolder'>{llcount}건</CardTitle>
                    </CardBody>
                </Card>
            </GridLayoutItem>
            </>
                }

            </GridLayout>
            </div>
            <div>
                {!visibleDialog && (
                    <Dialog title={"업체별 검사건수"} onClose={toggleDialog} height={"50%"}>
                        <Grid
                            sortable={true}
                            sort={sort}
                            onSortChange={(e) => {
                                setSort(e.sort);
                            }}
                            data={orderBy(countbyvendor, sort)}
                        >
                            <GridColumn field="name" title="회사명" width="400px" />
                            <GridColumn field="nvalue" title="불합격 건수" width="100px" />
                            <GridColumn field="tvalue" title="전체 건수" width="100px" />

                        </Grid>
                    </Dialog>
                )
                }
                {
                    !visibleDialog2 && (
                        <Dialog title={"업체별 유형별 부적합"} onClose={toggleDialog2} height={"50%"}>
                            <Grid
                                sortable={true}
                                sort={sort}
                                onSortChange={(e) => {
                                    setSort(e.sort);
                                }}
                                data={orderBy(ncrvendor, sort)}
                            >
                                <GridColumn field="name" title="업체명" width="400px" />
                                <GridColumn field="value" title="건수" width="100px" />

                            </Grid>
                        </Dialog>
                    )
                }

                {
                    !visibleDialog3 && (
                        <Dialog title={"업체별 유형별 OP"} onClose={toggleDialog3} height={"50%"}>
                            <Grid
                                sortable={true}
                                sort={sort}
                                onSortChange={(e) => {
                                    setSort(e.sort);
                                }}
                                data={orderBy(opvendor, sort)}
                            >
                                <GridColumn field="name" title="업체명" width="400px" />
                                <GridColumn field="value" title="건수" width="100px" />

                            </Grid>
                        </Dialog>
                    )
                }



            </div>
        </div>
    );
};


export default QCard

