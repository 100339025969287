import React, { useEffect, useState } from 'react';
import * as mnode from "../nodelibrary";
import _ from 'lodash';
import {
    Chart, ChartArea, ChartLegend, ChartSeries, ChartSeriesItem, ChartValueAxis, ChartValueAxisItem, ChartTooltip,ChartCategoryAxis , ChartCategoryAxisItem
} from "@progress/kendo-react-charts";

const CompanyPM4 = ({ positiondata }) => {


    const [safety, setsafety] = useState([]);

    const getSaftey = () => {
        let path = `/home/centos/download/file/safety.json`;
        mnode.getJSON(path, setsafety);
    }

    useEffect(getSaftey, []);

    const last_data = safety.sort(function compare(a, b) {
        var dateA = new Date(a.date);
        var dateB = new Date(b.date);
        return dateA - dateB;
    });

    const last_element = last_data.slice(-1)


    var chart_cum =
        _(safety)
            .groupBy('project')
            .map((objs, key) => ({
                'project': key,
                '누적직영근로자': _.sumBy(objs, '직영근로자'),
                '누적관리자': _.sumBy(objs, '관리자'),
                '누적협력업체반장': _.sumBy(objs, '협력업체반장'),
                '누적협력업체근로자': _.sumBy(objs, '협력업체근로자'),
                '누적크레인': _.sumBy(objs, '크레인'),
                '누적굴삭기': _.sumBy(objs, '굴삭기'),
                '누적기타': _.sumBy(objs, '기타'),
                '누적트럭': _.sumBy(objs, '트럭')

            }))
            .value();


    const chart_result = chart_cum.map(v => ({ ...v, ...last_element.find(sp => sp.project === v.project) }))

    const [BoxHeight, setBoxHeight] = useState();

    useEffect(() => {
        if (positiondata.length > 0) {
            const boxHeight = positiondata[6];
            setBoxHeight(155 + 150 * (boxHeight.rowSpan - 1));
        }
    }, [positiondata]);


    const tooltipcustom = (e) => e.point.series.name + ': ' + e.point.value;

    const labelContent = (e) => e.value === 0 || e.value === null || typeof e.value === 'undefined' ? '' : e.value;
    const seriesLabels = {
        visible: true,
        // Note that visible defaults to false
        padding: 3,
        font: "bold 0.8rem Arial, sans-serif",
        position: 'insideBase',
        background: 'none',
        content: labelContent
    };


    return (
        <Chart>
            <ChartArea height={BoxHeight * 0.9} />
            <ChartSeries>
                <ChartSeriesItem spacing={0} type="column" data={chart_result} field='누적크레인' categoryField="project" name='누적 양중장비' autoFit={true} labels={seriesLabels} />
                <ChartSeriesItem spacing={0} type="column" data={chart_result} field='누적굴삭기' categoryField="project" name='누적 굴삭기계' autoFit={true} labels={seriesLabels} />
                <ChartSeriesItem spacing={0} type="column" data={chart_result} field='누적트럭' categoryField="project" name='누적 운반차량' autoFit={true} labels={seriesLabels} />
                <ChartSeriesItem spacing={0} type="column" data={chart_result} field='누적기타' categoryField="project" name='누적 기타기계' autoFit={true} labels={seriesLabels} />

            </ChartSeries>

            <ChartTooltip render={tooltipcustom} />

            <ChartValueAxis>
                <ChartValueAxisItem majorTicks={{ step: 100, }} minorTicks={{ step: 100, }} majorGridLines={{ step: 100 }} labels={{ step: 5, font: "0.9rem Arial, sans-serif", }} />
            </ChartValueAxis>

            <ChartCategoryAxis>
                <ChartCategoryAxisItem labels={{ font: '0.65rem Arial' }} />
            </ChartCategoryAxis>

            <ChartLegend position="bottom" orientation="horizontal" labels={{ font: '0.65rem san-serif' }} />

        </Chart>

    )

};

export default CompanyPM4