import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import axios from 'axios';
import { Chart, ChartArea, ChartCategoryAxis, ChartCategoryAxisItem, ChartLegend, ChartPane, ChartPanes, ChartSeries, ChartSeriesItem, ChartValueAxis, ChartValueAxisItem,  } from "@progress/kendo-react-charts";
import "hammerjs";
import moment from 'moment';


const QualityComChart = ({ positiondata }) => {

    const [chartdata, setchartdata] = useState([]);
    const [startdate, setstartdate] = useState();


    useEffect(() => {
        const fetchData = async () => {

            const res = await axios.get(`https://dash.dtsolution.kr/api/getJSON?path=/home/centos/download/file/op.json`)
            const res2 = await axios.get(`https://dash.dtsolution.kr/api/getJSON?path=/home/centos/download/file/ncr.json`)
            const qcost = await axios.get(`https://dash.dtsolution.kr/api/getJSON?path=/home/centos/download/file/invioceforQ.json`)

            const ncrdata = res.data
            const opdata = res2.data

            const qcostdata = qcost.data


            const ncr_data_with_month = ncrdata.map(v => ({ YearMonth: v.IssueDateDO.toString().slice(8, 10) + "년 " + v.IssueDateDO.toString().slice(0, 2) + "월", ...v }))
            const ncr_data_with_month_count = _.map(_.countBy(ncr_data_with_month, 'YearMonth'), (val, key) => ({ name: key, ncrvalue: val }));

            const op_data_with_month = opdata.map(v => ({ YearMonth: v.IssueDateDO.toString().slice(8, 10) + "년 " + v.IssueDateDO.toString().slice(0, 2) + "월", ...v }))
            const op_data_with_month_count = _.map(_.countBy(op_data_with_month, 'YearMonth'), (val, key) => ({ name: key, opvalue: val }));

            const uniq_name = _.uniqBy([...op_data_with_month_count, ...ncr_data_with_month_count], 'name')

            const uniq_add1 = uniq_name.map(v => ({ ...v, ...ncr_data_with_month_count.find(sp => sp.name === v.name) }));

            const chartresult = uniq_add1.map(v => ({ ...v, ...op_data_with_month_count.find(sp => sp.name === v.name) }));

            const chart_with_value = chartresult.map(function (v) {
                if (typeof v.opvalue !== 'undefined' && typeof v.ncrvalue !== 'undefined') {
                    return {
                        value: Number(v.name.slice(0, 2)),
                        name: v.name,
                        opvalue: v.opvalue,
                        ncrvalue: v.ncrvalue
                    }
                }
                else if
                    (typeof v.opvalue === 'undefined' && typeof v.ncrvalue !== 'undefined') {
                    return {
                        value: Number(v.name.slice(0, 2)),
                        name: v.name,
                        opvalue: 0,
                        ncrvalue: v.ncrvalue
                    }
                }
                else if
                    (typeof v.opvalue !== 'undefined' && typeof v.ncrvalue === 'undefined') {
                    return {
                        value: Number(v.name.slice(0, 2)),
                        name: v.name,
                        opvalue: v.opvalue,
                        ncrvalue: 0
                    }
                }

                else {
                    return {
                        value: Number(v.name.slice(0, 2)),
                        name: v.name,
                        opvalue: 0,
                        ncrvalue: 0
                    }
                }
            })


            const sort_chart = _.sortBy(chart_with_value, 'name')


            const projects = await axios.get(`https://dash.dtsolution.kr/api/getProject`)
            const project_start_date = (_.filter(projects.data.data.data.filter(item=>item.uuu_location ==='/인사이트 부문/에너지솔루션 사업부/태양광 사업'), function (elem) { return typeof elem.uuu_project_start_date !== 'undefined'; })).map((v) => ({uuu_location:v.uuu_location, start: v.uuu_project_start_date.slice(6, 10)+'/'+ v.uuu_project_start_date.slice(0, 2)+'/'+v.uuu_project_start_date.slice(3, 5)}))
            

            const project_start = _.sortBy(project_start_date, 'start')[0]['start']

            setstartdate(Math.round(moment(new Date()).diff(project_start, 'months', true)))


            const project_finish_date = (_.filter(projects.data.data.data.filter(item=>item.uuu_location ==='/인사이트 부문/에너지솔루션 사업부/태양광 사업'), function (elem) { return typeof elem.ugenProjEndDateDO !== 'undefined' && elem.ugenProjEndDateDO !== null; })).map((v) => ({ end: v.ugenProjEndDateDO.slice(6, 10)+'/'+ v.ugenProjEndDateDO.slice(0, 2)+'/'+v.ugenProjEndDateDO.slice(3, 5)}))
            const project_finish = _.sortBy(project_finish_date, 'end')[project_finish_date.length - 1]['end']

            function dateRange(startDate, endDate) {
                var start = startDate.split('/');
                var end = endDate.split('/');
                var startYear = parseInt(start[0].slice(2, 4));
                var endYear = parseInt(end[0].slice(2, 4));
                var dates = [];

                for (var i = startYear; i <= endYear; i++) {
                    var endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1;
                    var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
                    for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
                        var month = j + 1;
                        var displayYear = i + "년"
                        var displayMonth = month < 10 ? '0' + month + '월' : month + '월';
                        dates.push([displayYear, displayMonth].join(' '));
                    }
                }
                return dates;
            }


            const chartdate = dateRange(String(project_start), String(project_finish)).map((data) => { return { name: data, value: Number(data.slice(0, 2)) }; })


            const chartresult2 = chartdate.map(v => ({ ...v, ...sort_chart.find(sp => sp.name === v.name) }));

            const chartdata3 = chartresult2.map(function (v) {
                if (typeof v.opvalue !== 'undefined' && typeof v.ncrvalue !== 'undefined') {
                    return {xaxis: Number("20" + v.name.slice(0, 2) + v.name.slice(4, 6)) , name: v.name, opvalue: v.opvalue, ncrvalue: v.ncrvalue, total: v.ncrvalue + v.opvalue }
                }
                else if (typeof v.opvalue === 'undefined' && typeof v.ncrvalue !== 'undefined') {
                    return { xaxis: Number("20" + v.name.slice(0, 2) + v.name.slice(4, 6)) , name: v.name, ncrvalue: v.ncrvalue, total: v.ncrvalue }
                }
                else if (typeof v.opvalue !== 'undefined' && typeof v.ncrvalue === 'undefined') {
                    return {xaxis: Number("20" + v.name.slice(0, 2) + v.name.slice(4, 6)) , name: v.name, opvalue: v.opvalue, total: v.opvalue }
                }
                else {
                    return {xaxis: Number("20" + v.name.slice(0, 2) + v.name.slice(4, 6)) , name: v.name }
                }
            })


            const qcost_with_name = qcostdata.map((v) => { return { name: v.date.slice(8, 10) + '년 ' + v.date.slice(0, 2) + '월', qcost: v.qcost } })

            var qcost_sum =
                _(qcost_with_name)
                    .groupBy('name')
                    .map((objs, key) => ({
                        'name': key,
                        'qcost': _.sumBy(objs, 'qcost'),

                    }))
                    .value();



            const chartresult3 = chartdata3.map(v => ({ ...v, ...qcost_sum.find(sp => sp.name === v.name) }));

            setchartdata(chartresult3)


        }

        fetchData();
    }, []);


    const [BoxHeight, setBoxHeight] = useState();

    useEffect(() => {
        if (positiondata.length > 0) {
            const boxHeight = positiondata[7];
            setBoxHeight(40 * (boxHeight.rowSpan ));
        }
    }, [positiondata]);



    const categories = chartdata.map(v => v.xaxis)


    const defaultStep = Math.floor(categories.length/10);


 
    const [min, setMin] = useState(55);
    const [max, setMax] = useState(75);
    const [step, setStep] = useState({
        step: 1,
    });




    const onSelectEnd = (args) => {
        setMin(args.from);
        setMax(args.to);
        setStep({
            step: Math.floor((args.to - args.from)/30),
        });
    };

    const opmin = _.min(chartdata.map(v=>v.opvalue) )
    const opmax = _.max(chartdata.map(v=>v.opvalue))
    const ncrmin =_.min(chartdata.map(v=>v.ncrvalue) )
    const ncrmax =_.max(chartdata.map(v=>v.ncrvalue) )
    const costmin = _.min(chartdata.map(v=>v.qcost))
    const costmax = _.max(chartdata.map(v=>v.qcost) )

//       const SharedTooltip = (props) => {
//     const { category, points } = props;
//     return (
//       <div>
//         <div>{category}</div>
//         {points.map((point, index) => (
//           point.series.name === '품질실패비용' ?
//             <div key={index}>
//               {point.category} {point.series.name} : {Number.parseFloat(point.value / 1000000).toFixed(0) + '백만'}
//             </div> :
           
//               <div key={index}>

//                 {point.category} {point.series.name} : {point.value}건
//               </div>
//         ))}
//       </div>
//     );
//   };

//   const sharedTooltipRender = (context) => <SharedTooltip {...context} />;


//   const labelContent = (e) => e.value ===0 || e.value === null || typeof e.value === 'undefined'? '' : e.series.name+': '+ e.value;
  const labelContent = (e) => e.value ===0 || e.value === null || typeof e.value === 'undefined'? '' :  e.value;
  const seriesLabels = {
      visible: true,
      // Note that visible defaults to false
      padding: 3,
      font: "bold 12px Arial, sans-serif",
      position: 'insideBase',
      background: 'none',
      content: labelContent
  };

  const axisCrossingValue = [0, chartdata.length];




    return (

        <Chart onSelectEnd={onSelectEnd} >
            <ChartArea height={BoxHeight } />
            <ChartCategoryAxis>
                <ChartCategoryAxisItem categories={categories} min={min} max={max} axisCrossingValue={axisCrossingValue}/>
                <ChartCategoryAxisItem categories={categories} name="navigatorAxis" labels={{ step: defaultStep, }} majorGridLines={{ step: defaultStep, }} majorTicks={{ step: defaultStep, }} pane="navigator" select={{ from: min, to: max, }}
                />
            </ChartCategoryAxis>
            <ChartPanes>
                <ChartPane />
                <ChartPane name={"navigator"} height={80} />
            </ChartPanes>
            <ChartValueAxis>
                <ChartValueAxisItem name='op'  labels={{ step: 5,font: "0.7rem Arial, sans-serif" }} title={{text: "OP",font: "0.7rem Arial, sans-serif"  }}/>
                <ChartValueAxisItem name='ncr'   majorTicks={{ step: 1, }} labels={{ step: 5, font: "0.7rem Arial, sans-serif" }} title={{text: "부적합",font: "0.7rem Arial, sans-serif" }}/>
                <ChartValueAxisItem name='cost'   labels={{ step: 5, font: "0.7rem Arial, sans-serif" }} title={{text: "품질실패비용", font: "0.7rem Arial, sans-serif"  }}/>
                <ChartValueAxisItem name="valueNavigatorAxis" pane="navigator"  labels={{ step: 5, font: "0.7rem Arial, sans-serif" }} title={{text: "OP+NCR", font: "0.7rem Arial, sans-serif"  }} majorTicks={{ step: 100, }} minorTicks={{step:100,}} majorGridLines={{step:100}}/>
            </ChartValueAxis>
            <ChartLegend position="bottom" orientation="horizontal" />
            <ChartSeries>
                <ChartSeriesItem
                    color={chartdata.color}
                    type="line"
                    data={chartdata}
                    field="qcost"
                    categoryField="name"
                    name="품질실패비용"
                    autoFit={true}
                    axis="cost"
                    labels ={seriesLabels}

                />
                <ChartSeriesItem
                    color={chartdata.color}
                    type="column"
                    data={chartdata}
                    field="ncrvalue"
                    categoryField="name"
                    name="부적합"
                    autoFit={true}
                    axis="ncr"
                    labels ={seriesLabels}

                />
                <ChartSeriesItem
                    color={chartdata.color}
                    type="column"
                    data={chartdata}
                    field="opvalue"
                    categoryField="name"
                    name="OP"
                    autoFit={true}
                    axis="op"
                    labels ={seriesLabels}

                />
                <ChartSeriesItem
                    color={chartdata.color}
                    type="column"
                    data={chartdata}
                    field="ncrvalue"
                    categoryField="name"
                    name="total"
                    autoFit={true}
                    axis="valueNavigatorAxis"
                    categoryAxis="navigatorAxis"
                />

            </ChartSeries>
        </Chart>



    );
};

export default QualityComChart

