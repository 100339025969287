import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { ResponsiveTreeMap } from '@nivo/treemap';

const Contract = ({ positiondata }) => {

  const [groups, setgroups] = useState([]);
  const { project_code } = useParams();

  useEffect(() => {
    const fetchData = async () => {
        const c_data = await axios.get(`https://dash.dtsolution.kr/api/getcontract?path=${project_code}`)
        const c_data_o = c_data.data.data.data.map((v) => {
          return {
            group: v.ContractType,
            Amount: v.i_contract_total_amt,
            Vendor: v.uveVendorNameTB50,
            ContractName: v.uconTitleSD,
            ContractDate: v.uuu_effective_date,
            volume: v.i_contract_total_amt / 100000000
          }
        });
  
  
        var data = c_data_o,
          keys = ['group', 'Vendor'],
          result = [],
          temp = { _: result };
  
        data.forEach(function (a) {
          keys.reduce(function (r, k) {
            if (!r[a[k]]) {
              r[a[k]] = { _: [] };
              r._.push({ name: a[k], children: r[a[k]]._ });
            }
            return r[a[k]];
          }, temp)._.push({name:a.ContractName, Amount: a.Amount });
        });
  
        setgroups(result);


    };

    fetchData();
  }, [project_code]);

  const treechart = {"name":'전체 계약', "children":groups}

  return (

      <ResponsiveTreeMap
        data={treechart}
        identity="name"
        value="Amount"
        label={d => new Intl.NumberFormat('ko-KR').format((d.value / 1000000).toFixed(0)) + '백만'}
        margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
        orientLabel={false}
        labelSkipSize={15}
        labelTextColor="black"
        parentLabelTextColor = "black"
        parentLabelPosition="left"
        tooltip={({ node }) => (
          <div
          style={{
            padding: 12,
            background: 'snow',
          }}
        >
          <strong style={{ color: node.color }}>
              {node.pathComponents.join(' / ')}: {  new Intl.NumberFormat('ko-KR').format((node.formattedValue  / 1000000).toFixed(0)) + '백만'}
          </strong>
          </div>
      )}
      theme={{
          tooltip: {
              container: {
                  background: '#333',
              },
          },
      }}
 
    />
 
  );

};

export default Contract

