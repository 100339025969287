import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import axios from 'axios';
import {
    GridLayout,
    GridLayoutItem,
} from "@progress/kendo-react-layout";
import moment from 'moment';
import {
    Chart,
    ChartArea,
    ChartSeries,
    ChartSeriesItem,
    ChartTooltip, ChartTitle
} from "@progress/kendo-react-charts";
import { Grid, GridColumn } from "@progress/kendo-react-grid";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}


function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}


const FinanceOverview = () => {
    const [data, setdata] = useState([]);
    const [data2, setdata2] = useState([]);
    const [financep, setfinancep] = useState([]);
    const [financea, setfinancea] = useState([]);

    const { height, width } = useWindowDimensions();


    useEffect(() => {
        const fetchData = async () => {

            let body =
            {
                "bpname": "Dashboard (PL&CF)",
                "lineitem": "no",
                "filter_criteria": {
                    "join": "AND",
                    "filter": [
                        {
                            "field": "status",
                            "value": "Active",
                            "condition_type": "eq"
                        }
                    ]
                }
            }

            let body2 =
            {
                "bpname": "Dashboard (PPV)",
                "lineitem": "yes",
                "filter_criteria": {
                    "join": "AND",
                    "filter": [
                        {
                            "field": "status",
                            "value": "Active",
                            "condition_type": "eq"
                        }
                    ]
                }
            }

            let body3 =
            {
                "bpname": "Dashboard (Finance)",
                "lineitem": "yes",
                "filter_criteria": {
                    "join": "AND",
                    "filter": [
                        {
                            "field": "status",
                            "value": "Active",
                            "condition_type": "eq"
                        }
                    ]
                }
            }


            const res = await axios.post(`https://dash.dtsolution.kr/api/getbprecords?path=HW-01`, body)
            const res2 = await axios.post(`https://dash.dtsolution.kr/api/getbprecords?path=HW-01`, body2)
            const res3 = await axios.post(`https://dash.dtsolution.kr/api/getbprecords?path=HW-01`, body3)

            const plcf = res.data.data.data.filter(v => v.umuMonthPD !== null && v.YearSPD !== null).map((v) => ({ ...v, yearMonth: v.YearSPD + '-' + v.umuMonthPD, uniq: v.YearSPD + '-' + v.umuMonthPD + v.Department_spd }))
            const last_plcf = _.sortBy(plcf, 'yearMonth').reverse()[0]['yearMonth']
            const plcf_filter = plcf.filter(v => v.yearMonth === last_plcf)


            const ppv = res2.data.data.data.map((v) => ({ ...v, yearMonth: v.YearSPD + '-' + v.umuMonthPD, uniq: v.YearSPD + '-' + v.umuMonthPD + v.Department_spd }))
                .filter(v => v.yearMonth === last_plcf)

            const all = plcf_filter.map(f => ({ ...f, ...ppv.find(sf => sf.uniq === f.uniq) }))
            delete all._bp_lineitems

            setdata(all)

            const plct_p = res.data.data.data.filter(v => v.umuMonthPD === null && v.YearSPD !== null && v.PlanActual_srb === '사업계획').map((v) => ({ ...v, uniq: v.YearSPD + '-' + v.umuMonthPD + v.Department_spd }))
            const plan_all = plct_p.map(f => ({ ...f, ...ppv.find(sf => sf.Department_spd === f.Department_spd) }))

            setdata2(plan_all)

            const finance_a = res3.data.data.data.filter(v => v.umuMonthPD !== null && v.YearSPD !== null).map((v) => ({ ...v, yearMonth: v.YearSPD + '-' + v.umuMonthPD }))
                .filter(v => v.yearMonth === last_plcf)[0]['_bp_lineitems']
            // .map(function (obj) {  delete obj['li_num'];return obj; })


            setfinancea([{
                type: '부채비율', 기타:
                    ((
                        // _.sumBy(finance_a.filter(v => v.FinanceClass01 === '부채'), 'FinanceDA01') +
                        // _.sumBy(finance_a.filter(v => v.FinanceClass01 === '부채'), 'FinanceDA02') +
                        // _.sumBy(finance_a.filter(v => v.FinanceClass01 === '부채'), 'FinanceDA03') +
                        // _.sumBy(finance_a.filter(v => v.FinanceClass01 === '부채'), 'FinanceDA04') +
                        // _.sumBy(finance_a.filter(v => v.FinanceClass01 === '부채'), 'FinanceDA05') +
                        // _.sumBy(finance_a.filter(v => v.FinanceClass01 === '부채'), 'FinanceDA06') +
                        // _.sumBy(finance_a.filter(v => v.FinanceClass01 === '부채'), 'FinanceDA07') +
                        // _.sumBy(finance_a.filter(v => v.FinanceClass01 === '부채'), 'FinanceDA08') +
                        // _.sumBy(finance_a.filter(v => v.FinanceClass01 === '부채'), 'FinanceDA09') +
                        // _.sumBy(finance_a.filter(v => v.FinanceClass01 === '부채'), 'FinanceDA10') +
                        // _.sumBy(finance_a.filter(v => v.FinanceClass01 === '부채'), 'FinanceDA11') +
                        _.sumBy(finance_a.filter(v => v.FinanceClass01 === '부채'), 'FinanceDA12')) /
                        (
                            // _.sumBy(finance_a.filter(v => v.FinanceClass01 === '자본'), 'FinanceDA01') +
                            // _.sumBy(finance_a.filter(v => v.FinanceClass01 === '자본'), 'FinanceDA02') +
                            // _.sumBy(finance_a.filter(v => v.FinanceClass01 === '자본'), 'FinanceDA03') +
                            // _.sumBy(finance_a.filter(v => v.FinanceClass01 === '자본'), 'FinanceDA04') +
                            // _.sumBy(finance_a.filter(v => v.FinanceClass01 === '자본'), 'FinanceDA05') +
                            // _.sumBy(finance_a.filter(v => v.FinanceClass01 === '자본'), 'FinanceDA06') +
                            // _.sumBy(finance_a.filter(v => v.FinanceClass01 === '자본'), 'FinanceDA07') +
                            // _.sumBy(finance_a.filter(v => v.FinanceClass01 === '자본'), 'FinanceDA08') +
                            // _.sumBy(finance_a.filter(v => v.FinanceClass01 === '자본'), 'FinanceDA09') +
                            // _.sumBy(finance_a.filter(v => v.FinanceClass01 === '자본'), 'FinanceDA10') +
                            // _.sumBy(finance_a.filter(v => v.FinanceClass01 === '자본'), 'FinanceDA11') +
                            _.sumBy(finance_a.filter(v => v.FinanceClass01 === '자본'), 'FinanceDA12'))) * 100


            }].map((v) => ({ ...v, actual_total: v.기타 })))

            const finance_p = res3.data.data.data.filter(v => v.umuMonthPD === null && v.YearSPD !== null && v.PlanActual_srb === '사업계획')[0]['_bp_lineitems']


            setfinancep([{
                type: '부채비율', 기타:
                    ((
                        // _.sumBy(finance_p.filter(v => v.FinanceClass01 === '부채'), 'FinanceDA01') +
                        // _.sumBy(finance_p.filter(v => v.FinanceClass01 === '부채'), 'FinanceDA02') +
                        // _.sumBy(finance_p.filter(v => v.FinanceClass01 === '부채'), 'FinanceDA03') +
                        // _.sumBy(finance_p.filter(v => v.FinanceClass01 === '부채'), 'FinanceDA04') +
                        // _.sumBy(finance_p.filter(v => v.FinanceClass01 === '부채'), 'FinanceDA05') +
                        // _.sumBy(finance_p.filter(v => v.FinanceClass01 === '부채'), 'FinanceDA06') +
                        // _.sumBy(finance_p.filter(v => v.FinanceClass01 === '부채'), 'FinanceDA07') +
                        // _.sumBy(finance_p.filter(v => v.FinanceClass01 === '부채'), 'FinanceDA08') +
                        // _.sumBy(finance_p.filter(v => v.FinanceClass01 === '부채'), 'FinanceDA09') +
                        // _.sumBy(finance_p.filter(v => v.FinanceClass01 === '부채'), 'FinanceDA10') +
                        // _.sumBy(finance_p.filter(v => v.FinanceClass01 === '부채'), 'FinanceDA11') +
                        _.sumBy(finance_p.filter(v => v.FinanceClass01 === '부채'), 'FinanceDA12')) /
                        (
                            // _.sumBy(finance_p.filter(v => v.FinanceClass01 === '자본'), 'FinanceDA01') +
                            // _.sumBy(finance_p.filter(v => v.FinanceClass01 === '자본'), 'FinanceDA02') +
                            // _.sumBy(finance_p.filter(v => v.FinanceClass01 === '자본'), 'FinanceDA03') +
                            // _.sumBy(finance_p.filter(v => v.FinanceClass01 === '자본'), 'FinanceDA04') +
                            // _.sumBy(finance_p.filter(v => v.FinanceClass01 === '자본'), 'FinanceDA05') +
                            // _.sumBy(finance_p.filter(v => v.FinanceClass01 === '자본'), 'FinanceDA06') +
                            // _.sumBy(finance_p.filter(v => v.FinanceClass01 === '자본'), 'FinanceDA07') +
                            // _.sumBy(finance_p.filter(v => v.FinanceClass01 === '자본'), 'FinanceDA08') +
                            // _.sumBy(finance_p.filter(v => v.FinanceClass01 === '자본'), 'FinanceDA09') +
                            // _.sumBy(finance_p.filter(v => v.FinanceClass01 === '자본'), 'FinanceDA10') +
                            // _.sumBy(finance_p.filter(v => v.FinanceClass01 === '자본'), 'FinanceDA11') +
                            _.sumBy(finance_p.filter(v => v.FinanceClass01 === '자본'), 'FinanceDA12'))) * 100

            }].map((v) => ({ ...v, plan_total: v.기타 })))

        }

        fetchData();
    }, []);


    const plan1 = data2.map((v) => ({
        매출:
            v['Revenue01'] + v['Revenue02'] + v['Revenue03'] + v['Revenue04'] + v['Revenue05'] + v['Revenue06'] + v['Revenue07'] + v['Revenue08'] + v['Revenue09'] + v['Revenue10'] + v['Revenue11'] + v['Revenue12'],
        사업부: v.Department_spd, type2: '계획'
    }))
        .map(function (obj) { var index = obj.사업부; obj[index] = obj.매출; obj['type'] = '매출'; delete obj['매출']; delete obj['사업부']; return obj; })

    const plan2 = data2.map((v) => ({
        세전이익:
            v['PretaxProfit01'] + v['PretaxProfit02'] + v['PretaxProfit03'] + v['PretaxProfit04'] + v['PretaxProfit05'] + v['PretaxProfit06'] + v['PretaxProfit07'] + v['PretaxProfit08'] + v['PretaxProfit09'] + v['PretaxProfit10'] + v['PretaxProfit11'] + v['PretaxProfit12'],

        사업부: v.Department_spd, type2: '계획'
    }))
        .map(function (obj) { var index = obj.사업부; obj[index] = obj.세전이익; obj['type'] = '세전이익'; delete obj['세전이익']; delete obj['사업부']; return obj; })

    const plan3 = data2.map((v) => ({
        순차입금:
            v['NetDebt12'],

        사업부: v.Department_spd, type2: '계획'
    }))
        .map(function (obj) { var index = obj.사업부; obj[index] = obj.순차입금; obj['type'] = '순차입금'; delete obj['순차입금']; delete obj['사업부']; return obj; })

    const plan4 = data2.map((v) => ({ PPV: v['TargetPPV'], 사업부: v.Department_spd, type2: '계획' }))
        .map(function (obj) { var index = obj.사업부; obj[index] = obj.PPV; obj['type'] = 'PPV'; delete obj['PPV']; delete obj['사업부']; return obj; })

    const actual1 = data.map((v) => ({
        매출:
            v['Revenue01'] + v['Revenue02'] + v['Revenue03'] + v['Revenue04'] + v['Revenue05'] + v['Revenue06'] + v['Revenue07'] + v['Revenue08'] + v['Revenue09'] + v['Revenue10'] + v['Revenue11'] + v['Revenue12'],
        사업부: v.Department_spd, type2: '추정'
    }))
        .map(function (obj) { var index = obj.사업부; obj[index] = obj.매출; obj['type'] = '매출'; delete obj['매출']; delete obj['사업부']; return obj; })

    const actual2 = data.map((v) => ({
        세전이익:
            v['PretaxProfit01'] + v['PretaxProfit02'] + v['PretaxProfit03'] + v['PretaxProfit04'] + v['PretaxProfit05'] + v['PretaxProfit06'] + v['PretaxProfit07'] + v['PretaxProfit08'] + v['PretaxProfit09'] + v['PretaxProfit10'] + v['PretaxProfit11'] + v['PretaxProfit12'],
        사업부: v.Department_spd, type2: '추정'
    }))
        .map(function (obj) { var index = obj.사업부; obj[index] = obj.세전이익; obj['type'] = '세전이익'; delete obj['세전이익']; delete obj['사업부']; return obj; })

    const actual3 = data.map((v) => ({
        순차입금:
            v['NetDebt12'],
        사업부: v.Department_spd, type2: '추정'
    }))
        .map(function (obj) { var index = obj.사업부; obj[index] = obj.순차입금; obj['type'] = '순차입금'; delete obj['순차입금']; delete obj['사업부']; return obj; })

    const actual4 = data.map((v) => ({ PPV: v['actualppv'], 사업부: v.Department_spd, type2: '추정' }))
        .map(function (obj) { var index = obj.사업부; obj[index] = obj.PPV; obj['type'] = 'PPV'; delete obj['PPV']; delete obj['사업부']; return obj; })
        .map((v) =>({...v,조정값:typeof v.조정값 === 'undefined' ? 0:v.조정값 }))



    const department = _.uniq(data2.map(v => v.Department_spd));
    const plan_type = [Object.assign({}, ...plan1), Object.assign({}, ...plan2), Object.assign({}, ...plan3), Object.assign({}, ...plan4)]
    const actual_type = [Object.assign({}, ...actual1), Object.assign({}, ...actual2), Object.assign({}, ...actual3), Object.assign({}, ...actual4)].map((v) => ({ ...v, 기타: v.기타 + v.조정값 }))
        // .map(function (obj) { delete obj['조정값']; return obj; })


    const plan_total = [
        { type: '매출', plan_total: _.sum(plan_type.filter(v => v.type === '매출')[0] && _.filter(Object.values(plan_type.filter(v => v.type === '매출')[0]), x => typeof x === 'number'&& isNaN(x) === false)) },
        { type: '세전이익', plan_total: _.sum(plan_type.filter(v => v.type === '세전이익')[0] && _.filter(Object.values(plan_type.filter(v => v.type === '세전이익')[0]), x => typeof x === 'number'&& isNaN(x) === false)) },
        { type: '순차입금', plan_total: _.sum(plan_type.filter(v => v.type === '순차입금')[0] && _.filter(Object.values(plan_type.filter(v => v.type === '순차입금')[0]), x => typeof x === 'number'&& isNaN(x) === false)) },
        { type: 'PPV', plan_total: _.sumBy(data2, 'TargetPPV') },
    ]

    const actual_total = [
        { type: '매출', actual_total: _.sum(actual_type.filter(v => v.type === '매출')[0] && _.filter(Object.values(actual_type.filter(v => v.type === '매출')[0]), x => typeof x === 'number' && isNaN(x) === false)) },
        { type: '세전이익', actual_total: _.sum(actual_type.filter(v => v.type === '세전이익')[0] && _.filter(Object.values(actual_type.filter(v => v.type === '세전이익')[0]), x => typeof x === 'number'&& isNaN(x) === false)) },
        { type: '순차입금', actual_total: _.sum(actual_type.filter(v => v.type === '순차입금')[0] && _.filter(Object.values(actual_type.filter(v => v.type === '순차입금')[0]), x => typeof x === 'number'&& isNaN(x) === false)) },
        { type: 'PPV', actual_total: _.sumBy(data2, 'actualppv') },
    ]

    const plan_last = plan_type.map(f => ({ ...f, ...plan_total.find(sf => sf.type === f.type) }))
    const actual_last = actual_type.map(f => ({ ...f, ...actual_total.find(sf => sf.type === f.type) }))

    const last_plan = [...plan_last, ...financep];
    const last_actual = [...actual_last, ...financea];


    const COLORS = [
        "#FFA500",
        "#56C6A9",
        "#6F9FD8",
        "#E8A798",
        "#D9CE52",
        "#D19C97",
        "#ECDB54",
    ];

    const labelContent2 = (e) =>
        Math.abs(e.value) === 0 || e.value === null || typeof e.value === "undefined" || Math.abs(e.value) < 100
            ? ""
            : e.value.toFixed(1);

    const seriesLabels2 = {
        visible: true,
        // Note that visible defaults to false
        padding: 3,
        font: "0.8rem Arial, sans-serif",
        position: "center",
        background: "none",
        content: labelContent2,
    };
    const labelContentL = (e) =>
        e.category === '부채비율' ? e.value.toFixed(0) + '%' : e.value.toFixed(0) + '억';


    const marginf = (e) => e.category === '순차입금' ? { left: 140, bottom: 50 } : { left: 140, bottom: 0 };
    const marginf2 = (e) => e.category === '순차입금' ? { right: 140, bottom: 50 } : { right: 140, bottom: 0 };

    const seriesLabelsL = {
        visible: true,
        // Note that visible defaults to false
        margin: marginf2,
        font: "bold 0.8rem Arial, sans-serif",
        position: "top",
        background: "none",
        content: labelContentL,
    };

    const seriesLabelsR = {
        visible: true,
        // Note that visible defaults to false
        margin: marginf,
        font: "bold 0.8rem Arial, sans-serif",
        position: "top",
        background: "none",
        content: labelContentL,
    };




    const SharedTooltip = (props) => {
        const { points } = props;

        const data = points.filter(v => typeof v.series.stack !== 'undefined').map((v) => ({ field: v.series.field, value: v.value, group: v.series.stack.group }))
            .map(function (obj) { var index = obj.group; obj[index] = obj.value; return obj; })


        const array = data.filter(v => v.group === 'plan')
            .map((v) => ({
                field: v.field, 
                actual:data.filter(f => typeof f.actual !== 'undefined').find(f => f.field === v.field) && data.filter(f => typeof f.actual !== 'undefined').find(f => f.field === v.field).actual.toFixed(1),
                plan: data.filter(f => typeof f.plan !== 'undefined').find(f => f.field === v.field)&&data.filter(f => typeof f.plan !== 'undefined').find(f => f.field === v.field).plan.toFixed(1)
            }))

        const SimpleSumCell = (props) => {
            const field = props.field || "";
            const total = 
            // _.sumBy(array, field)
            array.reduce((acc, current) => acc + parseFloat(current[field] === null ? 0:current[field] ), 0);
            return (
                <td
                    colSpan={props.colSpan}
                    style={{ fontSize: "0.8rem", textAlign: "center" }}
                >
                    {total.toFixed(2)}
                </td>
            );
        };

        return (

            <>
                <div>{points[0].category}</div>
                <Grid
                    style={{ width: '300px' }}
                    className='tooltiptable'
                    data={array}
                >
                    <GridColumn field="field" title="사업부" />
                    <GridColumn field="plan" title="계획" footerCell={SimpleSumCell} />
                    <GridColumn field="actual" title="추정" footerCell={SimpleSumCell} />

                </Grid>

            </>


        )


    };


    const sharedTooltipRender = (context) => <SharedTooltip {...context} />;


    return (<>
        <GridLayout
            style={{ width: width, height: height }}
            rows={[
                {
                    height: "70%",
                },
                {
                    height: "10%",
                },

            ]}
            cols={[
                {
                    width: "100%",
                },
            ]}
            gap={{
                rows: 0,
                cols: 0,
            }}
        >

            <GridLayoutItem row={2} col={1} style={{ position: 'relative', width: width }}>
            <div style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                    {department.map((v, index) => (
                        <React.Fragment key={index}>
                            <span>
                                <span
                                    style={{
                                        float: "left",
                                        fontSize: "1.2rem",
                                        color: COLORS[index],
                                    }}
                                    className="k-icon k-i-circle"
                                ></span>
                                <span
                                    style={{
                                        float: "left",
                                        fontSize: "0.8rem",
                                        color: COLORS[index],
                                    }}
                                >
                                    {v} &nbsp;&nbsp;
                                </span>
                            </span>
                        </React.Fragment>
                    ))}
                </div>
            </GridLayoutItem>
            <GridLayoutItem row={1} col={1} style={{ margin: "0 auto" }}>

                <Chart>
                    <ChartTitle text='전사 재무지표' position="top" />
                    <ChartArea width={width} height={height * 0.7} margin={10} />
                    <ChartTooltip shared={true} render={sharedTooltipRender} />
                    {/* <ChartCategoryAxis>
                        <ChartCategoryAxisItem labels={serieslabelcategory} />
                        <ChartCategoryAxisItem labels={serieslabelcategory2} />

                    </ChartCategoryAxis> */}
                    <ChartSeries>
                        {department && department.map((v, index) => (
                            <ChartSeriesItem
                                key={index}
                                type="column"
                                stack={{ group: 'plan' }}
                                data={last_plan}
                                field={v}
                                categoryField='type'
                                color={COLORS[index]}
                                labels={seriesLabels2}
                                border={{ width: '0' }}

                            />
                        ))}
                        <ChartSeriesItem
                            type="line"
                            data={last_plan}
                            field='plan_total'
                            categoryField='type'
                            width={0}
                            markers={{
                                visible: false,
                            }}

                            labels={seriesLabelsL}

                        />
                        {department && department.map((v, index) => (
                            <ChartSeriesItem
                                key={index}
                                type="column"
                                stack={{ group: 'actual' }}
                                data={last_actual}
                                field={v}
                                categoryField='type'
                                color={COLORS[index]}
                                labels={seriesLabels2}
                                border={{ width: '0' }}
                            />
                        ))}

                        <ChartSeriesItem
                            type="line"
                            data={last_actual}
                            field='actual_total'
                            categoryField='type'
                            labels={seriesLabelsR}
                            width={0}
                            markers={{
                                visible: false,
                            }}
                        />
                    </ChartSeries>

                </Chart>

            </GridLayoutItem>



        </GridLayout>


    </>)

}

export default FinanceOverview;
