import React, { useEffect, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import * as mnode from "../nodelibrary";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";

const QTable2 = ({positiondata}) => {

    const [riskdata, setriskdata] = useState([]);

    const [sort, setSort] = useState([{}]);

    const getData = () => {

        let path = `/home/centos/download/file/projectrisk.json`;
        mnode.getJSON(path, setriskdata);
    }

    useEffect(getData, []);



    const projectlist = _.uniqBy(riskdata, 'project_projectname').map((v) => {
        return {
            project: v.project_projectname,
            완료: _.filter(_.filter(riskdata, { 'project_projectname': v.project_projectname }), { 'status': 'Closed' }).length,
            전체: _.filter(_.filter(riskdata, { 'project_projectname': v.project_projectname }), { 'status': 'Open' }).length + _.filter(_.filter(riskdata, { 'project_projectname': v.project_projectname }), { 'status': 'Closed' }).length,
            지연: _.filter(_.filter(_.filter(_.filter(riskdata, function (elem) { return elem.ugenDueDateDO !== null; }), { 'project_projectname': v.project_projectname }), { 'status': 'Open' }), function (elem) { return new Date(elem.ugenDueDateDO) < new Date(); }).length

        }
    })


    const [homework, sethomework] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const risk2 = await axios.get(`https://dash.dtsolution.kr/api/getrandi`)
            const risk2_with = risk2.data.data.data.filter(item => typeof item._bp_lineitems !== 'undefined')
                .reduce((c, v) => c.concat(v._bp_lineitems.map(o => Object.assign(o, { Department: v.Department, status: v.status }))), []);

            const filtered_risk_total = _.filter(risk2_with, { 'uuu_tab_id': '대응 및 개선계획서' })
            const filtered_risk_approved = _.filter(filtered_risk_total, { 'status': 'Approved' })
            const filtered_risk_pending = _.filter(filtered_risk_total, { 'status': 'Pending' })
            const filtered_risk_delay = _.filter(_.filter(filtered_risk_pending, function (elem) { return elem.RemedyTargetDate !== null; }), function (elem) { return new Date(elem.RemedyTargetDate) < new Date(); })

            const risk_count_by_team_total = _.map(_.countBy(filtered_risk_total, 'Department'), (val, key) => ({ team: key, total: val }));
            const risk_count_by_team_approved = _.map(_.countBy(filtered_risk_approved, 'Department'), (val, key) => ({ team: key, approved: val }));
            const risk_count_by_team_delay = _.map(_.countBy(filtered_risk_delay, 'Department'), (val, key) => ({ team: key, delayed: val }));

            const risk_count1 = risk_count_by_team_total.map(v => ({ ...v, ...risk_count_by_team_approved.find(sp => sp.team === v.team) }));
            const risk_count2 = risk_count1.map(v => ({ ...v, ...risk_count_by_team_delay.find(sp => sp.team === v.team) }));


            sethomework(risk_count2);

        };

        fetchData();
    }, []);

    
    const [BoxHeight, setBoxHeight] = useState();

    useEffect(() => {
        if (positiondata.length > 0) {
            const boxHeight = positiondata[3];
            setBoxHeight(40 * (boxHeight.rowSpan)-10);
        }
    }, [positiondata]);


    return (
        <GridLayout style={{height: BoxHeight}}
 

        cols={[
            {
                width: '100%'
            },
       
        ]}
        rows={[            {
            height: '45%',
        },            {
            height: '5%',
        },            {
            height: '45%',
        },]}
        gap={{
            rows: 0,
            cols: 0,
        }}> 
        
        <GridLayoutItem col={1} row={1} colSpan={1} >
            <div>프로젝트별</div>
            <Grid className='qtable'data={orderBy(projectlist, sort)} sortable={true} sort={sort} onSortChange={(e) => { setSort(e.sort); }} style={{ height: BoxHeight * 0.42 }}>
                <GridColumn field="project" title="프로젝트명"/>
                <GridColumn field="전체" title="대상건수"/>
                <GridColumn field="완료" title="완료건수"/>
                <GridColumn field="지연" title="지연건수"/>
            </Grid>
        </GridLayoutItem>
        <GridLayoutItem col={1} row={3}colSpan={1} >
        <div>부서별</div>
        <Grid className='qtable' data={orderBy(homework, sort)} sortable={true} sort={sort} onSortChange={(e) => { setSort(e.sort); }} style={{ height: BoxHeight * 0.42 }} >
                <GridColumn field="team" title="부서명"/>
                <GridColumn field="total" title="대상건수"/>
                <GridColumn field="approved" title="완료건수"/>
                <GridColumn field="delayed" title="지연건수"/>
            </Grid>
        </GridLayoutItem>
        
        </GridLayout>

    );

}

export default QTable2