import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
    GridLayout,
    GridLayoutItem, 
} from "@progress/kendo-react-layout";
import moment from 'moment';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}


const Meeting7 = () => {

    const { height, width } = useWindowDimensions();

    const { project_code } = useParams();
    const [ment, setment] = useState([]);
  
    const today = moment().format("YYYY-MM")

  
    useEffect(() => {
      const fetchData = async () => {
        const qn = await axios.get(`https://dash.dtsolution.kr/api/getmeeting1?path=${project_code}`)
        setment(qn.data.data.data.filter(item=>item.smmMeetingDO.slice(6,10)+'-'+item.smmMeetingDO.slice(0,2) ===today).map(v => v.smmAoCRT))
  
      }
  
      fetchData();
    }, [project_code,today]);

  


  return (
    <>
     <GridLayout style={{ height: height*0.8 ,width:width}}
                rows={[
                    {height: '100%',},
                ]}

                cols={[   
                    {width: '100%',},]}

                gap={{
                    rows: 10,
                    cols: 10,
                }}>
                <GridLayoutItem  col={1} row={1}rowSpan={1} style={{backgroundColor:'#fafafa',padding:'16px', overflow:'auto'}} >
                <div style={{textAlign:'left', fontSize:'0.9rem'}} dangerouslySetInnerHTML={{ __html:ment}} className='ment2'></div>
                </GridLayoutItem>
           



                </GridLayout>
    </>
  )

    
};

export default Meeting7;