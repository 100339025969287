import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { Chart, ChartArea, ChartCategoryAxis, ChartCategoryAxisItem, ChartLegend, ChartSeries, ChartSeriesItem, ChartValueAxis, ChartValueAxisItem, ChartTooltip, exportVisual, ChartTitle } from "@progress/kendo-react-charts";
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import { exportImage } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";


const QualityChart = ({ positiondata }) => {



  const { project_code } = useParams();
  const [chartdata, setchartdata] = useState([]);

  const [qcost, setqcost] = useState(0);
  const [budget, setbudget] = useState(0);


  useEffect(() => {
    const fetchData = async () => {
      const ncr_data = await axios.get(`https://dash.dtsolution.kr/api/getncr?path=${project_code}`)
      const ncr_data_with = ncr_data.data.data.data.map(value => ({ unique: value.uveVendorNameTB50 + " (" + value.title + ")", ...value }));
      const ncr_data_with_month = ncr_data_with.map(v => ({ YearMonth: v.IssueDateDO.toString().slice(8, 10) + "년 " + v.IssueDateDO.toString().slice(0, 2) + "월", ...v }))
      const ncr_data_with_month_count = _.map(_.countBy(ncr_data_with_month, 'YearMonth'), (val, key) => ({ name: key, ncrvalue: val }));

      const op_data = await axios.get(`https://dash.dtsolution.kr/api/getop?path=${project_code}`)
      const op_data_with = op_data.data.data.data
      const op_data_with_month = op_data_with.map(v => ({ YearMonth: v.IssueDateDO.toString().slice(8, 10) + "년 " + v.IssueDateDO.toString().slice(0, 2) + "월", ...v }))
      const op_data_with_month_count = _.map(_.countBy(op_data_with_month, 'YearMonth'), (val, key) => ({ name: key, opvalue: val }));


      const projects = await axios.get(`https://dash.dtsolution.kr/api/getProject`)
      const project_start_date = projects.data.data.data.filter(function (el) {
        return el.ugenProjectNumber === project_code
      })[0].uuu_project_start_date;
      const project_finish_date = projects.data.data.data.filter(function (el) {
        return el.ugenProjectNumber === project_code
      })[0].ugenProjEndDateDO;

      function dateRange(startDate, endDate) {
        var start = startDate.split('/');
        var end = endDate.split('/');
        var startYear = parseInt(start[2].slice(2, 4));
        var endYear = parseInt(end[2].slice(2, 4));
        var dates = [];

        for (var i = startYear; i <= endYear; i++) {
          var endMonth = i !== endYear ? 11 : parseInt(end[0]) - 1;
          var startMon = i === startYear ? parseInt(start[0]) - 1 : 0;
          for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
            var month = j + 1;
            var displayYear = i + "년"
            var displayMonth = month < 10 ? '0' + month + '월' : month + '월';
            dates.push([displayYear, displayMonth].join(' '));
          }
        }
        return dates;
      }

      const chartdate = dateRange(project_start_date, project_finish_date).map((data) => { return { name: data }; })
      const chartresult = chartdate.map(v => ({ ...v, ...op_data_with_month_count.find(sp => sp.name === v.name) }));
      const chartresult2 = chartresult.map(v => ({ ...v, ...ncr_data_with_month_count.find(sp => sp.name === v.name) }));

      const qcostdata = await axios.get(`https://dash.dtsolution.kr/api/getinvoice?path=${project_code}`)

      const qcost_with_name = qcostdata.data.data.data.map((v) => { return { name: v.uuu_creation_date.slice(8, 10) + '년 ' + v.uuu_creation_date.slice(0, 2) + '월', qcost: v.QualFailCostCA } })

      var qcost_sum =
        _(qcost_with_name)
          .groupBy('name')
          .map((objs, key) => ({
            'name': key,
            'qcost': _.sumBy(objs, 'qcost'),

          }))
          .value();

      const chartresult3 = chartresult2.map(v => ({ ...v, ...qcost_sum.find(sp => sp.name === v.name) }));
      setchartdata(chartresult3);

      const budget = await axios.get(`https://dash.dtsolution.kr/api/getIF?path=${project_code}`)
      const budget_change = await axios.get(`https://dash.dtsolution.kr/api/getBC?path=${project_code}`)

      const budget_sum = _.sumBy(budget.data.data.data, 'amount') + _.sumBy(budget_change.data.data.data, 'amount');
      const qcost_sum2 = _.sumBy(qcostdata.data.data.data, 'QualFailCostCA')

      setbudget(budget_sum);
      setqcost(qcost_sum2);

    };

    fetchData();
  }, [project_code]);

  const [BoxHeight, setBoxHeight] = useState();

  useEffect(() => {
    if (positiondata.length > 0) {
      const boxHeight = positiondata[1];
      setBoxHeight(180 + 150 * (boxHeight.rowSpan - 1));
    }
  }, [positiondata]);

  const [zoomableyn, setzoomableyn] = useState(false);

  const toggleZoom = () => {
    setzoomableyn(!zoomableyn);
  };

  const chart = useRef(null);

  const onImageExportClick = () => {
    const chartVisual = exportVisual(chart.current);

    if (chartVisual) {
      exportImage(chartVisual).then((dataURI) => saveAs(dataURI, "chart.png"));
    }
  };

  const SharedTooltip = (props) => {
    const { category, points } = props;
    return (
      <div>
        <div>{category}</div>
        {points.map((point, index) => (
          point.series.name === 'Open Point' || point.series.name === '부적합' ?
            <div key={index}>
              {point.category} {point.series.name} : {point.value}건
            </div>
            :
            <div key={index}>
              {point.category} {point.series.name} : {new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW' }).format(point.value)}
            </div>
        ))}
      </div>
    );
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;




  const Labels = {
    visible: true,
    padding: 3,
    font: `${(BoxHeight/540)*0.7}rem Arial, sans-serif`,
    position: 'center',
    background: 'none',
  };


  return (
    <div>
      <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
        <ButtonGroup>
          <Button
            title="확대/이동 켜기"
            iconClass='k-icon k-i-zoom-in'
            themeColor={zoomableyn ? "primary" : null} fillMode="flat"
            onClick={toggleZoom}
          >
          </Button>

          <Button
            title="이미지로 저장"
            iconClass='k-icon k-i-image-export'
            fillMode="flat"
            onClick={onImageExportClick}
          >
          </Button>
        </ButtonGroup>
      </div>

      <Chart ref={chart} pannable={zoomableyn} zoomable={zoomableyn}>
        <ChartTitle text={qcost > 0 ? `품질실패비용: ${qcost / 1000000}백만 | 전체 예산대비 ${((qcost / budget) * 100).toFixed(2)}%` : '품질실패비용: 0 | 전체 예산대비 0%'} font="1rem Arial"/>
        <ChartArea height={BoxHeight } />
        <ChartLegend position="bottom" orientation="horizontal" />
        <ChartValueAxis>
          <ChartValueAxisItem
            majorGridLines={0} minorGridLines={0} labels={{font:"0.8rem Arial"}}
          />
        </ChartValueAxis>
        <ChartTooltip shared={true} render={sharedTooltipRender} />
        <ChartSeries>
          <ChartSeriesItem color={chartdata.color} type="column" data={chartdata} field="opvalue" categoryField="name" name="Open Point" autoFit={true} />
          <ChartSeriesItem color={chartdata.color} type="column" data={chartdata} field="ncrvalue" categoryField="name" name="부적합" autoFit={true} />
          <ChartSeriesItem color={chartdata.color} type="line" data={chartdata} field="qcost" categoryField="name" name="품질 비용" autoFit={true} />

        </ChartSeries>
        <ChartCategoryAxis>
          <ChartCategoryAxisItem labels={Labels}    majorGridLines={0} minorGridLines={0}/>
        </ChartCategoryAxis>
      </Chart>
    </div>
  );
};

export default QualityChart

