import React, { useState, useEffect } from 'react';
import { TileLayout } from "@progress/kendo-react-layout";
import '@progress/kendo-theme-default/dist/all.css';
import useBreakpoint from "use-breakpoint";
import _ from 'lodash';
import { Dialog } from "@progress/kendo-react-dialogs";
import { ListView } from "@progress/kendo-react-listview";
import { Switch } from "@progress/kendo-react-inputs";
import "../kendocustom.css";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import CompanyMans from '../safety/companycards';
import CompanyHSE from '../safety/companysafetyreport';
import CompanyIncident from '../safety/companyincident';
import CompanyPM1 from '../safety/projectman1';
import CompanyPM2 from '../safety/projectman2';
import CompanyPM3 from '../safety/projectman3';
import CompanyPM4 from '../safety/projectman4';
import CompanyMPM1 from '../safety/projectmonthman1';
import CompanyMPM2 from '../safety/projectmonthman2';

const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280, };

const GridSafety = () => {

    const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop')
    const [IncidentYear2, setIncidentYear2] = useState('');


    const GRID_CONFIG = {
        mobile: [

            {
                col: 1,
                colSpan: 12,
                rowSpan: 2,
                status: true,
            },
            {
                col: 1,
                colSpan: 12,
                status: true,
                rowSpan: 2,
            },
            {
                col: 1,

                colSpan: 12,
                status: true,
                rowSpan: 2,
            },

            {
                col: 1,
                colSpan: 12,
                rowSpan: 1,
                status: true,
            },
            {
                col: 1,
                colSpan: 12,
                rowSpan: 1,
                status: true,
            },
            {
                col: 1,
                colSpan: 12,
                rowSpan: 1,
                status: true,
            },


            {
                col: 1,
                colSpan: 12,
                rowSpan: 1,
                status: true,
            },
            {
                col: 1,
                colSpan: 12,
                rowSpan: 1,
                status: true,
            }, {
                col: 1,
                colSpan: 12,
                rowSpan: 1,
                status: true,
            },

        ],

        tablet: [

            {
                col: 1,

                colSpan: 3,
                rowSpan: 2,
                status: true,
            },
            {
                col: 4,

                colSpan: 4,
                status: true,
                rowSpan: 2,
            },
            {
                col: 8,

                colSpan: 5,
                status: true,
                rowSpan: 2,
            },

            {
                col: 1,
                colSpan: 3,
                rowSpan: 1,
                status: true,
            },
            {
                col: 4,
                colSpan: 3,
                rowSpan: 1,
                status: true,
            },
            {
                col: 7,
                colSpan: 3,
                rowSpan: 1,
                status: true,
            }, {
                col: 10,
                colSpan: 3,
                rowSpan: 1,
                status: true,
            },



            {
                col: 1,
                colSpan: 6,
                rowSpan: 1,
                status: true,
            },
            {
                col: 7,

                colSpan: 6,
                rowSpan: 1,
                status: true,
            },

        ],

        desktop: [

            {
                col: 1,

                colSpan: 3,
                rowSpan: 2,
                status: true,
            },
            {
                col: 4,

                colSpan: 4,
                status: true,
                rowSpan: 2,
            },
            {
                col: 8,

                colSpan: 5,
                status: true,
                rowSpan: 2,
            },

            {
                col: 1,
                colSpan: 3,
                rowSpan: 1,
                status: true,
            },
            {
                col: 4,
                colSpan: 3,
                rowSpan: 1,
                status: true,
            },
            {
                col: 7,
                colSpan: 3,
                rowSpan: 1,
                status: true,
            }, {
                col: 10,
                colSpan: 3,
                rowSpan: 1,
                status: true,
            },



            {
                col: 1,
                colSpan: 6,
                rowSpan: 1,
                status: true,
            },
            {
                col: 7,

                colSpan: 6,
                rowSpan: 1,
                status: true,
            },

        ]
    }

    const [GRIDCONFIG, setGRIDCONFIG] = useState(GRID_CONFIG);

    const GRIDCONFIG_filterd = { mobile: _.filter(GRIDCONFIG['mobile'], { 'status': true }), tablet: _.filter(GRIDCONFIG['tablet'], { 'status': true }), desktop: _.filter(GRIDCONFIG['desktop'], { 'status': true }) }

    useEffect(() => {
        setPositionData(GRIDCONFIG_filterd[breakpoint])
    }, [breakpoint]);


    const [positiondata, setPositionData] = useState(GRID_CONFIG['desktop']);


    const [fixblock, setfixblock] = useState(true);

    const togglefix = () => {
        setfixblock(!fixblock);
    };


    const tiles = [
        {
            header: (<div>전 현장 출력현황</div>),
            body: <CompanyMans positiondata={positiondata} />,
            status: true,
            key: '출력현황',
            resizable: fixblock,
            reorderable: fixblock,

        },
        {
            header: <div style={{ position: 'relative' }}>전 현장 안전시정조치요구서 </div>,
            body: <CompanyHSE positiondata={positiondata} />,
            status: true,
            key: '안전시정조치요구서',
            resizable: fixblock,
            reorderable: fixblock,
        },

        {
            header: (
                <div style={{ position: "relative" }}>
                    {IncidentYear2 && `${IncidentYear2} 전 현장 재해현황`}

                </div>
            ),
            body: <CompanyIncident positiondata={positiondata} setIncidentYear2={setIncidentYear2} />,
            status: true,
            key: '재해현황',
            resizable: fixblock,
            reorderable: fixblock,


        }, {
            header: (<div style={{ position: "relative" }}> 프로젝트별 일일 출력  </div>),
            body: <CompanyPM1 positiondata={positiondata} />,
            status: true,
            key: '프로젝트별 일일 출력',
            resizable: fixblock,
            reorderable: fixblock,


        }, {
            header: (<div style={{ position: "relative" }}> 프로젝트별 누적 출력  </div>),
            body: <CompanyPM2 positiondata={positiondata} />,
            status: true,
            key: ' 프로젝트별 누적 출력',
            resizable: fixblock,
            reorderable: fixblock,


        }, {
            header: (<div style={{ position: "relative" }}> 프로젝트별 일일 장비  </div>),
            body: <CompanyPM3 positiondata={positiondata} />,
            status: true,
            key: '프로젝트별 일일 장비',
            resizable: fixblock,
            reorderable: fixblock,


        }, {
            header: (<div style={{ position: "relative" }}> 프로젝트별 누적 장비  </div>),
            body: <CompanyPM4 positiondata={positiondata} />,
            status: true,
            key: '프로젝트별 누적 장비',
            resizable: fixblock,
            reorderable: fixblock,


        },
        {
            header: (<div style={{ position: "relative" }}> 프로젝트별 월간 출력  </div>),
            body: <CompanyMPM1 positiondata={positiondata} />,
            status: true,
            key: '프로젝트별 월간 출력',
            resizable: fixblock,
            reorderable: fixblock,


        },
        {
            header: (<div style={{ position: "relative" }}> 프로젝트별 월간 장비</div>),
            body: <CompanyMPM2 positiondata={positiondata} />,
            status: true,
            key: '프로젝트별 월간 장비',
            resizable: fixblock,
            reorderable: fixblock,


        },
    ];

    const handleReposition = e => {

        setPositionData(e.value);
        // setPositionData(e.value.map((v) => { return { col: v.col, row: v.row, rowSpan: v.rowSpan, colSpan: v.colSpan } }));
    };


    const flow = "row dense";

    const [tile, settile] = useState(tiles);
    const tile_filtered = _.filter(tile, ['status', true]);

    useEffect(() => {
        settile(tiles);
    }, [positiondata, fixblock]);



    const toggle_function = (key) => {

        const newData = tile.map(item => {
            if (item.key === key) {
                item.status = item.status === true ? false : true;
            }
            return item;
        })
        settile(newData);


        let col_index = _.findIndex(tiles, ['key', key])

        const mobile = GRIDCONFIG['mobile'].map((item, index) => {
            if (index === col_index) {
                item.status = item.status === true ? false : true;

            }
            return item;
        })
        const tablet = GRIDCONFIG['tablet'].map((item, index) => {
            if (index === col_index) {
                item.status = item.status === true ? false : true;

            }
            return item;
        })
        const desktop = GRIDCONFIG['desktop'].map((item, index) => {
            if (index === col_index) {
                item.status = item.status === true ? false : true;

            }
            return item;
        })

        setGRIDCONFIG({ mobile: mobile, tablet: tablet, desktop: desktop });

    };


    const [visibleDialog, setVisibleDialog] = useState(true);

    const toggleDialog = () => {
        setVisibleDialog(!visibleDialog);
    };


    const MyItemRender = (props) => {
        let item = props.dataItem;
        return (
            <div
                className="border-bottom align-middle"
                style={{
                    margin: 10,
                    display: 'flex',
                    borderBottom: '2px'
                }}
            >
                <div style={{ width: '70%', flex: '1' }}>
                    {item.key}

                </div>
                <div style={{ width: '30%', flex: '1', position: 'absolute', right: '0' }}>
                    <Switch size={'small'} checked={item.status} onChange={() => toggle_function(item.key)} />

                </div>
            </div>
        );
    };


    return (
        <div>
            {visibleDialog && (
                <div style={{ position: 'absolute', top: '0px', right: '20px', zIndex: '100' }}>
                    <ButtonGroup>
                        <Button title="블록 온오프" iconClass="k-icon k-i-grid" onClick={toggleDialog}></Button >
                        <Button title="고정하기" iconClass={fixblock ? "k-icon k-i-unpin" : "k-icon k-i-pin"} togglable={true} onClick={togglefix}></Button>
                    </ButtonGroup>
                </div>
            )}

            <TileLayout
                columns={12}
                rowHeight={200}
                positions={positiondata}
                gap={{
                    rows: 10,
                    columns: 10
                }}
                items={tile_filtered}
                onReposition={handleReposition}
                autoFlow={flow}
            />

            {!visibleDialog && (
                <Dialog title={"블록리스트"} onClose={toggleDialog}>
                    <div className='row'>
                        <ListView
                            data={tile}
                            item={MyItemRender}
                            style={{
                                width: "100%",
                                minWidth: 250
                            }}
                        />
                    </div>
                </Dialog>
            )}
        </div>
    )

        ;
};

export default GridSafety;