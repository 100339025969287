import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
  GridLayout,
  GridLayoutItem, 
} from "@progress/kendo-react-layout";
import moment from 'moment';
import Hse1 from '../charts/safetyreport';
import Incident from '../charts/incident';



function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}





const Meeting6 = () => {

  const { height, width } = useWindowDimensions();

  const { project_code } = useParams();
  const [ment, setment] = useState([]);

  const today = moment().format("YYYY-MM")

  useEffect(() => {
    const fetchData = async () => {
      const qn = await axios.get(`https://dash.dtsolution.kr/api/getmeeting2?path=${project_code}`)
      setment(qn.data.data.data.filter(f=>f.status ==='Active').filter(item=>item.smmMeetingDO.slice(6,10)+'-'+item.smmMeetingDO.slice(0,2) ===today).map(v => v.smmHSERT))

    }

    fetchData();
  }, [project_code,today]);


  const positiondata = [
    {
      col: 1,
      colSpan: 4,
      rowSpan: 3,
      status: true,
    },
    {
      col: 5,
      colSpan: 8,
      rowSpan: 2.5,
      status: true,
    },
    {
      col: 1,
      colSpan: 4,
      status: true,
      rowSpan: 2.5,
    },
    {
      col: 5,
      colSpan: 8,
      status: true,
      rowSpan: 3,
    },
    {
      col: 5,
      colSpan: 8,
      status: true,
      rowSpan: 2,
    },
    {
      col: 5,
      colSpan: 8,
      status: true,
      rowSpan: 2.2,
    }, {
      col: 5,
      colSpan: 8,
      status: true,
      rowSpan: 2.2,
    },
    {
      col: 5,
      colSpan: 8,
      status: true,
      rowSpan: 1.8,
    },



  ]


  const [IncidentYear, setIncidentYear] = useState('');

  return (
    <>
      <GridLayout style={{ height: height * 0.8, width: width }}
        rows={[
          { height: '60%', }, { height: '40%', },
        ]}

        cols={[
          { width: (width-10)/2 },  { width: (width-10)/2 },]}

        gap={{
          rows: 10,
          cols: 10,
        }}>

        <GridLayoutItem row={1} col={1} style={{ backgroundColor: '#fafafa', padding:'16px', position:'relative'}} className='meeting'>
        <div style={{fontSize:'1.2rem', height:'2.1rem' ,fontWeight:'bold'}}>{today.slice(0,4)}년 재해 현황</div>
          <Incident positiondata={positiondata} setIncidentYear={setIncidentYear} />
        </GridLayoutItem>
        <GridLayoutItem row={1} col={2} style={{ backgroundColor: '#fafafa', padding:'16px'}} className='meeting'>
          <div style={{fontSize:'1.2rem', height:'2.1rem' ,fontWeight:'bold'}}>유해위험 요인 발굴 및 직원 참여 현황</div>
          <Hse1 positiondata={positiondata} />
        </GridLayoutItem>
        <GridLayoutItem row={2} col={1} colSpan={2} style={{ backgroundColor: '#fafafa' , padding:'16px', overflow:'auto'}}>

        <div style={{textAlign:'left', fontSize:'0.9rem'}} dangerouslySetInnerHTML={{ __html:ment}} className='ment'></div>
        </GridLayoutItem>

      </GridLayout>
    </>
  )


};

export default Meeting6;