
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Route ,BrowserRouter, Switch } from 'react-router-dom';
import GridMain from './grid/gridmain';
import GridProcurement from './grid/gridprocurement';
import Image from './charts/image';
import Maps from './company/companymaps';
import GridQ from './grid/gridquality';
import GridQC from './grid/gridqualitycompany';
import GridSafety from './grid/gridsafety';
import GridProcurementC from './grid/gridcompanyprocurement';
import Meeting1 from './meeting/meeting1';
import Meeting3 from './meeting/meeting3';
import Meeting4 from './meeting/meeting4';
import Meeting2 from './meeting/meeting2';
import Meeting5 from './meeting/meeting5';
import Meeting6 from './meeting/meeting6';
import Meeting7 from './meeting/meeting7';
import Meeting8 from './meeting/meeting8';
import FinanceOverview from './finance/overview';
import FinanceProfit from './finance/profit';
import FinanceCash from './finance/cash';
import FinanceF from './finance/finance';
import FinancePPV from './finance/ppv';
import KPI from './charts/kpi';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <Switch>
  <Route path="/:project_code" component={GridMain} exact/>
  <Route path="/q/:project_code" component={GridQ} exact/>
  <Route path="/p/:project_code" component={GridProcurement} exact/>
  <Route path="/image/:project_code" component={Image} exact/>
  <Route path="/insight/maps" component={Maps} exact/> 
  <Route path="/insight/quality" component={GridQC} exact/> 
  <Route path="/insight/safety" component={GridSafety} exact/> 
  <Route path="/insight/p" component={GridProcurementC} exact/> 

  <Route path="/:project_code/meeting1" component={Meeting1} exact/>
  <Route path="/:project_code/meeting2" component={Meeting2} exact/>
  <Route path="/:project_code/meeting3" component={Meeting3} exact/>
  <Route path="/:project_code/meeting4" component={Meeting4} exact/>
  <Route path="/:project_code/meeting5" component={Meeting5} exact/>
  <Route path="/:project_code/meeting6" component={Meeting6} exact/>
  <Route path="/:project_code/meeting7" component={Meeting7} exact/>
  <Route path="/:project_code/meeting8" component={Meeting8} exact/>

  <Route path="/insight/finance" component={FinanceOverview} exact/>
  <Route path="/insight/profit" component={FinanceProfit} exact/>
  <Route path="/insight/cash" component={FinanceCash} exact/>
  <Route path="/insight/state" component={FinanceF} exact/>
  <Route path="/insight/ppv" component={FinancePPV} exact/>

  {/* <Route path="/insight/kpi" component={KPI} exact/> */}


  </Switch>

  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
