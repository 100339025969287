import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';
import {
    GridLayout,
    GridLayoutItem,
} from "@progress/kendo-react-layout";
import moment from 'moment';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}


function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

const config = {
    responseType: 'blob',

};



const Meeting1 = () => {

    const { height, width } = useWindowDimensions();


  const { project_code } = useParams();
  const [data1, setdata1] = useState([]);
  const [src1, setsrc1] = useState([]);
  const [src2, setsrc2] = useState([]);
      
  const today = moment().format("YYYY-MM")
  const foldervalue = moment().format("YY년 M월")

  useEffect(() => {
    const fetchData = async () => {
        const res = await axios.get(`https://dash.dtsolution.kr/api/getmeeting1?path=${project_code}`)

        setdata1(_.sortBy(_.filter(res.data.data.data,  function (o) { return moment(o.smmMeetingDO).format("YYYY-MM") === today;}),'record_no')
        .map((v)=> ({공사명:v.conProjectName, 공사위치:v.ugenAddTXT250,계약기간:v.smmContractPeriod,도급금액:v.smmContractAmount.toLocaleString(), 도급비고:v.smmContractRemark, 실행금액:v.smmIBAmount.toLocaleString()+' 원(VAT 제외)',매출이익:(v.smmProfitAmount).toLocaleString()+ ' 원 (VAT 제외) / '+ (((v.smmProfitAmount)/v.smmContractAmount)*100).toFixed(1)+'%', 공사내용:v.description.replaceAll('\\n', '<br/>'), 사업구도:v.smmBizRT  }))[0]
        )

        
        const meta_file = await axios.get(`https://dash.dtsolution.kr/api/getimg/?project=` + project_code + '&parent=/현장 사진/' + foldervalue)

        const files = _.sortBy(meta_file.data.data.data,'Creation Date').reverse()

        const res2 = await axios.get(`https://dash.dtsolution.kr/api/getimgfile/?file_id=` + _.get(files[0], 'file_id'), config)
        const res3 = await axios.get(`https://dash.dtsolution.kr/api/getimgfile/?file_id=` + _.get(files[1], 'file_id'), config)

        const url1 = window.URL.createObjectURL(new Blob([res2.data]));
        const url2 = window.URL.createObjectURL(new Blob([res3.data]));

        setsrc1(url1);
        setsrc2(url2);
      };
      fetchData();
  }, [project_code, today,foldervalue]);


  return (
    <>
     <GridLayout style={{ height: height*0.8 ,width:width}}
                rows={[
                    {height: '50%',}, {height: '50%',},
                ]}

                cols={[   
                    {width: width*0.5-5,}, {width: width*0.5-5,},]}

                gap={{
                    rows: 10,
                    cols: 10,
                }}>
                <GridLayoutItem  col={1} row={1}rowSpan={1} style={{backgroundColor:'#fafafa',}} ><img src={src1} alt='현장전경1' style={{objectFit:'contain', maxHeight:height*0.4, width:'90%'}}/></GridLayoutItem>
                <GridLayoutItem  col={1} row={2}rowSpan={1} style={{backgroundColor:'#fafafa', }}><img src={src2} alt='현장전경2'style={{objectFit:'contain',maxHeight:height*0.4, width:'90%'}}/></GridLayoutItem>
                <GridLayoutItem  col={2} row={1} rowSpan={2} style={{backgroundColor:'#fafafa'}}>
                {data1 && 
                <>
                <div style={{width:'95%', display:'table', margin:'0 auto'}}>
                <div style={{height:height/14 }} className='tableleft' >공사명</div>
                <div style={{height:height/14 }} className='tableright' >{data1.공사명}</div>
                </div>

                <div style={{width:'95%', display:'table', margin:'0 auto'}}>
                <div style={{height:height/14 }} className='tableleft'>공사위치</div>
                <div style={{height:height/14 }}className='tableright' >{data1.공사위치}</div>
                </div>

                <div style={{width:'95%', display:'table', margin:'0 auto'}}>
                <div style={{height:height/14 }} className='tableleft'>계약기간</div>
                <div style={{height:height/14 }}className='tableright' >{data1.계약기간}</div>
                </div>

                
                <div style={{width:'95%', display:'table', margin:'0 auto'}}>
                <div style={{height:height/14 }}className='tableleft'>도급금액</div>
                <div style={{height:height/14}}className='tableright' >{_.get(data1, '도급금액')}원(VAT 제외)<br></br> {data1.도급비고}</div>
                </div>


                <div style={{width:'95%', display:'table', margin:'0 auto'}}>
                <div style={{ height:height/14}}className='tableleft'>실행금액</div>
                <div style={{ height:height/14}}className='tableright' >{data1.실행금액}</div>
                </div>

                <div style={{width:'95%', display:'table', margin:'0 auto'}}>
                <div style={{height:height/14}}className='tableleft'>매출이익</div>
                <div style={{ height:height/14}}className='tableright' >{data1.매출이익}</div>
                </div>

                <div style={{width:'95%', display:'table', margin:'0 auto'}}>
                <div style={{height:height/14}}className='tableleft'>공사내용</div>
                <div style={{ height:height/14}}className='tableright' > <p dangerouslySetInnerHTML={{ __html:data1.공사내용 }} /></div>
                </div>


                <div style={{width:'95%', display:'table', margin:'0 auto'}}>
                <div style={{display:'table-cell', width:'15%', height:4*(height/14), borderBottom:'none'}}className='tableleft'>사업구도</div>
                <div style={{maxWidth:width*0.3, height:3*(height/14), borderBottom:'none'}}className='tableright rimage'dangerouslySetInnerHTML={{ __html:data1.사업구도 }} ></div>
                </div>
                </>
                
                }
                {/* <div dangerouslySetInnerHTML={{ __html:data1.smmAoCRT }} /> */}
                </GridLayoutItem>



                </GridLayout>
    </>
  )

    
};

export default Meeting1;