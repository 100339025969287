import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import * as mnode from "../nodelibrary";
import { Card, CardTitle, CardBody, CardImage,GridLayoutItem, GridLayout} from "@progress/kendo-react-layout";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";

const initialSort = [

];

const CompanyMans = ({positiondata}) => {


    const [sort, setSort] = useState(initialSort);

    const [safety, setsafety] = useState([]);

    const getSaftey = () => {

        let path = `/home/centos/download/file/safety.json`;
        mnode.getJSON(path, setsafety);
    }

    useEffect(getSaftey, []);

    const last_data = safety.sort(function compare(a, b) {
        var dateA = new Date(a.date);
        var dateB = new Date(b.date);
        return dateA - dateB;
    });

    const last_element = last_data.slice(-1)

    const newtrainee = last_element && _.get(last_element[0], '신규근로자');


    const AccManArray = [
        { "인력 타입": '협력업체 반장', "인원수": safety.reduce(function (cnt, o) { return cnt + o.협력업체반장; }, 0)},
        { "인력 타입": '협력업체 근로자', "인원수": safety.reduce(function (cnt, o) { return cnt + o.협력업체근로자; }, 0)},
        { "인력 타입": '관리자', "인원수": safety.reduce(function (cnt, o) { return cnt + o.관리자; }, 0)},
        { "인력 타입": '직영 근로자', "인원수": safety.reduce(function (cnt, o) { return cnt + o.직영근로자; }, 0) },
    ]
    const DailyManArray = [
        { "인력 타입": '협력업체 반장', "인원수":last_element && _.get(last_element[0], '협력업체반장')},
        { "인력 타입": '관리자', "인원수":  last_element && _.get(last_element[0], '관리자') },
        { "인력 타입": '협력업체 근로자', "인원수": last_element && _.get(last_element[0], '협력업체근로자') },
        { "인력 타입": '직영 근로자', "인원수": last_element && _.get(last_element[0], '직영근로자') },
    ]


    const DailyEquipArray = [
        { "장비 종류": '양중장비', "수량":  last_element && _.get(last_element[0], '크레인') },
        { "장비 종류": '굴착기계', "수량": last_element && _.get(last_element[0], '굴삭기') },
        { "장비 종류": '운반차량', "수량": last_element && _.get(last_element[0], '트럭') },
        { "장비 종류": '기타기계', "수량": last_element && _.get(last_element[0], '기타') },
    ]
    const AccEquipArray = [
        { "장비 종류": '양중장비', "수량": safety.reduce(function (cnt, o) { return cnt + o.크레인; }, 0)},
        { "장비 종류": '굴착기계', "수량": safety.reduce(function (cnt, o) { return cnt + o.굴삭기; }, 0) },
        { "장비 종류": '운반차량', "수량": safety.reduce(function (cnt, o) { return cnt + o.트럭; }, 0) },
        { "장비 종류": '기타기계', "수량":  safety.reduce(function (cnt, o) { return cnt + o.기타; }, 0) },
    ]



    //hse

    const [hsecount, sethsecount] = useState([]);

    const getHSE = () => {

        let path = `/home/centos/download/file/hsetraining.json`;
        mnode.getJSON(path, sethsecount);
    }

    useEffect(getHSE, []);

    const totaltrainee = (_.sumBy(hsecount, '교육자'));

    const counttraining = (hsecount.length);

    const hse_with_month = hsecount.map(value => ({ month: value.date.slice(8, 10) + "년 " + value.date.slice(0, 2) + "월", ...value }));


    var today_training = new Date().toISOString().slice(0, 10).slice(2, 4) + "년 " + new Date().toISOString().slice(0, 10).slice(5, 7) + "월"

    const monthhse = _.filter(hse_with_month, { 'month': today_training }).length;

  

    const [BoxHeight, setBoxHeight] = useState();

    useEffect(() => {
        if (positiondata.length > 0) {
            const boxHeight = positiondata[0];
            setBoxHeight(250 + 150 * (boxHeight.rowSpan - 2));
        }
    }, [positiondata]);

    const [visibleDialog, setVisibleDialog] = useState(true);

    const toggleDialog = () => {
        setVisibleDialog(!visibleDialog);
    };


    const [visibleDialog2, setVisibleDialog2] = useState(true);

    const toggleDialog2 = () => {
        setVisibleDialog2(!visibleDialog2);
    };

    const [visibleDialog3, setVisibleDialog3] = useState(true);

    const toggleDialog3 = () => {
        setVisibleDialog3(!visibleDialog3);
    };


    const [visibleDialog4, setVisibleDialog4] = useState(true);

    const toggleDialog4 = () => {
        setVisibleDialog4(!visibleDialog4);
    };


    return (
        <div>
            <div>

                <GridLayout style={{ height: BoxHeight }}
                    rows={[
                        {
                            height: '15.5%',
                        },
                        {
                            height: '15.5%',
                        },
                        {
                            height: '15.5%',
                        },
                        {
                            height: '15.5%',
                        },
                        {
                            height: '15.5%',
                        },
                        {
                            height: '15.5%',
                        },
                        {
                            height: '15.5%',
                        },
                        {
                            height: '15.5%',
                        }

                    ]}

                    cols={[
                        {
                            width: '49%',
                        },
                        {
                            width: '49%',
                        },


                    ]}
                    gap={{
                        rows: 5,
                        cols: 5,
                    }}>
                    <GridLayoutItem col={1} row={1} colSpan={1} rowSpan={2}>
                        <Card
                            orientation='horizontal'
                            style={{ width: '100%', height:BoxHeight/4}}
                        >

                            <CardBody>
                                <CardTitle>일일 인원</CardTitle>
                                <CardTitle> <span onClick={toggleDialog} className='hovertext bolder'> {_.sumBy(DailyManArray, '인원수')}명</span></CardTitle>
                            </CardBody>


                            <CardImage src={'/image/construction-worker.png'} style={{ width: '3rem', height: '3rem', position: 'absolute', right: '5px' }}>

                            </CardImage>
                        </Card>
                    </GridLayoutItem>
                    <GridLayoutItem col={2} row={1} colSpan={1} rowSpan={2}>
                        <Card
                            orientation='horizontal'
                            style={{ width: '100%', height:BoxHeight/4}}
                        >

                            <CardBody>
                                <CardTitle>누적 인원</CardTitle>
                                <CardTitle> <span onClick={toggleDialog2} className='hovertext bolder'> {_.sumBy(AccManArray, '인원수')}명</span></CardTitle>
                            </CardBody>


                            <CardImage src={'/image/workers.png'} style={{ width: '3rem', height: '3rem', position: 'absolute', right: '5px' }}>

                            </CardImage>
                        </Card>
                    </GridLayoutItem>
                    <GridLayoutItem col={1} row={3} colSpan={1} rowSpan={2}>
                        <Card
                            orientation='horizontal'
                            style={{ width: '100%', height:BoxHeight/4}}
                        >

                            <CardBody>
                                <CardTitle>일일 장비</CardTitle>
                                <CardTitle> <span onClick={toggleDialog3} className='hovertext bolder'>   {_.sumBy(DailyEquipArray, '수량')}대</span></CardTitle>
                            </CardBody>


                            <CardImage src={'/image/toolbox.png'} style={{ width: '3rem', height: '3rem', position: 'absolute', right: '5px' }}>

                            </CardImage>
                        </Card>
                    </GridLayoutItem>
                    <GridLayoutItem col={2} row={3} colSpan={1} rowSpan={2}>
                        <Card
                            orientation='horizontal'
                            style={{ width: '100%', height:BoxHeight/4}}
                        >

                            <CardBody>
                                <CardTitle>누적 장비</CardTitle>
                                <CardTitle> <span onClick={toggleDialog4} className='hovertext bolder'> {_.sumBy(AccEquipArray, '수량')}대</span></CardTitle>
                            </CardBody>


                            <CardImage src={'/image/toolbox.png'} style={{ width: '3rem', height: '3rem', position: 'absolute', right: '5px' }}>

                            </CardImage>
                        </Card>
                    </GridLayoutItem>
                    <GridLayoutItem col={1} row={5} colSpan={1} rowSpan={2}>
                        <Card
                            orientation='horizontal'
                            style={{ width: '100%', height:BoxHeight/4}}
                        >

                            <CardBody>
                                <CardTitle>신규채용자</CardTitle>
                                <CardTitle className='bolder'>{newtrainee}명</CardTitle>
                            </CardBody>


                            <CardImage src={'/image/foreman.png'} style={{ width: '3rem', height: '3rem', position: 'absolute', right: '5px' }}>

                            </CardImage>
                        </Card>
                    </GridLayoutItem>
                    <GridLayoutItem col={2} row={5} colSpan={1} rowSpan={2}>
                        <Card
                            orientation='horizontal'
                            style={{ width: '100%', height:BoxHeight/4}}
                        >

                            <CardBody>
                                <CardTitle>안전교육 총인원</CardTitle>
                                <CardTitle className='bolder'>{totaltrainee}명</CardTitle>
                            </CardBody>


                            <CardImage src={'/image/training.png'} style={{ width: '3rem', height: '3rem', position: 'absolute', right: '5px' }}>

                            </CardImage>
                        </Card>
                    </GridLayoutItem>
                    <GridLayoutItem col={1} row={7} colSpan={1} rowSpan={2}>
                        <Card
                            orientation='horizontal'
                            style={{ width: '100%', height:BoxHeight/4}}
                        >

                            <CardBody>
                                <CardTitle>월간교육 횟수</CardTitle>
                                <CardTitle className='bolder'>  {monthhse}회</CardTitle>
                            </CardBody>


                            <CardImage src={'/image/training.png'} style={{ width: '3rem', height: '3rem', position: 'absolute', right: '5px' }}>

                            </CardImage>
                        </Card>
                    </GridLayoutItem>
                    <GridLayoutItem col={2} row={7} colSpan={1} rowSpan={2}>

                        <Card
                            orientation='horizontal'
                            style={{ width: '100%', height:BoxHeight/4}}
                        >

                            <CardBody>
                                <CardTitle>안전교육 총횟수</CardTitle>
                                <CardTitle className='bolder'>{counttraining}회</CardTitle>
                            </CardBody>


                            <CardImage src={'/image/training.png'} style={{ width: '3rem', height: '3rem', position: 'absolute', right: '5px' }}>

                            </CardImage>
                        </Card>
                    </GridLayoutItem>


                </GridLayout>

            </div>

            <div>
                {!visibleDialog && (
                    <Dialog title={"상세 인원 이력"} onClose={toggleDialog}>
                        <Grid
                            sortable={true}
                            sort={sort}
                            onSortChange={(e) => {
                                setSort(e.sort);
                            }}
                            data={orderBy(DailyManArray, sort)}
                        >
                            <GridColumn field="인력 타입" title="인력 타입" width="200px" />
                            <GridColumn field="인원수" title="인원수" width="100px" />

                        </Grid>
                    </Dialog>
                )
                }
                {
                    !visibleDialog2 && (
                        <Dialog title={"누적 인원 이력"} onClose={toggleDialog2}>
                            <Grid
                                sortable={true}
                                sort={sort}
                                onSortChange={(e) => {
                                    setSort(e.sort);
                                }}
                                data={orderBy(AccManArray, sort)}
                            >
                                <GridColumn field="인력 타입" title="인력 타입" width="200px" />
                                <GridColumn field="인원수" title="인원수" width="100px" />

                            </Grid>
                        </Dialog>
                    )
                }

                {
                    !visibleDialog3 && (
                        <Dialog title={"일일 장비 이력"} onClose={toggleDialog3}>
                            <Grid
                                sortable={true}
                                sort={sort}
                                onSortChange={(e) => {
                                    setSort(e.sort);
                                }}
                                data={orderBy(DailyEquipArray, sort)}
                            >
                                <GridColumn field="장비 종류" title="장비 종류" width="200px" />
                                <GridColumn field="수량" title="수량" width="100px" />

                            </Grid>
                        </Dialog>
                    )
                }


                {
                    !visibleDialog4 && (
                        <Dialog title={"누적 장비 이력"} onClose={toggleDialog4}>
                            <Grid
                                sortable={true}
                                sort={sort}
                                onSortChange={(e) => {
                                    setSort(e.sort);
                                }}
                                data={orderBy(AccEquipArray, sort)}
                            >
                                <GridColumn field="장비 종류" title="장비 종류" width="200px" />
                                <GridColumn field="수량" title="수량" width="100px" />

                            </Grid>
                        </Dialog>
                    )
                }

            </div>

        </div>

    )
};

export default CompanyMans

