const MY_SERVER = `https://dash.dtsolution.kr/api`;

export const getJSON =  (path,setState) => {
    fetch(`${MY_SERVER}/getJSON?path=${path}`)
    .then((response) => response.json())
    .then((data) => setState(data));
}


export const getWeather =  (path,setState) => {
    fetch(`${MY_SERVER}/getWeather?path=${path}`)
    .then((response) => response.json())
    .then((data) => setState(data));
}

export const getProject =  (setState) => {
    fetch(`${MY_SERVER}/getProject`)
    .then((response) => response.json())
    .then((data) => setState(data));
}

