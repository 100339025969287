import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import moment from 'moment'
import _ from 'lodash';
import { Card, CardTitle, CardBody, CardImage, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";


const Weater = ({ positiondata }) => {


  const { project_code } = useParams();
  const [C_Weather, setWeather] = useState([]);
  const [DailyWeather, setDailyWeather] = useState([]);
  const [param, setparam] = useState([]);



  useEffect(() => {

    const fetchData = async () => {
      const projects = await axios.get(`https://dash.dtsolution.kr/api/getProject`)


      const lat_o = projects.data.data.data.filter(function (el) {
        return el.ugenProjectNumber === project_code
      })[0].uuu_latitude;
      const lon_o = projects.data.data.data.filter(function (el) {
        return el.ugenProjectNumber === project_code
      })[0].uuu_longitude;

      setparam(lat_o + "," + lon_o)

      const w = await axios.get(`https://dash.dtsolution.kr/api/getWeather?path=${param}`)

      const w_result = w.data && w.data.data.current
      const daily_w = w.data.data.forecast && w.data.data.forecast.forecastday


      setWeather(w_result)
      setDailyWeather(daily_w)



    };

    fetchData();
  }, [project_code, param]);



  function convert(value) {
    return moment.unix(value).format("MM/DD");
  }


  function krconvert(value) {
    return moment.unix(value).format("YYYY년 MM월 DD일  HH시");
  }

  const [BoxHeight, setBoxHeight] = useState();

  useEffect(() => {
    if (positiondata.length > 0) {
      const boxHeight = positiondata[2];
      setBoxHeight((boxHeight.rowSpan * 180));
    }
  }, [positiondata]);


  return (

    <div>

      <GridLayout style={{ height: BoxHeight -30 }}
        rows={[
          {
            height: '10%',
          },
          {
            height: '10%',
          },
          {
            height: '10%',
          },
          {
            height: '10%',
          },
          {
            height: '10%',
          },
          {
            height: '10%',
          },
          {
            height: '10%',
          },
          {
            height: '7.5%',
          },
          {
            height: '7.5%',
          },
          {
            height: '7.5%',
          },
          {
            height: '7.5%',
          },

        ]}

        cols={[
          {
            width: '25%',
          },
          {
            width: '25%',
          },
          {
            width: '25%',
          },
          {
            width: '25%',
          },
        ]}
        gap={{
          rows: 5,
          cols:0,
        }}>
        <GridLayoutItem col={1} row={1} colSpan={4} rowSpan={1}>
          {C_Weather && krconvert(C_Weather.last_updated_epoch)}
        </GridLayoutItem>

        <GridLayoutItem col={1} row={2} colSpan={2} rowSpan={3}style={{ height: BoxHeight/3 , textAlign:'center'}} className='center'>
          <img
            width={'50%'}
            alt="171x180"
            src={_.get(C_Weather, 'condition.icon')} />
        </GridLayoutItem>
        <GridLayoutItem col={3} row={2} colSpan={2} rowSpan={3} style={{ height: BoxHeight/3 , textAlign:'center', fontSize:`${(BoxHeight/300)*15}px`}} className='center'>
          <div style={{fontSize:`1.1rem`}}> {C_Weather && C_Weather.temp_c}℃ <div > 체감온도:{C_Weather && C_Weather.feelslike_c}℃ </div> </div>
        </GridLayoutItem>

        <GridLayoutItem col={1} row={5} colSpan={1} rowSpan={1} style={{ textAlign: 'center', fontSize:`0.8rem`}}>
          <img className="icon" alt="icon" src={'/image/humidity.png'} width="20%" /><div>{C_Weather && C_Weather.humidity}% </div>
        </GridLayoutItem>
        <GridLayoutItem col={2} row={5} colSpan={1} rowSpan={1} style={{ textAlign: 'center', fontSize:`0.8rem`}}>
          <img className="icon" alt="icon" src={'/image/wind.png'} width="20%" /><div>{C_Weather && C_Weather.wind_dir} {C_Weather && C_Weather.wind_kph}km/h </div>
        </GridLayoutItem>
        <GridLayoutItem col={3} row={5} colSpan={1} rowSpan={1} style={{ textAlign: 'center', fontSize:`0.8rem`}}>
          <img className="icon" alt="icon" src={'/image/sunset.png'} width="20%" /><div>{DailyWeather && _.get(DailyWeather[0], 'astro.sunset')}</div>
        </GridLayoutItem>
        <GridLayoutItem col={4} row={5} colSpan={1} rowSpan={1} style={{ textAlign: 'center', fontSize:`0.8rem` }}>
          <img className="icon" alt="icon" src={'/image/air-quality.png'} width="20%" /><div> {Number(_.get(C_Weather, 'air_quality.pm2_5')).toFixed(2)}㎍/m³</div>
        </GridLayoutItem>
        <GridLayoutItem col={1} row={6} colSpan={4} rowSpan={1} style={{ textAlign: 'center' }}>
        </GridLayoutItem>


        <GridLayoutItem col={1} row={7} colSpan={4} rowSpan={5} style={{ textAlign: 'center' , }}>
          <div className='k-card-group '>
            {DailyWeather && DailyWeather.map((pi, index) => {
              return (
                <Card orientation="vertical" key={index} style={{ width: '20%' }}>
                  <CardImage style={{ width: BoxHeight / 10, margin: '0 auto' }} src={pi.day.condition.icon} />
                  <CardTitle style={{fontSize:'0.7rem',marginBottom:'0'}}>  {convert(pi.date_epoch)} </CardTitle>
                  <CardBody style={{padding:'5px', marginBottom:'0'}}>
                    <CardTitle style={{fontSize:'0.7rem', marginBottom:'1px'}}>기온:  {pi.day.avgtemp_c}℃</CardTitle>
                    <CardTitle style={{fontSize:'0.7rem', marginBottom:'1px'}}>습도: {pi.day.avghumidity}%</CardTitle>
                    <CardTitle style={{fontSize:'0.7rem'}}>강수확률: {pi.day.daily_chance_of_rain}%</CardTitle>
                  </CardBody>
                </Card>
              )

            })}
          </div>
        </GridLayoutItem>


      </GridLayout>

    </div>


  );
};

export default Weater

