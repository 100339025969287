import React, { useEffect, useState,  } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';
import {
  GridLayout,
  GridLayoutItem, 
} from "@progress/kendo-react-layout";
import OrganizationChart from "@dabeng/react-orgchart";
import Node from "./Node";
import "./OrgChart.css";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}


const Meeting2 = () => {

  const { height, width } = useWindowDimensions();


  const { project_code } = useParams();
  const [data1, setdata1] = useState({});
  const [data2, setdata2] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`https://dash.dtsolution.kr/api/getorg?path=${project_code}`)
      const dataf = res.data.data.data.map((v) => ({ id: v.record_no, position: v.orgJobTitleSPD, biopic: v.OrgChartPhotoRT, name: v.orgName, pid: v.orgParentBPK === null ? 0 : v.orgParentBPK , part:v.orgPartSPD}))
      console.log(dataf)
      var nodes = dataf,
        tree = function (data, root) {
          
          var r = [], o = {};
          data.forEach(function (a) {
            if (o[a.id] && o[a.id].children) {
              a.children = o[a.id] && o[a.id].children;
            }
            o[a.id] = a;
            if (a.pid === root) {
              r.push(a);
            } else {
              o[a.pid] = o[a.pid] || {};
              o[a.pid].children = o[a.pid].children || [];
              o[a.pid].children.push(a);
            }
          });
          return r;
        }(nodes, 0);


      setdata1(tree[0])
      setdata2(dataf)

    };
    fetchData();
  }, [project_code]);


  return (
    <>
      <GridLayout style={{ height: height * 0.8, width: width }}
        rows={[
          { height: '100%', },
        ]}

        cols={[
          { width: '100%' },]}

        gap={{
          rows: 0,
          cols: 0,
        }}>

        <GridLayoutItem row={1} col={1} style={{ backgroundColor: '#fafafa', }}>
          <div style={{position:'absolute', top:'10px', right:'10px'}}>
            <table className="type11">
              <thead>
                <tr>
                  <th scope="cols">구분</th>
                  <th scope="cols">현장소장</th>
                  <th scope="cols">공무</th>
                  <th scope="cols">시공</th>
                  <th scope="cols">품질</th>
                  <th scope="cols">안전/보건</th>
                  <th scope="cols">비고</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>T/O</td>
                  <td>{_.filter(data2, function (elem) { return elem.part === null && elem.name !== null }).length + _.filter(data2, function (elem) { return elem.position.includes('소장') && elem.name === null }).length}</td>
                  <td>{_.filter(data2, function (elem) { return elem.part === '공무 Part' && elem.name !== null }).length+_.filter(data2, function (elem) { return elem.part === '공무 Part' && elem.name === null }).length}</td>
                  <td>{_.filter(data2, function (elem) { return elem.part === '토목 Part' && elem.name !== null }).length + _.filter(data2, function (elem) { return elem.part === '전기 Part' && elem.name !== null }).length+
                  _.filter(data2, function (elem) { return elem.part === '토목 Part' && elem.name === null }).length + _.filter(data2, function (elem) { return elem.part === '전기 Part' && elem.name === null }).length}</td>
                  <td>{_.filter(data2, function (elem) { return elem.part === '품질 Part' && elem.name !== null }).length+_.filter(data2, function (elem) { return elem.part === '품질 Part' && elem.name === null }).length}</td>
                  <td>{_.filter(data2, function (elem) { return elem.part === '안전 Part' && elem.name !== null }).length+_.filter(data2, function (elem) { return elem.part === '안전 Part' && elem.name === null }).length}</td>
                  <td>{_.filter(data2, function (elem) { return elem.name !== null }).length+_.filter(data2, function (elem) { return elem.name === null }).length}</td>
                </tr>
                <tr>
                  <td>현재원</td>
                  <td>{_.filter(data2, function (elem) { return elem.part === null && elem.name !== null }).length}</td>
                  <td>{_.filter(data2, function (elem) { return elem.part === '공무 Part' && elem.name !== null }).length}</td>
                  <td>{_.filter(data2, function (elem) { return elem.part === '토목 Part' && elem.name !== null }).length + _.filter(data2, function (elem) { return elem.part === '전기 Part' && elem.name !== null }).length}</td>
                  <td>{_.filter(data2, function (elem) { return elem.part === '품질 Part' && elem.name !== null }).length}</td>
                  <td>{_.filter(data2, function (elem) { return elem.part === '안전 Part' && elem.name !== null }).length}</td>
                  <td>{_.filter(data2, function (elem) { return elem.name !== null }).length}</td>
                  
      
                </tr>
                <tr>
                  <td>충원예정</td>
                  <td>{_.filter(data2, function (elem) { return elem.position.includes('소장') && elem.name === null }).length}</td>
                  <td>{_.filter(data2, function (elem) { return elem.part === '공무 Part' && elem.name === null }).length}</td>
                  <td>{_.filter(data2, function (elem) { return elem.part === '토목 Part' && elem.name === null }).length + _.filter(data2, function (elem) { return elem.part === '전기 Part' && elem.name === null }).length}</td>
                  <td>{_.filter(data2, function (elem) { return elem.part === '품질 Part' && elem.name === null }).length}</td>
                  <td>{_.filter(data2, function (elem) { return elem.part === '안전 Part' && elem.name === null }).length}</td>
                  <td>{_.filter(data2, function (elem) { return elem.name === null }).length}</td>
                </tr>
              </tbody>
            </table>
          </div>

          {data1 &&
            <OrganizationChart
              datasource={data1}
              chartClass="sekure-org-chart"
              NodeTemplate={Node}
              pan={true}
              zoom={true}
            />
          }
        </GridLayoutItem>


      </GridLayout>
    </>
  )


};

export default Meeting2;