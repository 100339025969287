import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import _ from 'lodash';
import * as mnode from "../nodelibrary";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { orderBy } from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';

const QTable = ({ positiondata }) => {

    const _export = useRef(null);

    const excelExport = () => {
      if (_export.current !== null) {
        _export.current.save();
      }
    };


    const [sort, setSort] = useState([{}]);

    const [vendorlist, setvendorlist] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const vendors_data = await axios.get(`https://dash.dtsolution.kr/api/getvendor`)
            const filtered_vendor_data = _.filter(vendors_data.data.data.data, function (elem) { return typeof elem._bp_lineitems !== 'undefined'; })
            const vendors_data_with = filtered_vendor_data.reduce((c, v) => c.concat(v._bp_lineitems.map(o => Object.assign(o, { vendorname: v.uveVendorNameTB50, status: v.status, prepoint: v.PreEvalutionPoint, postpoint: v.PostEvalutionPoint }))), []);
            setvendorlist(vendors_data_with)


        };

        fetchData();
    }, []);

    var vendorlist_with_amount =
        _(vendorlist)
            .groupBy('vendorname')
            .map((objs, key) => ({
                'vendorname': key,
                'amount': _.sumBy(objs, 'i_contract_total_amt'),
            }))
            .value();

    const vendor_list_result2 = _.filter(vendorlist_with_amount, function (elem) { return elem.amount > 0; })


    const vendor_list_result3 = vendor_list_result2.map(function (v) {
        if (v.postpoint !== 0 && v.prepoint !== 0) {
            return { vendorname: v.vendorname, 평가점수: v.postpoint }
        }
        else if (v.postpoint === 0 && v.prepoint !== 0) {
            return { vendorname: v.vendorname, 평가점수: v.prepoint }
        }
        else if (v.postpoint !== 0 && v.prepoint === 0) {
            return { vendorname: v.vendorname, 평가점수: v.postpoint }
        }
        else {
            return { vendorname: v.vendorname, 평가점수: 0 }
        }
    })


    //rfip count +
    const [rfip, setrfip] = useState([]);

    const getRFI = () => {

        let path = `/home/centos/download/file/requestforinspection.json`;
        mnode.getJSON(path, setrfi);

        let path2 = `/home/centos/download/file/rfiprocurement.json`;
        mnode.getJSON(path2, setrfip);



    }

    useEffect(getRFI, []);

    const rfip_nonpass = _.filter(rfip, function (elem) { return elem.status === 'Rejected'; })
    const rfip_nonpass_count = _.map(_.countBy(rfip_nonpass, 'uuu_user_company'), (val, key) => ({ vendorname: key, nonpasscount2: val }));

    const vendor2 = vendor_list_result3.map(v => ({ ...v, ...rfip_nonpass_count.find(sp => sp.vendorname === v.vendorname) }));

    const rfip_count = _.map(_.countBy(rfip, 'uuu_user_company'), (val, key) => ({ vendorname: key, allcount2: val }));


    //rfi count +
    const [rfi, setrfi] = useState([]);


    const rfi_nonpass = _.filter(rfi, function (elem) { return elem.status === 'Rejected'; })
    const rfi_nonpass_count = _.map(_.countBy(rfi_nonpass, 'uuu_user_company'), (val, key) => ({ vendorname: key, nonpasscount1: val }));
    const vendor3 = vendor2.map(v => ({ ...v, ...rfi_nonpass_count.find(sp => sp.vendorname === v.vendorname) }));

    const rfi_count = _.map(_.countBy(rfi, 'uuu_user_company'), (val, key) => ({ vendorname: key, allcount1: val }));


    //op count +
    const [opcount, setopcount] = useState([]);

    const getOP = () => {

        let path = `/home/centos/download/file/op.json`;
        mnode.getJSON(path, setopcount);
    }

    useEffect(getOP, []);

    const op_filter = _.filter(opcount, function (elem) { return elem.IssueDateDO !== null && elem.end_date !== null && elem.uuu_user_company !== null; })
    const op_new = op_filter.map((v) => { return { uuu_user_company: v.uuu_user_company, days: parseInt(((new Date(v.end_date.slice(0, 10))).getTime() - (new Date(v.IssueDateDO.slice(0, 10))).getTime()) / (24 * 3600 * 1000 * 7)) } })

    var op_day_sum_vendor =
        _(op_new)
            .groupBy('uuu_user_company')
            .map((objs, key) => ({
                'vendorname': key,
                'OP조치일수': _.sumBy(objs, 'days'),

            }))
            .value();
    const vendor4 = vendor3.map(v => ({ ...v, ...op_day_sum_vendor.find(sp => sp.vendorname === v.vendorname) }));

    const op_count_vendor = _.map(_.countBy(opcount, 'uuu_user_company'), (val, key) => ({ vendorname: key, opcount: val }));


    //ncr count +

    const [ncrcount, setncrcount] = useState([]);
    const getNCR = () => {
        let path = `/home/centos/download/file/ncr.json`;
        mnode.getJSON(path, setncrcount);
    }
    useEffect(getNCR, []);

    const ncr_filter = _.filter(ncrcount, function (elem) { return elem.IssueDateDO !== null && elem.end_date !== null && elem.uveVendorNameTB50 !== null; })
    const ncr_new = ncr_filter.map((v) => { return { uuu_user_company: v.uveVendorNameTB50, days: parseInt(((new Date(v.end_date.slice(0, 10))).getTime() - (new Date(v.IssueDateDO.slice(0, 10))).getTime()) / (24 * 3600 * 1000 * 7)) } })
    var ncr_day_sum_vendor =
        _(ncr_new)
            .groupBy('uuu_user_company')
            .map((objs, key) => ({
                'vendorname': key,
                'ncr조치일수': _.sumBy(objs, 'days'),

            }))
            .value();

    const vendor5 = vendor4.map(v => ({ ...v, ...ncr_day_sum_vendor.find(sp => sp.vendorname === v.vendorname) }));

    const ncr_count_vendor = _.map(_.countBy(ncrcount, 'uveVendorNameTB50'), (val, key) => ({ vendorname: key, ncrcount: val }));

    const vendor6 = vendor5.map(v => ({ ...v, ...ncr_count_vendor.find(sp => sp.vendorname === v.vendorname) }));


    const vendor7 = vendor6.map(v => ({ ...v, ...op_count_vendor.find(sp => sp.vendorname === v.vendorname) }));
    const vendor8 = vendor7.map(v => ({ ...v, ...rfi_count.find(sp => sp.vendorname === v.vendorname) }));
    const vendor9 = vendor8.map(v => ({ ...v, ...rfip_count.find(sp => sp.vendorname === v.vendorname) }));


    const last_vendor = vendor9.map(function (v) {
        if (typeof v.OP조치일수 !== 'undefined' && typeof v.ncr조치일수 !== 'undefined') {
            return { 협력업체명: v.vendorname, 평가점수: v.평가점수, 조치일수: v.OP조치일수 + v.ncr조치일수 }
        }

        else if (typeof v.OP조치일수 !== 'undefined' && typeof v.ncr조치일수 === 'undefined') {
            return { 협력업체명: v.vendorname, 평가점수: v.평가점수, 조치일수: v.OP조치일수 }
        }
        else if (typeof v.OP조치일수 === 'undefined' && typeof v.ncr조치일수 !== 'undefined') {
            return { 협력업체명: v.vendorname, 평가점수: v.평가점수, 조치일수: v.ncr조치일수 }
        }
        else {
            return { 협력업체명: v.vendorname, 평가점수: v.평가점수, 조치일수: 0 }
        }

    })


    const last_vendor_count = vendor9.map(function (v) {
        if (typeof v.ncrcount !== 'undefined' && typeof v.opcount !== 'undefined') {
            return { 협력업체명: v.vendorname, 평가점수: v.평가점수, '부적합/OP': v.ncrcount + ' / ' + v.opcount, '부적합OP합': v.ncrcount + v.opcount }
        }

        else if (typeof v.ncrcount !== 'undefined' && typeof v.opcount === 'undefined') {
            return { 협력업체명: v.vendorname, 평가점수: v.평가점수, '부적합/OP': v.ncrcount + ' / 0', '부적합OP합': v.ncrcount }
        }
        else if (typeof v.ncrcount === 'undefined' && typeof v.opcount !== 'undefined') {
            return { 협력업체명: v.vendorname, 평가점수: v.평가점수, '부적합/OP': '0 / ' + v.opcount, '부적합OP합': v.opcount }
        }
        else {
            return { 협력업체명: v.vendorname, 평가점수: v.평가점수, '부적합/OP': '0 / 0', '부적합OP합': 0 }
        }

    })


    const last_vendor_check = vendor9.map(function (v) {
        if (typeof v.nonpasscount1 !== 'undefined' && typeof v.nonpasscount2 !== 'undefined') {
            return { 협력업체명: v.vendorname, 평가점수: v.평가점수, '검사불합격': v.nonpasscount1 + v.nonpasscount2 + '(' + v.allcount1 + v.allcount2 + ')', '검사불합격수합': v.nonpasscount1 + v.nonpasscount2 }
        }

        else if (typeof v.nonpasscount1 !== 'undefined' && typeof v.nonpasscount2 === 'undefined') {
            return { 협력업체명: v.vendorname, 평가점수: v.평가점수, '검사불합격': v.nonpasscount1 + '(' + v.allcount1 + ')', '검사불합격수합': v.nonpasscount1 }
        }
        else if (typeof v.nonpasscount1 === 'undefined' && typeof v.nonpasscount2 !== 'undefined') {
            return { 협력업체명: v.vendorname, 평가점수: v.평가점수, '검사불합격': v.nonpasscount2 + '(' + v.allcount2 + ')', '검사불합격수합': v.nonpasscount2 }
        }
        else {
            return { 협력업체명: v.vendorname, 평가점수: v.평가점수, '검사불합격': '0(0)', '검사불합격수합': 0 }
        }

    })


    const vendorlast1 = vendor_list_result2.map(v => ({ ...v, ...last_vendor_count.find(sp => sp.협력업체명 === v.vendorname) }));
    const vendorlast2 = vendorlast1.map(v => ({ ...v, ...last_vendor.find(sp => sp.협력업체명 === v.협력업체명) }));
    const vendorlast4 = vendorlast2.map(v => ({ ...v, ...last_vendor_check.find(sp => sp.협력업체명 === v.협력업체명) }));

    const vendor_table = vendorlast4.map((v) => {
        return {
            '협력업체명': v.협력업체명, '부적합OP합': v.부적합OP합, '검사불합격수합': v.검사불합격수합, '총 계약금액': Number(v.amount.toFixed(0)), '평가점수': v.평가점수, '검사불합격수': v.검사불합격, '부적합/OP': v['부적합/OP'], '조치일수': v.조치일수
        }
    })


    const amount_total = _.sumBy(vendor_table, '총 계약금액');
    const instruction_total = _.sumBy(vendor_table, '조치일수');
    const error_total = _.sumBy(vendor_table, '부적합OP합');
    const nonpass_total = _.sumBy(vendor_table, '검사불합격수합');


    const vendor_table2 = _.sortBy(vendor_table.map((v) => {
        return {
            '협력업체명': v.협력업체명, '부적합OP합 비율': ((v.부적합OP합 / error_total) * 100).toFixed(2) + '%', '총 계약금액': v['총 계약금액'], '총 계약금액 비율': ((v['총 계약금액'] / amount_total) * 100).toFixed(2) + '%', '평가점수': v.평가점수, '검사불합격수': v.검사불합격수, '부적합/OP': v['부적합/OP'], '조치일수': v.조치일수
            , '조치일수 비율': ((v.조치일수 / instruction_total) * 100).toFixed(2) + '%', '불합격 비율': ((v.검사불합격수합 / nonpass_total) * 100).toFixed(2) + '%'

        }
    }), '총 계약금액').reverse()


    const [BoxHeight, setBoxHeight] = useState();

    useEffect(() => {
        if (positiondata.length > 0) {
            const boxHeight = positiondata[2];
            setBoxHeight((40 * (boxHeight.rowSpan)));
        }
    }, [positiondata]);


    const formatContract = (props) => {
        const contract = props.dataItem['총 계약금액']
        const sumtotal =  _.sumBy(vendor_table2, '총 계약금액')

        return (
            <td
            >
                <div style={{ width: '80px', position: 'relative' }}>
                    <div style={{ width: 100*(contract/ sumtotal)+ '%',backgroundColor: '#8ef', position: "absolute", left: '0', top: "0", bottom: '0',  opacity: '0.4' }}></div>
                    {(contract / 100000000).toFixed(2) + '억'}  

                </div>
            </td>
        );
    }

    const sumfooter = (props) => {
        const field = props.field || "";
        const total = _.sumBy(vendor_table2, field)
        return (
            <td colSpan={props.colSpan} style={props.style}>
                {(total / 10000000).toFixed(2) + '억'}
                
            </td>
        );
    };

    const sumfooterNonpass = (props) => {
        const field = props.field || "";
        const newdata = vendor_table2.map((v) => { return { count1: Number(v[field].slice(0, v[field].indexOf('('))), count2: Number(v[field].slice(v[field].indexOf('(') + 1, v[field].indexOf(')'))) } })
        const total1 = _.sumBy(newdata, 'count1');
        const total2 = _.sumBy(newdata, 'count2');
        return (
            <td colSpan={props.colSpan} style={props.style}>
                {total1}({total2})
            </td>
        );
    };

    const sumfooterOP = (props) => {
        const field = props.field || "";
        const newdata = vendor_table2.map((v) => { return { count1: Number(v[field].slice(0, v[field].indexOf('/'))), count2: Number(v[field].slice(v[field].indexOf('/') + 1, 10)) } })
        const total1 = _.sumBy(newdata, 'count1');
        const total2 = _.sumBy(newdata, 'count2');
        return (
            <td colSpan={props.colSpan} style={props.style}>
                {total1} / {total2}
            </td>
        );
    };

    const sumfooterDay = (props) => {
        const field = props.field || "";
        const total = _.sumBy(vendor_table2, field)
        return (
            <td colSpan={props.colSpan} style={props.style}>
                {total}
            </td>
        );
    };

    const formatNonPass = (props) => {
        const nonpass = props.dataItem['검사불합격수'].slice(0,props.dataItem['검사불합격수'].indexOf('('))
        const sumtotal =  _.sumBy(vendor9, 'nonpasscount2')


        return (
            <td
            >

                <div style={{ width: '80px', position: 'relative' }}>
                    <div style={{ width: 100*(nonpass/ sumtotal)+ '%',backgroundColor: '#8ef', position: "absolute", left: '0', top: "0", bottom: '0',  opacity: '0.4' }}></div>
                    {props.dataItem['검사불합격수']}

                </div>
       
            </td>
        );
    }

    const formatOP = (props) => {
        const op = Number(props.dataItem['부적합/OP'].slice(0,props.dataItem['부적합/OP'].indexOf('/')))
        const ncr = Number(props.dataItem['부적합/OP'].slice(props.dataItem['부적합/OP'].indexOf('/')+1,10))
        const sumtotal =  _.sumBy(vendor9, 'ncrcount') + _.sumBy(vendor9, 'opcount')

        return (
            <td
            >
                <div style={{ width: '80px', position: 'relative' }}>
                    <div style={{ width: 100*((op+ncr)/ sumtotal)+ '%',backgroundColor: '#8ef', position: "absolute", left: '0', top: "0", bottom: '0',  opacity: '0.4' }}></div>
                    {op}/{ncr}

                </div>
       
            </td>
        );
    }



    return (
        <ExcelExport data={vendor_table2} ref={_export}>
            <Grid data={orderBy(vendor_table2, sort)} sortable={true} sort={sort} onSortChange={(e) => { setSort(e.sort); }} style={{ height: BoxHeight  }} className='qtable'>

                <GridToolbar>
                    <button
                        title="Export Excel"
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                        onClick={excelExport}
                        style={{ position: 'absolute', right: '5px' }}
                    >
                        <span className="k-icon k-i-file-excel"></span>
                    </button>
                </GridToolbar>

                <GridColumn field="협력업체명" title="협력업체명" width="220px" />
                <GridColumn title="총 계약금액" >
                    <GridColumn field="총 계약금액" title="금액" cell={formatContract} width="100px" footerCell={sumfooter} />
                    <GridColumn field="총 계약금액 비율" title="비율" />
                </GridColumn>
                <GridColumn title="검사불합격수">
                    <GridColumn field="검사불합격수" footerCell={sumfooterNonpass} width="100px"  title="불합격수" cell={formatNonPass} />
                    <GridColumn field="불합격 비율" title="비율" />
                </GridColumn>
                <GridColumn field="평가점수" title="AVL 평가점수" />
                <GridColumn title="부적합/OP">
                    <GridColumn field="부적합/OP" title="부적합/OP" footerCell={sumfooterOP} width="100px" cell={formatOP} />
                    <GridColumn field="부적합OP합 비율" title="비율" />
                </GridColumn>
                <GridColumn field="조치일수" title="품질 이슈 조치 일수" footerCell={sumfooterDay} />


            </Grid>
        </ExcelExport>
    )

};

export default QTable

