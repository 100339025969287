import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';
import {
  GridLayout,
  GridLayoutItem, 
} from "@progress/kendo-react-layout";
import moment from 'moment';
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";


function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}


function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}





const Meeting5 = () => {

  const { height, width } = useWindowDimensions();

  const { project_code } = useParams();
  const [data1, setdata1] = useState([]);

  const [inspection, setinspection] = useState([]);
  const [ncr, setncr] = useState([]);
  const [op, setop] = useState([]);
  const [ment, setment] = useState([]);


  const today = moment().format("YYYY-MM");

  const expandChange = (event) => {
    let newData = data1.map((item) => {
      if (item.번호 === event.dataItem.번호) {
        item.expanded = !event.dataItem.expanded;
      }

      return item;
    });
    setdata1(newData);
  };


  useEffect(() => {
    const fetchData = async () => {
      const res = await axios.get(`https://dash.dtsolution.kr/api/getrisk?path=${project_code}`)
      setdata1(res.data.data.data.filter(item=>item.smmReportYNSRB === 'Yes').map((v)=>({번호:"M"+v.record_no.slice(-4), 분류:v.ProjPotRnIRecordNoDPK === null?'Risk':'Lesson Learned', CostImpact:v.uriProbableCaseAmountCA, ScheduleImpact:v.ucoScheduleImpactDaysIA, 기한:v.ugenDueDateDO, 상태:v.status, 제목:v.title, 상세설명: v.RiskDesc5L ===null ?'': v.RiskDesc5L.replaceAll('\\n', '<br/>'), 조치방안:v.RIActionPlan ===null ?'': v.RIActionPlan.replaceAll('\\n', '<br/>'), 추진현황: v.uriActionToRslvMTL4000 ===null ?'':v.uriActionToRslvMTL4000.replaceAll('\\n', '<br/>')})))

      const inspection1 = await axios.get(`https://dash.dtsolution.kr/api/getrfi?path=${project_code}`)
      const inspection2 = await axios.get(`https://dash.dtsolution.kr/api/getrfpi?path=${project_code}`)
      const inspection = [...inspection1.data.data.data.map((v)=>({...v, month:moment(v.InspDateReqsted).format("YYYY-MM")})), ...inspection2.data.data.data.map((v)=>({...v, month:moment(v.InspectionStart).format("YYYY-MM")}))];

      const ncr_data = await axios.get(`https://dash.dtsolution.kr/api/getncr?path=${project_code}`)
      const op_data = await axios.get(`https://dash.dtsolution.kr/api/getop?path=${project_code}`)
      const qn = await axios.get(`https://dash.dtsolution.kr/api/getmeeting2?path=${project_code}`)

      setop(op_data.data.data.data.map((v)=>({...v, month:moment(v.IssueDateDO).format("YYYY-MM")})));
      setncr(ncr_data.data.data.data.map((v)=>({...v, month:moment(v.IssueDateDO).format("YYYY-MM")})));
      setinspection(inspection)
      setment(qn.data.data.data.filter(item=>moment(item.smmMeetingDO).format("YYYY-MM") ===today).map(v => v.smmQualityRT))


    };
    fetchData();
  }, [project_code, today]);


  const DetailComponent = (props) => {
    const dataItem = props.dataItem;
    return (
      <table style={{width:'70%'}}>
        <tbody>
          {/* <tr>
            <td style={{width:'20%'}}>제목</td>
            <td>{dataItem.제목}</td>
          </tr> */}
          <tr>
          <td style={{width:'15%'}}>상세 설명</td>
            <td><span  dangerouslySetInnerHTML={{ __html:dataItem.상세설명 }}></span></td>
          </tr>
          <tr>
          <td style={{width:'15%'}}>조치 방안</td>
            <td><span  dangerouslySetInnerHTML={{ __html:dataItem.조치방안 }}></span></td>
          </tr>
          <tr>
          <td style={{width:'15%'}}>추진 현황</td>
            <td><span  dangerouslySetInnerHTML={{ __html:dataItem.추진현황 }}></span></td>
          </tr>

        </tbody>

      </table>

    );
  };

  const costcell = (props) => {
    const field = props.field || "";
    const cell = props.dataItem[field]
    return (
        <td>
            { Number(cell).toLocaleString()}원
        </td>
    );
};

const daycell = (props) => {
  const field = props.field || "";
  const cell = props.dataItem[field]
  return (
      <td>
          { Number(cell).toLocaleString()}일
      </td>
  );
};

const datecell = (props) => {
  const field = props.field || "";
  const cell = props.dataItem[field]
  return (
      <td>
          {cell.slice(6,10)+'년 ' + cell.slice(0,2)+'월 '+cell.slice(3,5)+'일'}
      </td>
  );
};


  return (
    <>
      <GridLayout style={{ height: height * 0.8, width: width }}
        rows={[
          { height: '50%', }, { height: '50%', },
        ]}

        cols={[
          { width: '100%' },]}

        gap={{
          rows: 10,
          cols: 0,
        }}>
          <GridLayoutItem row={2} col={1} style={{padding:'16px' ,backgroundColor: '#fafafa'}}>
            <div style={{fontSize:'1.2rem', height:'2rem',fontWeight:'bold'}}>프로젝트 이슈 & Lessons Learned</div>
            <Grid
            data={data1}
            detail={DetailComponent}
            style={{
              height: height*0.8*0.4,width:'95%', margin:'0 auto'
            }}
            expandField="expanded"
            onExpandChange={expandChange}
            className='quality'
          >
            <Column field="제목" title="제목" />
            <Column field="분류" title="분류"/>
            <Column field="CostImpact" title="Cost Impact" cell={costcell} />
            <Column field="ScheduleImpact" title="Schedule Impact" cell={daycell}/>
            <Column field="기한" title="기한" cell={datecell}/>
            <Column field="상태" title="상태" />
          </Grid>
          </GridLayoutItem>
          <GridLayoutItem row={1} col={1} style={{padding:'16px' ,backgroundColor: '#fafafa',overflow:'auto'}}>
          <div style={{fontSize:'1.2rem', height:'2rem',fontWeight:'bold'}}>프로젝트 품질현황</div>
          <table className='type12' style={{width:'95%', margin:'0 auto', height:height*0.8*0.4,verticalAlign:'middle'}}>
          <thead>
                <tr>
                  <th scope="cols" width="14%">항목</th>
                  <th scope="cols"width="8%">전체 건수</th>
                  <th scope="cols"width="8%">조치 건수</th>
                  <th scope="cols"width="8%">신규 건수</th>
                  <th scope="cols"width="62%">{today.slice(-2)}월 품질 현황</th>
                </tr>
              </thead>

            <tbody>
              <tr>
                <td>검사 불합격</td>
                <td>{_.filter(inspection, { 'status': 'Rejected' }).length}</td>
                <td>-</td>
                <td>{_.filter(inspection,  function (elem) { return elem.status === 'Rejected' && elem.month === today }).length}</td>
                <td style={{verticalAlign:'top'}} rowSpan={3}><span style={{textAlign:'left', fontSize:'0.9rem'}} dangerouslySetInnerHTML={{ __html:ment}}></span></td>
              </tr>
              <tr>
                <td>부적합</td>
                <td>{ncr.length}</td>
                <td>{_.filter(ncr, { 'status': "Closed" }).length}</td>
                <td>{_.filter(ncr,  function (elem) { return elem.month === today }).length}</td>
              </tr>
              <tr>
                <td>Open Point</td>
                <td>{op.length}</td>
                <td>{_.filter(op, { 'status': 'Closed' }).length}</td>
                <td>{_.filter(op,  function (elem) { return elem.month === today }).length}</td>
              </tr>
     
            </tbody>

          </table>
          </GridLayoutItem>

 
      </GridLayout>
    </>
  )


};

export default Meeting5;