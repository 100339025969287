import React, { useLayoutEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

am5.addLicense("CH363108325");
am5.addLicense("MP363108325");
am5.addLicense("TL363291176");

const CHART_ID = 'timeline_chart';

const KPI = () => {

    useLayoutEffect(() => {
        let root = am5.Root.new(CHART_ID);
        root.setThemes([am5themes_Animated.new(root)]);

        var continents = {
            "AF": "Africa",
            "AS": "Asia",
            "EU": "Europe",
            "NA": "North America",
            "SA": "South America",
            "OC": "Oceania",
            "AN": "Antarctica"
        }

        var colorSet = am5.ColorSet.new(root, { step: 2 });



        var colors = {
            EU: colorSet.getIndex(0),
            NA: colorSet.getIndex(2),
            SA: colorSet.getIndex(4),
            AS: colorSet.getIndex(6),
            AF: colorSet.getIndex(8),
            OC: colorSet.getIndex(10),
        }



        var yearData = {
            "202203": [
                {
                    "id": "AF",
                    "name": "태안안면",
                    "continent": "인사이트",
                    "settings": {
                        "fill": {
                            "_hex": 14444494
                        }
                    },
                    "x": 0.988,
                    "y": 1.1,
                    "value": 3290
                },
                {
                    "id": "AL",
                    "name": "대호호",
                    "continent": "EU",
                    "settings": {
                        "fill": {
                            "_hex": 6797276
                        }
                    },
                    "x": 1.1,
                    "y": 1.12,
                    "value": 4290
                }
            ],
            "202204": [
                {
                    "id": "AF",
                    "name": "태안안면",
                    "continent": "인사이트",
                    "settings": {
                        "fill": {
                            "_hex": 14444494
                        }
                    },
                    "x": 0.9,
                    "y": 0.8,
                    "value": 3290
                },
                {
                    "id": "AL",
                    "name": "대호호",
                    "continent": "EU",
                    "settings": {
                        "fill": {
                            "_hex": 6797276
                        }
                    },
                    "x": 1.1,
                    "y": 1.12,
                    "value": 4290
                }
            ],
            "202205": [
                {
                    "id": "AF",
                    "name": "태안안면",
                    "continent": "인사이트",
                    "settings": {
                        "fill": {
                            "_hex": 14444494
                        }
                    },
                    "x": 0.9,
                    "y": 0.8,
                    "value": 3290
                },
                {
                    "id": "AL",
                    "name": "대호호",
                    "continent": "EU",
                    "settings": {
                        "fill": {
                            "_hex": 6797276
                        }
                    },
                    "x": 1.2,
                    "y": 0.89,
                    "value": 4290
                },

            ]

            ,
            "202206": [
                {
                    "id": "AF",
                    "name": "태안안면",
                    "continent": "인사이트",
                    "settings": {
                        "fill": {
                            "_hex": 14444494
                        }
                    },
                    "x": 0.9,
                    "y": 0.8,
                    "value": 3290
                },
                {
                    "id": "AL",
                    "name": "대호호",
                    "continent": "EU",
                    "settings": {
                        "fill": {
                            "_hex": 6797276
                        }
                    },
                    "x": 1.2,
                    "y": 0.89,
                    "value": 4290
                },

            ]
        };



        var firstYearMonth = 202203;
        var lastYearMonth = 202205;
        var currentYearMonth = firstYearMonth;

        var newData = {};

        am5.object.each(yearData, function (yearMonth, yearDataItems) {
            am5.array.each(yearDataItems, function (item) {
                if (!newData[item.id]) {
                    newData[item.id] = {
                        data: [],
                        continent: item.continent
                    };
                }

                newData[item.id].data.push(item);
            });
        });

        // main container
        var mainContainer = root.container.children.push(am5.Container.new(root, {
            width: am5.p100,
            height: am5.p100,
            layout: root.verticalLayout
        }))
        // Create chart
        var chart = mainContainer.children.push(am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelY: "zoomXY",
            pinchZoomX: true,
            pinchZoomY: true
        }));


        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        var xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
            min: 0.5,
            max: 1.5,
            renderer: am5xy.AxisRendererX.new(root, { minGridDistance: 50 }),
            tooltip: am5.Tooltip.new(root, {})
        }));

        xAxis.children.push(am5.Label.new(root, { text: "SPI 비용성과지수", x: am5.p50, centerX: am5.p50 }));


        var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            min: 0.5,
            max: 1.5,
            renderer: am5xy.AxisRendererY.new(root, {}),
            tooltip: am5.Tooltip.new(root, {})
        }));
        yAxis.children.moveValue(am5.Label.new(root, { text: "CPI 일정성과지수", rotation: -90, y: am5.p50, centerX: am5.p50 }), 0);


        // color fills
        var series0 = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "ax",
            valueYField: "ay",
            fill: am5.color(0xe3853c)
        }));
        series0.fills.template.setAll({ fillOpacity: 0.9, visible: true });
        series0.strokes.template.set("forceHidden", true);
        series0.data.setAll([
            { ax: -0.5, ay: 1 },
            { ax: 1, ay: 1 },
            { ax: 1, ay: 2 },
            { ax: -0.5, ay: 2 }
        ]);

        var series1 = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "ax",
            valueYField: "ay",
            fill: am5.color(0x48b2b7)
        }));
        series1.fills.template.setAll({ fillOpacity: 0.9, visible: true });
        series1.strokes.template.set("forceHidden", true);
        series1.data.setAll([
            { ax: -0.5, ay: 1 },
            { ax: 1, ay: 1 },
            { ax: 1, ay: -0.5 },
            { ax: -0.5, ay: -0.5 }
        ]);

        var series2 = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "ax",
            valueYField: "ay",
            fill: am5.color(0x91d1da)
        }));
        series2.fills.template.setAll({ fillOpacity: 0.9, visible: true });
        series2.strokes.template.set("forceHidden", true);
        series2.data.setAll([
            { ax: 2, ay: 1 },
            { ax: 1, ay: 1 },
            { ax: 1, ay: -0.5 },
            { ax: 2, ay: -0.5 }
        ]);

        var series3 = chart.series.push(am5xy.LineSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "ax",
            valueYField: "ay",
            fill: am5.color(0xe8c634)
        }));
        series3.fills.template.setAll({ fillOpacity: 0.9, visible: true });
        series3.strokes.template.set("forceHidden", true);
        series3.data.setAll([
            { ax: 2, ay: 1 },
            { ax: 1, ay: 1 },
            { ax: 1, ay: 2 },
            { ax: 2, ay: 2 }
        ]);

        // Create series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        var bubbleSeries = chart.series.push(am5xy.LineSeries.new(root, {
            calculateAggregates: true,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "y",
            valueXField: "x",
            valueField: "value"
        }));

        bubbleSeries.strokes.template.set("visible", false);

        // Add bullet
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets
        var circleTemplate = am5.Template.new({ tooltipY: 0 });
        circleTemplate.states.create("transparent", { opacity: 0.15 });

        circleTemplate.events.on("pointerover", handleOver);
        circleTemplate.events.on("pointerout", handleOut);
        circleTemplate.events.on("click", handleClick);

        function handleOver(e) {
            var target = e.target;
            am5.array.each(bubbleSeries.dataItems, function (dataItem) {
                if (dataItem.bullets) {
                    var bullet = dataItem.bullets[0];
                    if (bullet) {
                        var sprite = bullet.get("sprite");
                        if (sprite && sprite !== target) {
                            sprite.states.applyAnimate("transparent");
                        }
                    }
                }
            })
        }

        function handleOut(e) {
            am5.array.each(bubbleSeries.dataItems, function (dataItem) {
                if (dataItem.bullets) {
                    var bullet = dataItem.bullets[0];
                    if (bullet) {
                        var sprite = bullet.get("sprite");
                        if (sprite) {
                            sprite.states.applyAnimate("default");
                        }
                    }
                }
            })
        }

        var selectedDataItem;
        function handleClick(e) {
            if (selectedDataItem === e.target.dataItem) {
                am5.array.each(bubbleSeries.dataItems, function (dataItem) {
                    var bullet = dataItem.bullets[0];
                    var sprite = bullet.get("sprite");
                    sprite.set("fillOpacity", 1);
                })
                lineSeries.data.clear();
            }
            else {
                selectedDataItem = e.target.dataItem;


                lineSeries.data.setAll(newData[selectedDataItem.dataContext.id].data);
                lineSeries.show();

                am5.array.each(bubbleSeries.dataItems, function (dataItem) {
                    console.log('dataItem',dataItem)
                    console.log('target',e.target.dataItem)
                    console.log('selectedDataItem',selectedDataItem)
                    var bullet = dataItem.bullets[0];
                    var sprite = bullet.get("sprite");
                    if (dataItem !== e.target.dataItem) {
                        sprite.set("fillOpacity", 0.15);
                    }
                    else {
                        sprite.set("fillOpacity", 1);
                    }
                })
            }
        }

        bubbleSeries.bullets.push(function () {
            var bulletCircle = am5.Circle.new(root, {
                radius: 5,
                templateField: "settings",
                fillOpacity: 0.9,
                tooltipText: "[fontSize:18px; bold]{name}[/]\nCPI: {valueY}\nSPI: {valueX}\n프로젝트 규모(예산): {value}억"
            }, circleTemplate);
            return am5.Bullet.new(root, {
                sprite: bulletCircle
            });
        });


        // Add heat rule
        // https://www.amcharts.com/docs/v5/concepts/settings/heat-rules/
        bubbleSeries.set("heatRules", [{
            target: circleTemplate,
            min: 3,
            max: 35,
            dataField: "value",
            key: "radius", maxValue: 4000
        }]);

        // line series
        var lineSeries = chart.series.push(am5xy.LineSeries.new(root, {
            valueXField: "x",
            valueYField: "y",
            xAxis: xAxis,
            yAxis: yAxis,
            stroke: am5.color(0x00000)

        }))

        lineSeries.strokes.template.set("strokeOpacity", 0.3);

        lineSeries.bullets.push(function () {
            var bulletCircle = am5.Circle.new(root, {
                radius: 2,
                fill: lineSeries.stroke

            });
            return am5.Bullet.new(root, {
                sprite: bulletCircle
            });
        });



        // Add cursor
        // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
        chart.set("cursor", am5xy.XYCursor.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            snapToSeries: [bubbleSeries]
        }));


        // Add scrollbars
        // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
        chart.set("scrollbarX", am5.Scrollbar.new(root, {
            orientation: "horizontal",
            exportable: false
        }));

        chart.set("scrollbarY", am5.Scrollbar.new(root, {
            orientation: "vertical",
            exportable: false
        }));

        // Label
        var yearLabel = chart.plotContainer.children.push(am5.Label.new(root, {
            text: currentYearMonth.toString().slice(0, 4) + '/' + currentYearMonth.toString().slice(4, 6),
            fontSize: "6em",
            fill: am5.color(0x000000),
            opacity: 0.15,
            x: am5.p50,
            y: am5.p50,
            fontFamily: "Courier New",
            textAlign: "right",
            centerY: am5.p50,
            centerX: am5.p50
        }));


        // Create controls
        var yearSliderContainer = mainContainer.children.push(am5.Container.new(root, {
            width: am5.percent(100),
            layout: root.horizontalLayout,
            paddingLeft: 70,
            paddingRight: 40,
            exportable: false
        }));

        var playButton = yearSliderContainer.children.push(am5.Button.new(root, {
            themeTags: ["play"],
            centerY: am5.p50,
            marginRight: 20,
            icon: am5.Graphics.new(root, {
                themeTags: ["icon"]
            })
        }));

        playButton.events.on("click", function () {
            if (playButton.get("active")) {
                slider.set("start", slider.get("start") + 0.0001);
            } else {
                slider.animate({
                    key: "start",
                    to: 1,
                    duration: 15000 * (1 - slider.get("start"))
                });
            }
        });

        var slider = yearSliderContainer.children.push(am5.Slider.new(root, {
            orientation: "horizontal",
            start: 0,
            centerY: am5.p50
        }));

        slider.on("start", function (start) {
            if (start === 1) {
                playButton.set("active", false);
            }
        });

        var firstYear = Math.floor(firstYearMonth / 100);
        var firstMonth = firstYearMonth % 100;
        var lastYear = Math.floor(lastYearMonth / 100);
        var lastMonth = lastYearMonth % 100;

        slider.events.on("rangechanged", function () {
            updateSeriesData(
                firstYearMonth + Math.round(slider.get("start", 0) * ((lastYear - firstYear) * 12 + (lastMonth - firstMonth) + 1))
            );
        });


        function updateSeriesData(year) {
            if (currentYearMonth !== year) {
                currentYearMonth = year;
                var data = yearData[year];

                var i = 0;
                am5.array.each(data, function (item) {
                    bubbleSeries.data.setIndex(i, item);
                    i++;
                });

                yearLabel.set("text", year.toString().slice(0, 4) + '/' + year.toString().slice(4, 6));
            }
        }



        bubbleSeries.data.setAll(yearData[currentYearMonth]);

        return () => {
            root && root.dispose();
        };

    }, []);



    return (
        <>
            <div style={{textAlign:'center', marginTop:10}}>
                <span className='k-icon k-i-circle' style={{ fontSize: '1.5rem', color: '#E13D43' }}></span><span>도시개발 </span>
                <span className='k-icon k-i-circle' style={{ fontSize: '1.5rem', color: '#F49B42' }}></span><span>PLS</span>
                <span className='k-icon k-i-circle' style={{ fontSize: '1.5rem', color: '#00A170' }}></span><span>에너지솔루션 </span>
            </div>
            <div
                id={CHART_ID}
                style={{ width: "80%", height: 600, zIndex: '999', margin: '0 auto' }}
            ></div>
        </>
    );

};

export default KPI
