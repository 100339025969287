import React, { useState, useEffect } from 'react';
import krMessages from "./kr.json";
import axios from "axios";
import { useParams } from 'react-router-dom';
import { DatePicker } from "@progress/kendo-react-dateinputs";
import CustomCalendar from './customcalendar';
import moment from 'moment'
import { Swiper, SwiperSlide } from "swiper/react";
import { Checkbox } from "@progress/kendo-react-inputs";
import { FreeMode, Navigation, Thumbs, Mousewheel, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/zoom";
import "swiper/css/pagination";
import {
    IntlProvider,
    load,
    LocalizationProvider,
    loadMessages,
} from "@progress/kendo-react-intl";
import {
    Card,
    CardTitle,
    CardBody,
    GridLayout, GridLayoutItem
} from "@progress/kendo-react-layout";
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import currencyData from "cldr-core/supplemental/currencyData.json";
import weekData from "cldr-core/supplemental/weekData.json";
import numbers from "cldr-numbers-full/main/ko/numbers.json";
import currencies from "cldr-numbers-full/main/ko/currencies.json";
import caGregorian from "cldr-dates-full/main/ko/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/ko/dateFields.json";
import timeZoneNames from "cldr-dates-full/main/ko/timeZoneNames.json";
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css'

load(
    likelySubtags,
    currencyData,
    weekData,
    numbers,
    currencies,
    caGregorian,
    dateFields,
    timeZoneNames
);
loadMessages(krMessages, "ko-KR");

const config = {
    responseType: 'blob',

};



const ProjectPhotos = ({ positiondata }) => {
    const { project_code } = useParams();
    const defaultvalue = new Date();
    const [BoxHeight, setBoxHeight] = useState(250);

    const [Maxdate, setMaxdate] = useState(defaultvalue);
    const [value, setValue] = useState(defaultvalue);
    const [urlset, seturlset] = useState([]);
    const [imagesNavSlider, setImagesNavSlider] = useState(null);

    useEffect(() => {
        const boxHeight = positiondata[1];
        setBoxHeight((boxHeight && boxHeight.rowSpan * 180));
    }, [positiondata]);


    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const foldervalue = moment(value).format('YY년 M월')

    useEffect(() => {
        const fetchData = async () => {
            const meta_file = await axios.get(`https://dash.dtsolution.kr/api/getimg/?project=` + project_code + '&parent=/현장 사진/전경 사진/' + foldervalue)

            const fileIDlist = meta_file.data.data.data.map((v) => ({ id: v.file_id }))

            const requestArr = fileIDlist.map(async data => {
                const result = await axios.get(`https://dash.dtsolution.kr/api/getimgfile/?file_id=${data.id}`, {
                    responseType: 'arraybuffer'
                })
                return result;
            });

            const res = await Promise.all(requestArr)


            const urlset = res.map(v => {
                return window.URL.createObjectURL(new Blob([v.data]))
            })

            seturlset(urlset)
        }

        fetchData();

    }, [project_code, foldervalue]);

    // console.log(urlset)


    const [checkvalue, setcheckvalue] = useState(true);

    const handleCheckChange = (event) => {
        setcheckvalue(event.value);
    };

    console.log(checkvalue)



    return (
        <>
            <div>
                <span style={{ width: '125px', position: 'absolute', right: '12px', top: '6px' }} >
                    <LocalizationProvider language="ko-KR">
                        <IntlProvider locale="ko-KR">
                            <DatePicker format={"yyyy년 MM월"} calendar={CustomCalendar} max={Maxdate} defaultValue={defaultvalue} onChange={handleChange} value={value} />
                        </IntlProvider>
                    </LocalizationProvider>
                </span>
            </div>
            <div style={{ position: 'absolute', right: '8px', bottom: '0px' }}>
                <Checkbox
                    value={checkvalue}
                    onChange={handleCheckChange}
                    label={"Auto Play"}
                    size='small'
                />
            </div>

            <section className="slider" style={{ height: BoxHeight - 60 }}>
                <div className="slider__flex">
                    <div className="slider__col">

                        <div className="slider__thumbs" style={{ height: BoxHeight, width: 180, marginRight: 8, marginLeft: 8, marginTop: 8 }}>
                            <Swiper
                                onSwiper={setImagesNavSlider}
                                direction="vertical"
                                spaceBetween={4}
                                slidesPerView={4}

                                className="swiper-container1"
                                // breakpoints={{
                                //     0: {
                                //         direction: "horizontal"
                                //     },
                                //     768: {
                                //         direction: "vertical"
                                //     }
                                // }}
                                modules={[FreeMode, Navigation, Thumbs]}
                            // style={{width:'20%', height:'100%'}}
                            >

                                {urlset && urlset.map((v, index) => (
                                    <SwiperSlide key={index}>

                                        <img src={v} alt={index} style={{ height: 80, width: 160 }} />
                                    </SwiperSlide>


                                ))}
                            </Swiper>
                        </div>

                    </div>

                    <div className="slider__images" style={{ width: 'calc(100% - 190px)' }}>
                        <Swiper
                            thumbs={{ swiper: imagesNavSlider }}
                            direction="horizontal"
                            slidesPerView={1}
                            navigation={true}
                            spaceBetween={10}
                            mousewheel={true}
                            autoplay={{
                                delay: checkvalue === false? 10000000:  2500,
                            }}
                            // breakpoints={{
                            //     0: {
                            //         direction: "horizontal"
                            //     },
                            //     768: {
                            //         direction: "horizontal"
                            //     }
                            // }}
                            className="swiper-container2"
                            modules={[Navigation, Thumbs, Mousewheel, FreeMode, Autoplay]}
                        >
                            {urlset && urlset.map((v, index) => (

                                    <SwiperSlide key={index}>
                                <Zoom>

                                        <img src={v} style={{ objectFit: 'cover', opacity: 1, height: BoxHeight - 80, marginTop: 50, width: '95%' }} alt={index} />
                                </Zoom>

                                    </SwiperSlide>


                            ))}

                        </Swiper>
                    </div>
                </div>
            </section>
        </>
    )

}

export default ProjectPhotos