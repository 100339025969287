import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

const propTypes = {
  nodeData: PropTypes.object.isRequired,
};


const Node = ({ nodeData }) => {
  const handleNodeData = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let childNodes = document.getElementById(nodeData.id).parentElement
      .childNodes;
    if (childNodes[1].className.includes("hidden")) {
      childNodes[0].className = "oc-node";
      childNodes[1].className = "";
    } else {
      childNodes[0].className = "oc-node isChildrenCollapsed";
      childNodes[1].className = "hidden";
    }
  };



  useEffect(() => {
    if (typeof nodeData.position !== 'undefined' && nodeData.position.includes("팀장")) {
      let childNodes = document.getElementById(nodeData.id).parentElement
        .childNodes;

      childNodes[0].className = "oc-node isChildrenCollapsed";
      if (childNodes.length >1){
      childNodes[1].className = "hidden";}
    }
  }, [nodeData.id, nodeData.position]);

  return (
    <div className="org-node-container" >
      <div className="org-position">
        <div>{nodeData.position}</div>
      </div>
      <div className="org-person">
        <div dangerouslySetInnerHTML={{ __html:nodeData.biopic }}>
        </div>
        <div className="org-name">{nodeData.name}</div>
        {typeof nodeData.children !== 'undefined' && nodeData.children.length > 0 && (
          <div
            className="org-node-children"
            onClick={(e) => {
              handleNodeData(e);
            }}
          >
            {nodeData.children.length} 팀원
          </div>
        )}
      </div>

    </div>
  );
};

Node.propTypes = propTypes;

export default Node;
