import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import * as mnode from "../nodelibrary";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


am4core.useTheme(am4themes_animated);
am4core.addLicense("CH363108325");

const QPie2 = ({positiondata}) => {

    const [ncrcount, setncrcount] = useState([]);
    const [opcount, setopcount] = useState([]);

    const getPie = () => {

        let path = `/home/centos/download/file/ncr.json`;
        mnode.getJSON(path, setncrcount);
        let path2 = `/home/centos/download/file/op.json`;
        mnode.getJSON(path2, setopcount);
    }

    useEffect(getPie, []);




    const null_ncr = ncrcount.map(function (v) {
        if (v.DefectClassA !== null && v.DefectClassB !== null) {
            return { DefectClassA: v.DefectClassA, DefectClassB: v.DefectClassB }
        }
        else if (v.DefectClassA !== null && v.DefectClassB === null) {
            return { DefectClassA: v.DefectClassA, DefectClassB: '분석전' }
        }
        else if (v.DefectClassA === null && v.DefectClassB !== null) {
            return { DefectClassA: '분석전', DefectClassB: v.DefectClassB }
        }

        else {
            return { DefectClassA: '분석전', DefectClassB: '분석전' }
        }

    })



    const null_op = opcount.map(function (v) {
        if (v.DefectClassA !== null && v.DefectClassB !== null) {
            return { DefectClassA: v.DefectClassA, DefectClassB: v.DefectClassB }
        }
        else if (v.DefectClassA !== null && v.DefectClassB === null) {
            return { DefectClassA: v.DefectClassA, DefectClassB: '분석전' }
        }
        else if (v.DefectClassA === null && v.DefectClassB !== null) {
            return { DefectClassA: '분석전', DefectClassB: v.DefectClassB }
        }

        else {
            return { DefectClassA: '분석전', DefectClassB: '분석전' }
        }

    })


            var piedata =
            _([...null_ncr, ...null_op])
                .groupBy('DefectClassA')
                .map((objs, key) => ({
                    'category': key,
                    'value': objs.filter(v => v.DefectClassA === key).length,
                    'children': _.unionBy(_.map(objs, f => {
                        return {
                            'id': f.DefectClassB,
                            'value': _.map(_.countBy(objs, 'DefectClassB'), (val) => (val))[0],
                        }
                    }), 'name'),
    
                }))
                .value();

                

    useEffect(() => {
        /**
     * ---------------------------------------
     * This demo was created using amCharts 4.
     * 
     * For more information visit:
     * https://www.amcharts.com/
     * 
     * Documentation is available at:
     * https://www.amcharts.com/docs/v4/
     * ---------------------------------------
     */

        var container = am4core.create('qchart2', am4core.Container);
        container.width = am4core.percent(100);
        container.height = am4core.percent(100);
        container.layout = "horizontal";


        var chart = container.createChild(am4charts.PieChart);


        // Add data
        chart.data = piedata
        // Add and configure Series
        var pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "category";
        pieSeries.slices.template.states.getKey("active").properties.shiftRadius = 0;
        pieSeries.labels.template.text = "{category} ({value}건)\n {value.percent.formatNumber('#.#')}%";
        pieSeries.labels.template.fontSize = 11;

        pieSeries.slices.template.events.on("hit", function (event) {
            selectSlice(event.target.dataItem);
        })

        var chart2 = container.createChild(am4charts.PieChart);
        chart2.width = am4core.percent(30);
        chart2.radius = am4core.percent(80);

        // Add and configure Series
        var pieSeries2 = chart2.series.push(new am4charts.PieSeries());
        pieSeries2.dataFields.value = "value";
        pieSeries2.dataFields.category = "id";
        pieSeries2.slices.template.states.getKey("active").properties.shiftRadius = 0;
        //pieSeries2.labels.template.radius = am4core.percent(50);
        //pieSeries2.labels.template.inside = true;
        //pieSeries2.labels.template.fill = am4core.color("#ffffff");
        // pieSeries2.labels.template.disabled = true;
        pieSeries2.ticks.template.disabled = true;
        pieSeries2.alignLabels = false;
        pieSeries2.events.on("positionchanged", updateLines);
        pieSeries2.labels.template.fontSize = 11;
        pieSeries2.labels.template.text = "{category} ({value}건) {value.percent.formatNumber('#.#')}%";

        var interfaceColors = new am4core.InterfaceColorSet();

        var line1 = container.createChild(am4core.Line);
        line1.strokeDasharray = "2,2";
        line1.strokeOpacity = 0.5;
        line1.stroke = interfaceColors.getFor("alternativeBackground");
        line1.isMeasured = false;

        var line2 = container.createChild(am4core.Line);
        line2.strokeDasharray = "2,2";
        line2.strokeOpacity = 0.5;
        line2.stroke = interfaceColors.getFor("alternativeBackground");
        line2.isMeasured = false;

        var selectedSlice;

        function selectSlice(dataItem) {

            selectedSlice = dataItem && dataItem.slice;

            var fill = selectedSlice && selectedSlice.fill;

            var count = dataItem &&dataItem.dataContext && dataItem.dataContext.children.length;
            pieSeries2.colors.list = [];
            for (var i = 0; i < count; i++) {
                pieSeries2.colors.list.push(fill.brighten(i * 2 / count));
            }

            chart2.data = dataItem&&dataItem.dataContext && dataItem.dataContext.children;
            pieSeries2.appear();

            var middleAngle = selectedSlice.middleAngle;
            var firstAngle = pieSeries.slices.getIndex(0).startAngle;
            var animation = pieSeries.animate([{ property: "startAngle", to: firstAngle - middleAngle }, { property: "endAngle", to: firstAngle - middleAngle + 360 }], 600, am4core.ease.sinOut);
            animation.events.on("animationprogress", updateLines);

            selectedSlice.events.on("transformed", updateLines);

            //  var animation = chart2.animate({property:"dx", from:-container.pixelWidth / 2, to:0}, 2000, am4core.ease.elasticOut)
            //  animation.events.on("animationprogress", updateLines)
        }


        function updateLines() {
            if (selectedSlice) {
                var p11 = { x: selectedSlice.radius * am4core.math.cos(selectedSlice.startAngle), y: selectedSlice.radius * am4core.math.sin(selectedSlice.startAngle) };
                var p12 = { x: selectedSlice.radius * am4core.math.cos(selectedSlice.startAngle + selectedSlice.arc), y: selectedSlice.radius * am4core.math.sin(selectedSlice.startAngle + selectedSlice.arc) };

                p11 = am4core.utils.spritePointToSvg(p11, selectedSlice);
                p12 = am4core.utils.spritePointToSvg(p12, selectedSlice);

                var p21 = { x: 0, y: -pieSeries2.pixelRadius };
                var p22 = { x: 0, y: pieSeries2.pixelRadius };

                p21 = am4core.utils.spritePointToSvg(p21, pieSeries2);
                p22 = am4core.utils.spritePointToSvg(p22, pieSeries2);

                line1.x1 = p11.x;
                line1.x2 = p21.x;
                line1.y1 = p11.y;
                line1.y2 = p21.y;

                line2.x1 = p12.x;
                line2.x2 = p22.x;
                line2.y1 = p12.y;
                line2.y2 = p22.y;
            }
        }

        chart.events.on("datavalidated", function () {
            setTimeout(function () {
                selectSlice(pieSeries.dataItems.getIndex(0));
            }, 1000);
        });


        return () => {
            container && container.dispose();

        };


    }, [piedata]);

    const [BoxHeight, setBoxHeight] = useState();

    useEffect(() => {
        if (positiondata.length > 0) {
            const boxHeight = positiondata[0];
            setBoxHeight(40 * (boxHeight.rowSpan));
        }
    }, [positiondata]);



    return (

        <>
            <div
                id='qchart2'
                style={{ width: "100%", height: BoxHeight*0.9, zIndex: '999', margin: '0 auto' , marginLeft:-70}}
            ></div>

        </>
    )

};

export default QPie2

