import React, { useEffect, useState } from 'react';
import * as mnode from "../nodelibrary";
import _ from 'lodash';
import { Card, CardTitle, CardBody, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  Chart,ChartArea, ChartLegend, ChartSeries, ChartSeriesItem, ChartCategoryAxis, ChartCategoryAxisItem, ChartValueAxis, ChartValueAxisItem, ChartTooltip
} from "@progress/kendo-react-charts";


const CompanyIncident = ({ positiondata, setIncidentYear2 }) => {

  const [hincident, sethincident] = useState([]);

  const getIncident = () => {

      let path = `/home/centos/download/file/incident.json`;
      mnode.getJSON(path, sethincident);
  }

  useEffect(getIncident, []);


  const backdata = hincident
      .reduce((c, v) => c.concat(v._bp_lineitems.map(o => Object.assign(o, { "Type": o.IRIncidentTypeText, "Death": o.IRDeathText, "Year": v.CreationDateDO.slice(8, 10) + "년", "Month": v.CreationDateDO.slice(0, 2) + "월", "YearMonth": v.CreationDateDO.slice(8, 10) + "년 " + v.CreationDateDO.slice(0, 2) + "월" }))), []);


  const piedata2 = _.map(_.countBy(backdata, 'Type'), (val, key) => ({ name: key, value: val })).map((v, index) => ({ ...v, color: COLORS[index] }));

  var year = new Date().toISOString().slice(0, 10).slice(2, 4) + "년"
  var month = new Date().toISOString().slice(0, 10).slice(2, 4) + "년 " + new Date().toISOString().slice(0, 10).slice(5, 7) + "월"

  const year_filter = _.filter(backdata, { 'Year': year });

  const monthcount = _.filter(backdata, { 'YearMonth': month }).length

  const count_year = _.map(_.countBy(year_filter, 'Month'), (val, key) => ({ name: key, value: val }));


  function dateRange(startDate, endDate) {
      var start      = startDate.split('/');
      var end        = endDate.split('/');
      var startYear  = parseInt(start[2].slice(2,4));
      var endYear    = parseInt(end[2].slice(2,4));
      var dates      = [];
    
      for(var i = startYear; i <= endYear; i++) {
        var endMonth = i !== endYear ? 11 : parseInt(end[0]) - 1;
        var startMon = i === startYear ? parseInt(start[0])-1 : 0;
        for(var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j+1) {
          var month = j+1;
          var displayYear = i+"년"
          var displayMonth = month < 10 ? '0'+month+'월' : month+'월';
          dates.push([displayYear, displayMonth].join(' '));
        }
      }
      return dates;
    }
  
    const chartdate = dateRange(String('01/01/'+ (new Date().toISOString().slice(0, 10).slice(0, 4))), String('12/01/'+ (new Date().toISOString().slice(0, 10).slice(0, 4)))).map((data) => {return {name: data }});
    const chartdate2 = dateRange(String('01/01/'+ (new Date().toISOString().slice(0, 10).slice(0, 4))), String(((new Date().toISOString().slice(0, 10).slice(5, 7)))+'/01/'+ (new Date().toISOString().slice(0, 10).slice(0, 4)))).map((data) => {return {name: data, c_value:0 }});
  



  const count_year_sort = _.sortBy(count_year, 'Month').reverse();


  
  let cumsum = 0;
  const composedata_o = count_year_sort.map(({ name, value }) => ({ name, value, c_value: cumsum += value }));

  const composedata2 = chartdate.map(v => ({ ...v, ...chartdate2.find(sp => sp.name === v.name) }));
  const composedata = composedata2.map(v => ({ ...v, ...composedata_o.find(sp => sp.name === v.name) }));
  const composedata_month = composedata.map((v)=> {return{
      name:v.name,
      value:v.value,
      c_value:v.c_value,
      month:v.name.slice(-3)
    }})
  

  const death_o = backdata.filter(item => item.Death !== undefined).filter(item => item.Death === '사망')
  const death_o_count = _.map(_.countBy(death_o, 'Death'), (val) => ({ name: "사망", value: val }));

  const death_x = backdata.filter(item => item.Death !== undefined).filter(item => item.Death !== '사망')
  const death_x_count = _.map(_.countBy(death_x, 'Death'), (val) => ({ name: "부상", value: val }));


  const columnData = composedata_month.map(v => v.value);
  const lineData = composedata_month.map(v => v.c_value);
  const categoryData = composedata_month.map(v => v.month);


  const COLORS = ['#f37c21', '#fa9548', '#fa9f5a', '#ed8d45', '#f0a369', '#f89b6c', '#f7a881','#f37c21', '#fa9548', '#fa9f5a', '#ed8d45'];

  const piedata = [...death_o_count, ...death_x_count].map((v, index) => ({ ...v, color: COLORS[index] }));

  var today = new Date().toISOString().slice(0, 10).slice(5, 7) + "월";
  var today_year = new Date().toISOString().slice(0, 10).slice(0, 4) + "년"

  const [BoxHeight, setBoxHeight] = useState();

  useEffect(() => {
    if (positiondata.length > 0) {
      const boxHeight = positiondata[2];
      setBoxHeight(250 + 150 * (boxHeight.rowSpan - 2));
    }
  }, [positiondata]);


  const valueAxisLabels = {
    padding: 0,
    font: "10px Arial, sans-serif",
    step: 5,
  };

  const labelContent = (props) => {
    
    return `${props.dataItem.name} ${props.dataItem.value}건 `;
  };

  useEffect(() => {
    setIncidentYear2(today_year);
  });



  return (
    <GridLayout style={{ height: BoxHeight }}
      rows={[
        {
          height: '15.5%',
        },
        {
          height: '15.5%',
        },
        {
          height: '15.5%',
        },
        {
          height: '15.5%',
        },
        {
          height: '15.5%',
        },
        {
          height: '15.5%',
        },
        {
          height: '15.5%',
        },
        {
          height: '15.5%',
        }

      ]}

      cols={[
        {
          width: '24.5%',
        },
        {
          width: '24.5%',
        },
        {
          width: '24.5%',
        },
        {
          width: '24.5%',
        },


      ]}
      gap={{
        rows: 5,
        cols: 5,
      }}>

      <GridLayoutItem col={1} row={1} colSpan={1} rowSpan={2}>

        <Card
          orientation='horizontal'
          style={{ width: '100%', height:BoxHeight/4}}
        >
          <CardBody className='cardcenter'>
            <CardTitle>{today} 현장</CardTitle>
            <CardTitle  style={{fontWeight:'bolder'}}>{monthcount} </CardTitle>
          </CardBody>
        </Card>
      </GridLayoutItem>
      <GridLayoutItem col={2} row={1} colSpan={3} rowSpan={2}>
        <Chart style={{marginTop:((BoxHeight / 100) ) * -1}}>
          <ChartArea  height={BoxHeight/3}/>
          <ChartTooltip />
          <ChartLegend visible={false} />
          <ChartValueAxis>
            <ChartValueAxisItem labels={valueAxisLabels} majorGridLines={0} />
          </ChartValueAxis>
          <ChartCategoryAxis>
            <ChartCategoryAxisItem categories={categoryData}>
            </ChartCategoryAxisItem>
          </ChartCategoryAxis>
          <ChartSeries>
            <ChartSeriesItem
              type="colmun"
              data={columnData}
              field="value"
              autoFit={true}
            />

            <ChartSeriesItem
              type="line"
              data={lineData}
              field="value"
              autoFit={true}
            />
          </ChartSeries>
        </Chart>
      </GridLayoutItem>
      <GridLayoutItem col={1} row={3} colSpan={2} rowSpan={1}style={{ textAlign: 'center', zIndex: '100' }}></GridLayoutItem>

      
      <GridLayoutItem col={1} row={4} colSpan={2} rowSpan={1}style={{ textAlign: 'center', zIndex: '100' }}>현장누적</GridLayoutItem>
      <GridLayoutItem col={3} row={4} colSpan={2} rowSpan={1}style={{ textAlign: 'center', zIndex: '100' }}>재해유형</GridLayoutItem>
      <GridLayoutItem col={1} row={5} colSpan={2} rowSpan={4}>

        {piedata.length !== 0 ?
          <Chart style={{marginTop:((BoxHeight / 100) ) * -1}}>
          <ChartArea  height={(BoxHeight/2)*1.5}/>
              <ChartLegend visible={false} />
              <ChartSeries>
                <ChartSeriesItem
                  color={piedata.color}
                  type="pie"
                  data={piedata}
                  field="value"
                  categoryField="name"
                  labels={{
                    visible: true,
                    content: labelContent,
                  }}
                  autoFit={true}
                />
              </ChartSeries>
            </Chart>
          : <div style={{ margin: '0 auto', width:(BoxHeight/2)*1.5, position: 'relative' }}><img src='/image/na.png' alt='n/a' style={{ margin: '10px auto', objectFit: 'contain', width: '70%', position: 'relative', left: '15.5%' }} /></div>}

      </GridLayoutItem>
      <GridLayoutItem col={3} row={5} colSpan={2} rowSpan={4}>

        {piedata2.length !== 0 ?
          <div>
          <Chart style={{marginTop:((BoxHeight / 100) ) * -1}}>
          <ChartArea  height={(BoxHeight/2)*1.5}/>
              <ChartLegend visible={false} />
              {/* <ChartTitle text="위험유형" margin={-10} /> */}
              <ChartSeries>
                <ChartSeriesItem
                  color={piedata2.color}
                  type="pie"
                  data={piedata2}
                  field="value"
                  categoryField="name"
                  labels={{
                    visible: true,
                    content: labelContent,
                  }}
                  autoFit={true}
                />
              </ChartSeries>
            </Chart>
          </div>
          : <div style={{ margin: '0 auto', width:(BoxHeight/2)*1.5, position: 'relative' }}><img src='/image/na.png' alt='n/a' style={{ margin: '10px auto', objectFit: 'contain', width: '70%', position: 'relative', left: '15.5%' }} /></div>}
      </GridLayoutItem>


    </GridLayout>

  );
};

export default CompanyIncident

