import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { Card, CardTitle, CardBody, CardImage,GridLayoutItem, GridLayout} from "@progress/kendo-react-layout";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { orderBy } from "@progress/kendo-data-query";

const initialSort = [

];

const ProjectMan = ({ positiondata }) => {


    const [DailyManArray, setDailyManArray] = useState([]);
    const [AccManArray, setAccManArray] = useState([]);
    const [DailyEquipArray, setDailyEquipArray] = useState([]);
    const [AccEquipArray, setAccEquipArray] = useState([]);

    const [newtrainee, setnewtrainee] = useState(0);
    const [totaltrainee, settotaltrainee] = useState(0);
    const [counttraining, setcounttraining] = useState(0);

    const [monthhse, setmonthhse] = useState(0);

    const [sort, setSort] = useState(initialSort);


    const { project_code } = useParams();

    useEffect(() => {
        const fetchData = async () => {

            const res = await axios.get(`https://dash.dtsolution.kr/api/gethse1?path=${project_code}`)
            const resGH = await axios.get(`https://dash.dtsolution.kr/api/gethseGH?path=${project_code}`)

            const data = res.data.data.data

            const last_data = data.sort(function compare(a, b) {
                var dateA = new Date(a.IssueDateDO);
                var dateB = new Date(b.IssueDateDO);
                return dateA - dateB;
            });

            const last_element = data[last_data.length - 1]

            const dataGH = resGH.data.data.data

   
            const dailymanarray = [
                { "인력 타입": '협력업체 반장', "인원수": last_element.SubconMngrNumDA },
                { "인력 타입": '협력업체 근로자', "인원수": last_element.SubconLaborNumDA },
                { "인력 타입": '관리자', "인원수": last_element.MngrNumDA },
                { "인력 타입": '직영 근로자', "인원수": last_element.DirectMgmtLaborDA },
            ]
            const accumanarray = [
                { "인력 타입": '협력업체 반장', "인원수": data.reduce(function (cnt, o) { return cnt + o.SubconMngrNumDA; }, 0) +dataGH.reduce(function (cnt, o) { return cnt + o.SubconMngrNumDA; }, 0) },
                { "인력 타입": '협력업체 근로자', "인원수": data.reduce(function (cnt, o) { return cnt + o.SubconLaborNumDA; }, 0)+dataGH.reduce(function (cnt, o) { return cnt + o.SubconLaborNumDA; }, 0) },
                { "인력 타입": '관리자', "인원수": data.reduce(function (cnt, o) { return cnt + o.MngrNumDA; }, 0)+dataGH.reduce(function (cnt, o) { return cnt + o.MngrNumDA; }, 0)  },
                { "인력 타입": '직영 근로자', "인원수": data.reduce(function (cnt, o) { return cnt + o.DirectMgmtLaborDA; }, 0) +dataGH.reduce(function (cnt, o) { return cnt + o.DirectMgmtLaborDA; }, 0)},
            ]


            const dailyequiparray = [
                { "장비 종류": '양중장비', "수량": last_element.EquipNumCraneDA },
                { "장비 종류": '굴착기계', "수량": last_element.EquipNumExcDA },
                { "장비 종류": '운반차량', "수량": last_element.EquipNumOthersDA },
                { "장비 종류": '기타기계', "수량": last_element.EquipNumTransDA },
            ]
            const accuequiparray = [
                { "장비 종류": '양중장비', "수량": data.reduce(function (cnt, o) { return cnt + o.EquipNumCraneDA; }, 0)+dataGH.reduce(function (cnt, o) { return cnt + o.EquipNumCraneDA; }, 0) },
                { "장비 종류": '굴착기계', "수량": data.reduce(function (cnt, o) { return cnt + o.EquipNumExcDA; }, 0) +dataGH.reduce(function (cnt, o) { return cnt + o.EquipNumExcDA; }, 0)},
                { "장비 종류": '운반차량', "수량": data.reduce(function (cnt, o) { return cnt + o.EquipNumOthersDA; }, 0) + dataGH.reduce(function (cnt, o) { return cnt + o.EquipNumOthersDA; }, 0) },
                { "장비 종류": '기타기계', "수량": data.reduce(function (cnt, o) { return cnt + o.EquipNumTransDA; }, 0) + dataGH.reduce(function (cnt, o) { return cnt + o.EquipNumTransDA; }, 0)},
            ]

            setDailyManArray(dailymanarray)
            setAccManArray(accumanarray)
            setDailyEquipArray(dailyequiparray)
            setAccEquipArray(accuequiparray)

            setnewtrainee(last_element.NewLaborDA)

            const hse = await axios.get(`https://dash.dtsolution.kr/api/gethse2?path=${project_code}`)

            settotaltrainee(_.sumBy(hse.data.data.data, 'TotalTraineens'));
            setcounttraining(hse.data.data.data.length);

            const hse_with_month = hse.data.data.data.map(value => ({ month: value.TrainingStart.slice(8, 10) + "년 " + value.TrainingStart.slice(0, 2) + "월", ...value }));

            var today = new Date().toISOString().slice(0, 10).slice(2, 4) + "년 " + new Date().toISOString().slice(0, 10).slice(5, 7) + "월"

            const current_hse = _.filter(hse_with_month, { 'month': today }).length

            setmonthhse(current_hse);


        };

        fetchData();
    }, [project_code]);



    const [BoxHeight, setBoxHeight] = useState();

    useEffect(() => {
        if (positiondata.length > 0) {
            const boxHeight = positiondata[0];
            setBoxHeight(250 + 150 * (boxHeight.rowSpan - 2));
        }
    }, [positiondata]);

    const [visibleDialog, setVisibleDialog] = useState(true);

    const toggleDialog = () => {
        setVisibleDialog(!visibleDialog);
    };


    const [visibleDialog2, setVisibleDialog2] = useState(true);

    const toggleDialog2 = () => {
        setVisibleDialog2(!visibleDialog2);
    };

    const [visibleDialog3, setVisibleDialog3] = useState(true);

    const toggleDialog3 = () => {
        setVisibleDialog3(!visibleDialog3);
    };


    const [visibleDialog4, setVisibleDialog4] = useState(true);

    const toggleDialog4 = () => {
        setVisibleDialog4(!visibleDialog4);
    };


    return (
        <div>
            <div>

                <GridLayout style={{ height: BoxHeight }}
                    rows={[
                        {
                            height: '13%',
                        },
                        {
                            height: '13%',
                        },
                        {
                            height: '13%',
                        },
                        {
                            height: '13%',
                        },
                        {
                            height: '13%',
                        },
                        {
                            height: '13%',
                        },
                        {
                            height: '13%',
                        },
                        {
                            height: '13%',
                        }

                    ]}

                    cols={[
                        {
                            width: '49%',
                        },
                        {
                            width: '49%',
                        },


                    ]}
                    gap={{
                        rows: 5,
                        cols: 5,
                    }}>
                    <GridLayoutItem col={1} row={1} colSpan={1} rowSpan={2}>
                        <Card
                            orientation='horizontal'
                            style={{ width: '100%', height:BoxHeight/4}}
                        >

                            <CardBody>
                                <CardTitle style={{fontSize:'1rem'}}>일일 인원</CardTitle>
                                <CardTitle style={{fontSize:'1rem'}}> <span onClick={toggleDialog} className='hovertext bolder'> {_.sumBy(DailyManArray, '인원수')}명</span></CardTitle>
                            </CardBody>


                            <CardImage src={'/image/construction-worker.png'} style={{ width: '3rem', height: '3rem', position: 'absolute', right: '5px' }}>

                            </CardImage>
                        </Card>
                    </GridLayoutItem>
                    <GridLayoutItem col={2} row={1} colSpan={1} rowSpan={2}>
                        <Card
                            orientation='horizontal'
                            style={{ width: '100%', height:BoxHeight/4}}
                        >

                            <CardBody>
                                <CardTitle style={{fontSize:'1rem'}}>누적 인원</CardTitle>
                                <CardTitle style={{fontSize:'1rem'}}> <span onClick={toggleDialog2} className='hovertext bolder'> {_.sumBy(AccManArray, '인원수')}명</span></CardTitle>
                            </CardBody>


                            <CardImage src={'/image/workers.png'} style={{ width: '3rem', height: '3rem', position: 'absolute', right: '5px' }}>

                            </CardImage>
                        </Card>
                    </GridLayoutItem>
                    <GridLayoutItem col={1} row={3} colSpan={1} rowSpan={2}>
                        <Card
                            orientation='horizontal'
                            style={{ width: '100%', height:BoxHeight/4}}
                        >

                            <CardBody>
                                <CardTitle style={{fontSize:'1rem'}}>일일 장비</CardTitle>
                                <CardTitle style={{fontSize:'1rem'}}> <span onClick={toggleDialog3} className='hovertext bolder'>   {_.sumBy(DailyEquipArray, '수량')}대</span></CardTitle>
                            </CardBody>


                            <CardImage src={'/image/toolbox.png'} style={{ width: '3rem', height: '3rem', position: 'absolute', right: '5px' }}>

                            </CardImage>
                        </Card>
                    </GridLayoutItem>
                    <GridLayoutItem col={2} row={3} colSpan={1} rowSpan={2}>
                        <Card
                            orientation='horizontal'
                            style={{ width: '100%', height:BoxHeight/4}}
                        >

                            <CardBody>
                                <CardTitle style={{fontSize:'1rem'}}>누적 장비</CardTitle>
                                <CardTitle style={{fontSize:'1rem'}}> <span onClick={toggleDialog4} className='hovertext bolder'> {_.sumBy(AccEquipArray, '수량')}대</span></CardTitle>
                            </CardBody>


                            <CardImage src={'/image/toolbox.png'} style={{ width: '3rem', height: '3rem', position: 'absolute', right: '5px' }}>

                            </CardImage>
                        </Card>
                    </GridLayoutItem>
                    <GridLayoutItem col={1} row={5} colSpan={1} rowSpan={2}>
                        <Card
                            orientation='horizontal'
                            style={{ width: '100%', height:BoxHeight/4}}
                        >

                            <CardBody>
                                <CardTitle style={{fontSize:'1rem'}}>신규채용자</CardTitle>
                                <CardTitle style={{fontSize:'1rem'}}className='bolder'>{newtrainee}명</CardTitle>
                            </CardBody>


                            <CardImage src={'/image/foreman.png'} style={{ width: '3rem', height: '3rem', position: 'absolute', right: '5px' }}>

                            </CardImage>
                        </Card>
                    </GridLayoutItem>
                    <GridLayoutItem col={2} row={5} colSpan={1} rowSpan={2}>
                        <Card
                            orientation='horizontal'
                            style={{ width: '100%', height:BoxHeight/4}}
                        >

                            <CardBody>
                                <CardTitle style={{fontSize:'1rem'}}>안전교육 총인원</CardTitle>
                                <CardTitle style={{fontSize:'1rem'}} className='bolder'>{totaltrainee}명</CardTitle>
                            </CardBody>


                            <CardImage src={'/image/training.png'} style={{ width: '3rem', height: '3rem', position: 'absolute', right: '5px' }}>

                            </CardImage>
                        </Card>
                    </GridLayoutItem>
                    <GridLayoutItem col={1} row={7} colSpan={1} rowSpan={2}>
                        <Card
                            orientation='horizontal'
                            style={{ width: '100%', height:BoxHeight/4}}
                        >

                            <CardBody>
                                <CardTitle style={{fontSize:'1rem'}}>월간교육 횟수</CardTitle>
                                <CardTitle style={{fontSize:'1rem'}} className='bolder'>  {monthhse}회</CardTitle>
                            </CardBody>


                            <CardImage src={'/image/training.png'} style={{ width: '3rem', height: '3rem', position: 'absolute', right: '5px' }}>

                            </CardImage>
                        </Card>
                    </GridLayoutItem>
                    <GridLayoutItem col={2} row={7} colSpan={1} rowSpan={2}>

                        <Card
                            orientation='horizontal'
                            style={{ width: '100%', height:BoxHeight/4}}
                        >

                            <CardBody>
                                <CardTitle style={{fontSize:'1rem'}}>안전교육 총횟수</CardTitle>
                                <CardTitle style={{fontSize:'1rem'}} className='bolder'>{counttraining}회</CardTitle>
                            </CardBody>


                            <CardImage src={'/image/training.png'} style={{ width: '3rem', height: '3rem', position: 'absolute', right: '5px' }}>

                            </CardImage>
                        </Card>
                    </GridLayoutItem>


                </GridLayout>

            </div>

            <div>
                {!visibleDialog && (
                    <Dialog title={"상세 인원 이력"} onClose={toggleDialog}>
                        <Grid
                            sortable={true}
                            sort={sort}
                            onSortChange={(e) => {
                                setSort(e.sort);
                            }}
                            data={orderBy(DailyManArray, sort)}
                        >
                            <GridColumn field="인력 타입" title="인력 타입" width="200px" />
                            <GridColumn field="인원수" title="인원수" width="100px" />

                        </Grid>
                    </Dialog>
                )
                }
                {
                    !visibleDialog2 && (
                        <Dialog title={"누적 인원 이력"} onClose={toggleDialog2}>
                            <Grid
                                sortable={true}
                                sort={sort}
                                onSortChange={(e) => {
                                    setSort(e.sort);
                                }}
                                data={orderBy(AccManArray, sort)}
                            >
                                <GridColumn field="인력 타입" title="인력 타입" width="200px" />
                                <GridColumn field="인원수" title="인원수" width="100px" />

                            </Grid>
                        </Dialog>
                    )
                }

                {
                    !visibleDialog3 && (
                        <Dialog title={"일일 장비 이력"} onClose={toggleDialog3}>
                            <Grid
                                sortable={true}
                                sort={sort}
                                onSortChange={(e) => {
                                    setSort(e.sort);
                                }}
                                data={orderBy(DailyEquipArray, sort)}
                            >
                                <GridColumn field="장비 종류" title="장비 종류" width="200px" />
                                <GridColumn field="수량" title="수량" width="100px" />

                            </Grid>
                        </Dialog>
                    )
                }


                {
                    !visibleDialog4 && (
                        <Dialog title={"누적 장비 이력"} onClose={toggleDialog4}>
                            <Grid
                                sortable={true}
                                sort={sort}
                                onSortChange={(e) => {
                                    setSort(e.sort);
                                }}
                                data={orderBy(AccEquipArray, sort)}
                            >
                                <GridColumn field="장비 종류" title="장비 종류" width="200px" />
                                <GridColumn field="수량" title="수량" width="100px" />

                            </Grid>
                        </Dialog>
                    )
                }

            </div>

        </div>

    )
};

export default ProjectMan

