import React, { useEffect, useState } from 'react';
import * as mnode from "../nodelibrary";
import _ from 'lodash';
import {
    Chart, ChartArea, ChartLegend, ChartSeries, ChartSeriesItem, ChartValueAxis, ChartValueAxisItem, ChartTooltip,ChartCategoryAxis , ChartCategoryAxisItem
} from "@progress/kendo-react-charts";


const CompanyMPM2 = ({ positiondata }) => {


    const [safety, setsafety] = useState([]);

    const getSaftey = () => {
        let path = `/home/centos/download/file/safety.json`;
        mnode.getJSON(path, setsafety);
    }

    useEffect(getSaftey, []);


    const newchart = safety.map(v => ({ ...v, month: v.date.slice(8, 10) + '년 ' + v.date.slice(0, 2) + '월' }))

    const newchart2 = newchart.map(v => ({ ...v, unique: v.project + '||' + v.month }))


    const chart2_equip =
        _(newchart2)
            .groupBy('unique')
            .map((objs, key) => ({
                'project': key.slice(0, key.indexOf("||")),
                'month': key.slice(key.indexOf("||") + 2),
                '인원': _.sumBy(objs, '직영근로자') + _.sumBy(objs, '관리자') + _.sumBy(objs, '협력업체반장') + _.sumBy(objs, '협력업체근로자'),
                '장비': _.sumBy(objs, '크레인') + _.sumBy(objs, '굴삭기') + _.sumBy(objs, '기타') + _.sumBy(objs, '트럭'),

            }))
            .value();


    const equip_result = chart2_equip.map(function (x) {
        var item = x.project;
        x[item] = x.장비;
        return x;
    });

    var equip_result2 = _(equip_result)
        .groupBy('month')
        .map(_.spread(_.assign))
        .value();


    var chart4 =
        _(newchart)
            .groupBy('month')
            .map((objs, key) => ({
                'month': key,
                '누적장비': _.sumBy(objs, '크레인') + _.sumBy(objs, '굴삭기') + _.sumBy(objs, '기타') + _.sumBy(objs, '트럭'),
            }))
            .value();

    let cumsum_chart4 = 0;
    const chart4_cum = chart4.map(({ month, 누적장비 }) => ({ month, 누적장비, 누적장비cum: cumsum_chart4 += 누적장비 }));

    const chart_result2_equip = equip_result2.map(v => ({ ...v, ...chart4_cum.find(sp => sp.month === v.month) }))

    const list = _.uniq(chart_result2_equip.map(v => v.project))

    const [BoxHeight, setBoxHeight] = useState();

    useEffect(() => {
        if (positiondata.length > 0) {
            const boxHeight = positiondata[8];
            setBoxHeight(155 + 150 * (boxHeight.rowSpan - 1));
        }
    }, [positiondata]);


    const tooltipcustom = (e) => e.point.series.name + ': ' + e.point.value;

    const labelContent = (e) => e.value === 0 || e.value === null || typeof e.value === 'undefined' ? '' : e.value;
    const seriesLabels = {
        visible: true,
        // Note that visible defaults to false
        padding: 3,
        font: "bold 0.8rem Arial, sans-serif",
        position: 'insideBase',
        background: 'none',
        content: labelContent
    };


    return (
        <Chart>
            <ChartArea height={BoxHeight * 0.9} />
            <ChartSeries>
                {list.map((row, index) => (
                    <ChartSeriesItem spacing={0} type="column" data={chart_result2_equip} field={row} categoryField="month" name={row} autoFit={true} labels={seriesLabels} axis='월간' />
                ))}
                <ChartSeriesItem type="line" data={chart_result2_equip} field='누적장비cum' categoryField="month" name='누적장비' autoFit={true} labels={seriesLabels} axis='누적' />


            </ChartSeries>

            <ChartTooltip render={tooltipcustom} />

            <ChartValueAxis>
                <ChartValueAxisItem majorTicks={{ step: 100, }} minorTicks={{ step: 100, }} majorGridLines={{ step: 100 }} labels={{ step: 5, }} name='월간' />
                <ChartValueAxisItem majorTicks={{ step: 100, }} minorTicks={{ step: 100, }} majorGridLines={{ step: 100 }} labels={{ step: 5, }} name='누적' />
            </ChartValueAxis>


            <ChartCategoryAxis>
                <ChartCategoryAxisItem labels={{ font: '0.65rem Arial' }} />
            </ChartCategoryAxis>

            <ChartLegend position="bottom" orientation="horizontal" labels={{ font: '0.65rem san-serif' }} />


        </Chart>

    )

};

export default CompanyMPM2