import React, { useEffect, useState } from 'react';
import axios from 'axios';
import _ from 'lodash';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4plugins_timeline from "@amcharts/amcharts4/plugins/timeline";
import * as am4plugins_bullets from '@amcharts/amcharts4/plugins/bullets'
import moment from "moment";
import { Checkbox} from "@progress/kendo-react-inputs";

const current_year = new Date().getFullYear().toString()

am4core.addLicense("CH363108325");
am4core.addLicense("MP363108325");
am4core.addLicense("TL363291176");
am4core.useTheme(am4themes_animated);
const CHART_ID = 'timeline_chart';


const QGantt = ({ positiondata }) => {
    const [data, setdata] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            const res = await axios.get(`https://dash.dtsolution.kr/api/getqa`)

            const filtered_null = _.filter(res.data.data.data, function (elem) { return elem.title !== null && elem.StartDateDO !== null && elem.FinishDateDO !== null; })
            const filtered_null_year = _.filter(filtered_null, function (elem) { return elem.StartDateDO.slice(6, 10) === current_year || elem.FinishDateDO.slice(6, 10) === current_year })
 

            const task1_child = filtered_null_year.map((v) => {

                if (v.CatQAuditSPD !== '업체평가')
                    return {
                        category: '품질 감사',
                        task: v.CatQAuditSPD,
                        start: moment(new Date(v.StartDateDO)).format("YYYY-MM-DD"),
                        end: moment(new Date(v.FinishDateDO)).format("YYYY-MM-DD"),

                    }
                else {
                    return {
                        category: '품질 감사',
                        task: v.CatQAuditSPD + '\n(' + v.uveVendorNameTB50 + ')',
                        start: moment(new Date(v.StartDateDO)).format("YYYY-MM-DD"),
                        end: moment(new Date(v.FinishDateDO)).format("YYYY-MM-DD"),

                    }
                }
            });




            const res2 = await axios.get(`https://dash.dtsolution.kr/api/getqs`)

            const filtered_null2 = _.filter(res2.data.data.data, function (elem) { return elem.title !== null && elem.StartDateDO !== null && elem.FinishDateDO !== null; })
            const filtered_null2_year = _.filter(filtered_null2, function (elem) { return elem.StartDateDO.slice(6, 10) === current_year && elem.FinishDateDO.slice(6, 10) === current_year })

            const task2_child = filtered_null2_year.map((v) => {

                if (v.uveVendorNameTB50 !== null)
                    return {
                        category: '품질 감독',
                        task: v.ProjectPK + '(' + v.uveVendorNameTB50 + ')',
                        start: moment(new Date(v.StartDateDO)).format("YYYY-MM-DD"),
                        end: moment(new Date(v.FinishDateDO)).format("YYYY-MM-DD"),

                    }
                else {
                    return {
                        category: '품질 감독',
                        task: v.ProjectPK,
                        start: moment(new Date(v.StartDateDO)).format("YYYY-MM-DD"),
                        end: moment(new Date(v.FinishDateDO)).format("YYYY-MM-DD"),

                    }
                }
            });



            const res3 = await axios.get(`https://dash.dtsolution.kr/api/getqi`)
            const filtered_null3 = _.filter(res3.data.data.data, function (elem) { return elem.title !== null && elem.InspPlanDate !== null && elem.InspTargetShellPK !== null; })
            const filtered_null3_year = _.filter(filtered_null3, function (elem) { return elem.InspPlanDate.slice(6, 10) === current_year; })

            const task3_child = filtered_null3_year.map((v) => {
                return {
                    category: '품질 점검',
                    task: v.InspTargetShellPK.slice(v.InspTargetShellPK.lastIndexOf('/') + 1, 50).replaceAll(' ', '\n') + ' 점검',
                    start: moment(new Date(v.InspPlanDate)).format("YYYY-MM-DD"), 
                    end: moment(new Date(v.InspPlanDate)).format("YYYY-MM-DD"),

                }
            });

            const res4 = await axios.get(`https://dash.dtsolution.kr/api/getqt`)
            const filtered_null4 = _.filter(res4.data.data.data, function (elem) { return elem.TrainingDate !== null; })
            const filtered_null4_year = _.filter(filtered_null4, function (elem) { return elem.TrainingDate.slice(6, 10) === current_year; })

            const task4_child = filtered_null4_year.map((v) => {
                return {
                    category: '품질 교육',
                    task: v.title,
                    start: moment(new Date(v.TrainingDate)).format("YYYY-MM-DD"),
                    end: moment(new Date(v.TrainingDate)).format("YYYY-MM-DD"),

                }
            });


            const res5 = await axios.get(`https://dash.dtsolution.kr/api/getJSON?path=/home/centos/download/file/qt.json`)

            const filtered_null5 = _.filter(res5.data, function (elem) { return elem.TrainingDate !== null; })
            const filtered_null5_year = _.filter(filtered_null5, function (elem) { return elem.TrainingDate.slice(6, 10) === current_year; })
            const task5_child = filtered_null5_year.map((v) => {
                return {
                    category: '전사 품질 교육',
                    task: v.title,
                    start: moment(new Date(v.TrainingDate)).format("YYYY-MM-DD"),
                    end: moment(new Date(v.TrainingDate)).format("YYYY-MM-DD"),

                }
            });




            setdata([...task1_child, ...task2_child, ...task3_child, ...task4_child, ...task5_child])
        };

        fetchData();
    }, []);

    const [BoxHeight, setBoxHeight] = useState();


    const [checkvalue, setcheckvalue] = useState(true);
    const handleCheckChange = (event) => {
        setcheckvalue(event.value);
      };


    useEffect(() => {
        if (positiondata.length > 0) {
            const boxHeight = positiondata[0];
            setBoxHeight(50 + 40 * (boxHeight.rowSpan - 2));
        }
    }, [positiondata]);


    useEffect(() => {



        var chart = am4core.create(CHART_ID, am4plugins_timeline.SerpentineChart);
    
        chart.levelCount = 2;
        chart.yAxisRadius = am4core.percent(25);
        chart.yAxisInnerRadius = am4core.percent(-25);
        chart.maskBullets = false;
    
        var colorSet = new am4core.ColorSet();
        colorSet.saturation = 0.5;

        chart.data = data && data
        // .filter(v=>v.start !== v.end)
        .map((v)=>({...v, color:colorSet.getIndex(v.category === '품질 감사'? 0 :v.category === '품질 감독'? 1:v.category === '품질 점검'? 2:v.category === '품질 교육'? 3:4  )}))

        chart.dateFormatter.dateFormat = "yyyy-MM-dd";
        chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";
        chart.fontSize = 11;
        
        var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "category";
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.renderer.labels.template.paddingRight = 25;
        categoryAxis.renderer.minGridDistance = 10;
        categoryAxis.renderer.innerRadius = -60;
        categoryAxis.renderer.radius = 60;
        
        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.minGridDistance = 70;
        dateAxis.baseInterval = { count: 1, timeUnit: "day" };
        dateAxis.renderer.tooltipLocation = 0;
        dateAxis.startLocation = -0.5;
        dateAxis.renderer.line.strokeDasharray = "1,4";
        dateAxis.renderer.line.strokeOpacity = 0.6;
        dateAxis.tooltip.disabled = true;
        // dateAxis.tooltip.background.fillOpacity = 0.2;
        // dateAxis.tooltip.background.cornerRadius = 5;
        // dateAxis.tooltip.label.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
        // dateAxis.tooltip.label.paddingTop = 7;
        
        var labelTemplate = dateAxis.renderer.labels.template;
        labelTemplate.verticalCenter = "middle";
        labelTemplate.fillOpacity = 0.7;
        labelTemplate.background.fill = new am4core.InterfaceColorSet().getFor("background");
        labelTemplate.background.fillOpacity = 1;
        labelTemplate.padding(7, 7, 7, 7);
        
        var series = chart.series.push(new am4plugins_timeline.CurveColumnSeries());
        series.columns.template.height = am4core.percent(20);
        series.columns.template.tooltipText = "{task}: \n[bold]{openDateX}[/] - [bold]{dateX}[/]";
        

        series.dataFields.openDateX = "start";
        series.dataFields.dateX = "end";
        series.dataFields.categoryY = "category";
        series.columns.template.propertyFields.fill = "color"; // get color from data
        series.columns.template.propertyFields.stroke = "color";
        series.columns.template.strokeOpacity = 0;
        
        var bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.radius = 3;
        bullet.circle.strokeOpacity = 0;
        bullet.propertyFields.fill = "color";
        bullet.locationX = 1;
        bullet.tooltipText = "[font-size: 11px]{task}\n{start} ~ {end}[/]";
        series.tooltip.pointerOrientation = "up";

    
        // var bullet2 = series.bullets.push(new am4charts.CircleBullet());
        // bullet2.circle.radius = 3;
        // bullet2.circle.strokeOpacity = 0;
        // bullet2.propertyFields.fill = "color";
        // bullet2.locationX = 1;
        
        
        
        var eventSeries = chart.series.push(new am4plugins_timeline.CurveLineSeries());
        eventSeries.dataFields.dateX = "start";
        eventSeries.dataFields.categoryY = "category";
        eventSeries.data =data && data.map((v)=>({...v,color:colorSet.getIndex(v.category === '품질 감사'? 0 :v.category === '품질 감독'? 1:v.category === '품질 점검'? 2:v.category === '품질 교육'? 3:4  )}))
        eventSeries.strokeOpacity = 0;
        
        var imageBullet1 = eventSeries.bullets.push(new am4plugins_bullets.PinBullet());
        imageBullet1.background.radius = 0;
        imageBullet1.locationX = 1;
        imageBullet1.propertyFields.stroke = "color";
        imageBullet1.background.propertyFields.fill = "color";
        imageBullet1.circle.radius = am4core.percent(100);
        imageBullet1.background.fillOpacity = 0.8;
        imageBullet1.background.strokeOpacity = 0;
        imageBullet1.dy = -2;
        imageBullet1.background.pointerBaseWidth = 10;
        imageBullet1.background.pointerLength = 10
        imageBullet1.tooltipText = "[font-size: 11px]{task}\n{start} ~ {end}[/]";


    var hs = imageBullet1.states.create("hover")
    hs.properties.scale = 1.3;
    hs.properties.opacity = 1;

        chart.scrollbarX = new am4core.Scrollbar();
        chart.scrollbarX.align = "center"
        chart.scrollbarX.width = am4core.percent(85);
        // chart.scrollbarX.marginTop = -10;
        
        var cursor = new am4plugins_timeline.CurveCursor();
        chart.cursor = cursor;
        cursor.xAxis = dateAxis;
        cursor.yAxis = categoryAxis;
        cursor.lineY.disabled = true;
        cursor.lineX.strokeDasharray = "1,4";
        cursor.lineX.strokeOpacity = 1;
        
        dateAxis.renderer.tooltipLocation2 = 0;
        categoryAxis.cursorTooltipEnabled = false;

        chart.events.on("ready", function () {
          dateAxis.zoomToDates(
            new Date(moment().format('YYYY-01-01')),
            new Date(moment().format('YYYY-12-31'))
          );
        });
    
        chart.zoomOutButton.disabled = true;

        if (checkvalue){
        var previousBullet;

        chart.events.on("inited", function() {
            setTimeout(function() {
                hoverItem(eventSeries.dataItems.getIndex(0));
            }, 3000)
        })
    
        function hoverItem(dataItem) {

            var bullet = dataItem && dataItem.bullets.getKey(imageBullet1.uid);
            var index = dataItem && dataItem.index;
            if (index >= eventSeries.dataItems.length - 1) {
                index = -1;
            }
            if (bullet) {
                if (previousBullet) {
                    previousBullet.isHover = false;
                }
    
                bullet.isHover = true;
                previousBullet = bullet;
            }
            setTimeout(
                function() {
                    hoverItem(eventSeries.dataItems.getIndex(index + 1))
                }, 1500);
        }
    }
    
    
        return () => {
          chart && chart.dispose();
        };
    
    
      }, [data, checkvalue]);

    return (
        <>
        <div
        id={CHART_ID}
        style={{ width: "100%", height:BoxHeight, zIndex: '999', margin:'0 auto' }}
      ></div>
      <div style={{position:'absolute', left:'10px', bottom:'8px'}}>
      <Checkbox
          value={checkvalue}
          onChange={handleCheckChange}
          label={"자동 전환"}
          size='small'
      />
  </div>

  </>
    );


};

export default QGantt

