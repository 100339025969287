import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Chart, ChartArea, ChartLegend,ChartCategoryAxis, ChartCategoryAxisItem,ChartSeries, ChartSeriesItem, ChartValueAxis, ChartValueAxisItem, ChartTooltip, exportVisual } from "@progress/kendo-react-charts";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { orderBy } from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import moment from 'moment';
import { Button, ButtonGroup } from '@progress/kendo-react-buttons';
import { exportImage } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";


const Cashflow2 = ({ positiondata }) => {

  const _export = useRef(null);

  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save();
    }
  };

  const [sort, setSort] = useState([{}]);

  const [cashflow, setcashflow] = useState([]);
  const { project_code } = useParams();

  const [percent, setpercent] = useState([]);
  const [lastprofit, setlastprofit] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      //플랜

      const res = await axios.get(`https://dash.dtsolution.kr/api/getcf?path=${project_code}`)

      // 레코드들을 각 라인아이템으로 이동 



      const cf_data = res.data.data.data[0].curves


      const cashin = _.filter(cf_data, { "Name": "Cash In F" })[0];
      delete cashin["From Date"]
      delete cashin["To Date"]
      delete cashin["Total"]
      delete cashin["Type"]
      delete cashin["Unassigned"]
      delete cashin["alert_message"]
      delete cashin["Currency"]
      delete cashin["Name"]
      delete cashin["details"]

      const cashin2 = Object.keys(cashin).map(xaxis => ({ xaxis, cashin: cashin[xaxis] })).map((item) => {
        return {

          xaxis: item.xaxis,
          cashin: Number(item.cashin.replace(/,/g, ''))
        }


      });


      const cashout = _.filter(cf_data, { "Name": "Cash Out F" })[0];
      delete cashout["From Date"]
      delete cashout["To Date"]
      delete cashout["Total"]
      delete cashout["Type"]
      delete cashout["Unassigned"]
      delete cashout["alert_message"]
      delete cashout["Currency"]
      delete cashout["Name"]
      delete cashout["details"]

      const cashout2 = Object.keys(cashout).map(xaxis => ({ xaxis, cashout: cashout[xaxis] })).map((item) => {
        return {

          xaxis: item.xaxis,
          cashout: Number(item.cashout.replace(/,/g, '')) * -1
        }

      });

      const cashflow_o = cashin2.map(v => ({ ...v, ...cashout2.find(sp => sp.xaxis === v.xaxis) }));


      const sailes = _.filter(cf_data, { "Name": "매출" })[0];
      delete sailes["From Date"]
      delete sailes["To Date"]
      delete sailes["Total"]
      delete sailes["Type"]
      delete sailes["Unassigned"]
      delete sailes["alert_message"]
      delete sailes["Currency"]
      delete sailes["Name"]
      delete sailes["details"]

      const sailes2 = Object.keys(sailes).map(xaxis => ({ xaxis, sailes: sailes[xaxis] })).map((item) => {
        return {
          xaxis: item.xaxis,
          sailes: Number(item.sailes.replace(/,/g, ''))
        }
      });


      const cost = _.filter(cf_data, { "Name": "원가" })[0];
      delete cost["From Date"]
      delete cost["To Date"]
      delete cost["Total"]
      delete cost["Type"]
      delete cost["Unassigned"]
      delete cost["alert_message"]
      delete cost["Currency"]
      delete cost["Name"]
      delete cost["details"]


      const cost2 = Object.keys(cost).map(xaxis => ({ xaxis, cost: cost[xaxis] })).map((item) => {
        return {
          xaxis: item.xaxis,
          cost: Number(item.cost.replace(/,/g, ''))
        }
      });


      const cashflow_o2 = sailes2.map(v => ({ ...v, ...cost2.find(sp => sp.xaxis === v.xaxis) }));

      const cashflow_total = cashflow_o.map(v => ({ ...v, ...cashflow_o2.find(sp => sp.xaxis === v.xaxis) }));


      const cashflow_o_a = cashflow_total.map((item) => {
        return {

          xaxis: item.xaxis.slice(5, 7) + '년 ' + item.xaxis.slice(0, 2) + '월',
          cashout: item.cashout,
          cashin: item.cashin,
          sailes: item.sailes,
          cost: item.cost,
          variance: item.cashout + item.cashin,
          profit: item.sailes - item.cost

        }
      })

      cashflow_o_a.sort((a, b) => a.xaxis.localeCompare(b.xaxis));

      let cums = 0;
      let cums2 = 0;
      let cums3 = 0;
      let cums4 = 0;
      const cashflow_o_a_c = cashflow_o_a.map(({ xaxis, cashin, cashout, sailes, cost, variance, profit }) => ({ xaxis, cashin, cashout, sailes, cost, variance, profit, c_variance: cums += variance, c_profit: cums2 += profit, c_sails: cums3 += sailes, c_cost: cums4 += cost }));

      setcashflow(cashflow_o_a_c)
      setlastprofit(((cashflow_o_a_c[cashflow_o_a_c.length - 1].c_profit) / 100000000).toFixed(2) + '억')
      setpercent((((_.sumBy(cashflow_o_a_c, "cost")) / (_.sumBy(cashflow_o_a_c, "sailes"))) * 100).toFixed(2) + '%')

    }

    fetchData();

  }, [project_code]);

  const [BoxHeight, setBoxHeight] = useState();

  useEffect(() => {
    if (positiondata.length > 0) {
      const boxHeight = positiondata[7];
      setBoxHeight(250 + 150 * (boxHeight.rowSpan - 2));
    }
  }, [positiondata]);


  const SharedTooltip = (props) => {
    const { points } = props;

    return (
      <div>
        <div>{points[0].category}</div>
        {points.map((point, index) => (
          <div key={index}>
            {point.series.name} : {Number.parseFloat(point.value / 100000000).toFixed(2) + '억'}
          </div>

        ))}
      </div>
    );
  };

  const sharedTooltipRender = (context) => <SharedTooltip {...context} />;

  const labelContentAxis = (e) => (e.value / 100000000).toFixed(2) + '억';

  const [visibleDialog, setVisibleDialog] = useState(true);

  const toggleDialog = () => {
    setVisibleDialog(!visibleDialog);
  };


  const [zoomableyn, setzoomableyn] = useState(false);

  const toggleZoom = () => {
    setzoomableyn(!zoomableyn);
  };

  const chart = useRef(null);

  const onImageExportClick = () => {
    const chartVisual = exportVisual(chart.current);

    if (chartVisual) {
      exportImage(chartVisual).then((dataURI) => saveAs(dataURI, "chart.png"));
    }
  };


  const formatsailes = (props) => {
    const label = props.dataItem.sailes
    const max = _.maxBy(cashflow, 'sailes')['sailes']
    if (label > 0) {
      if (label === max) {

        return (
          <td style={{ color: 'green', fontWeight: 'bolder' }}>
            {(label / 100000000).toFixed(0) + '억'}
          </td>
        );
      }
      return (
        <td style={{ color: 'green' }}>
          {(label / 100000000).toFixed(0) + '억'}
        </td>
      );

    }
    else if (label < 0) {
      if (label === max) {
        return (
          <td style={{ color: 'red', fontWeight: 'bolder' }}>
            {(label / 100000000).toFixed(0) + '억'}
          </td>
        );
      }
      return (
        <td style={{ color: 'red' }}>
          {(label / 100000000).toFixed(0) + '억'}
        </td>
      );
    }

    return (<td></td>);
  }

  const formatcashin = (props) => {
    const label = props.dataItem.cashin
    const max = _.maxBy(cashflow, 'cashin')['cashin']

    if (label > 0) {
      if (label === max) {

        return (
          <td style={{ color: 'green', fontWeight: 'bolder' }}>
            {(label / 100000000).toFixed(0) + '억'}
          </td>
        );
      }
      return (
        <td style={{ color: 'green' }}>
          {(label / 100000000).toFixed(0) + '억'}
        </td>
      );

    }
    else if (label < 0) {
      if (label === max) {
        return (
          <td style={{ color: 'red', fontWeight: 'bolder' }}>
            {(label / 100000000).toFixed(0) + '억'}
          </td>
        );
      }
      return (
        <td style={{ color: 'red' }}>
          {(label / 100000000).toFixed(0) + '억'}
        </td>
      );
    }

    return (<td></td>);
  }


  const formatcashout = (props) => {
    const label = props.dataItem.cashout
    const max = _.maxBy(cashflow, 'cashout')['cashout']

    if (label > 0) {
      if (label === max) {

        return (
          <td style={{ color: 'green', fontWeight: 'bolder' }}>
            {(label / 100000000).toFixed(0) + '억'}
          </td>
        );
      }
      return (
        <td style={{ color: 'green' }}>
          {(label / 100000000).toFixed(0) + '억'}
        </td>
      );

    }
    else if (label < 0) {
      if (label === max) {
        return (
          <td style={{ color: 'red', fontWeight: 'bolder' }}>
            {(label / 100000000).toFixed(0) + '억'}
          </td>
        );
      }
      return (
        <td style={{ color: 'red' }}>
          {(label / 100000000).toFixed(0) + '억'}
        </td>
      );
    }

    return (<td></td>);
  }


  const formatvariance = (props) => {
    const label = props.dataItem.variance
    const max = _.maxBy(cashflow, 'variance')['variance']

    if (label > 0) {
      if (label === max) {

        return (
          <td style={{ color: 'green', fontWeight: 'bolder' }}>
            {(label / 100000000).toFixed(0) + '억'}
          </td>
        );
      }
      return (
        <td style={{ color: 'green' }}>
          {(label / 100000000).toFixed(0) + '억'}
        </td>
      );

    }
    else if (label < 0) {
      if (label === max) {
        return (
          <td style={{ color: 'red', fontWeight: 'bolder' }}>
            {(label / 100000000).toFixed(0) + '억'}
          </td>
        );
      }
      return (
        <td style={{ color: 'red' }}>
          {(label / 100000000).toFixed(0) + '억'}
        </td>
      );
    }

    return (<td></td>);
  }

  const formatc_variance = (props) => {
    const label = props.dataItem.c_variance
    const max = _.maxBy(cashflow, 'c_variance')['c_variance']

    if (label > 0) {
      if (label === max) {

        return (
          <td style={{ color: 'green', fontWeight: 'bolder' }}>
            {(label / 100000000).toFixed(0) + '억'}
          </td>
        );
      }
      return (
        <td style={{ color: 'green' }}>
          {(label / 100000000).toFixed(0) + '억'}
        </td>
      );

    }
    else if (label < 0) {
      if (label === max) {
        return (
          <td style={{ color: 'red', fontWeight: 'bolder' }}>
            {(label / 100000000).toFixed(0) + '억'}
          </td>
        );
      }
      return (
        <td style={{ color: 'red' }}>
          {(label / 100000000).toFixed(0) + '억'}
        </td>
      );
    }

    return (<td></td>);
  }

  const formatprofit = (props) => {
    const label = props.dataItem.profit
    const max = _.maxBy(cashflow, 'profit')['profit']

    if (label > 0) {
      if (label === max) {

        return (
          <td style={{ color: 'green', fontWeight: 'bolder' }}>
            {(label / 100000000).toFixed(0) + '억'}
          </td>
        );
      }
      return (
        <td style={{ color: 'green' }}>
          {(label / 100000000).toFixed(0) + '억'}
        </td>
      );

    }
    else if (label < 0) {
      if (label === max) {
        return (
          <td style={{ color: 'red', fontWeight: 'bolder' }}>
            {(label / 100000000).toFixed(0) + '억'}
          </td>
        );
      }
      return (
        <td style={{ color: 'red' }}>
          {(label / 100000000).toFixed(0) + '억'}
        </td>
      );
    }

    return (<td></td>);
  }

  const formatc_profit = (props) => {
    const label = props.dataItem.c_profit
    const max = _.maxBy(cashflow, 'c_profit')['c_profit']

    if (label > 0) {
      if (label === max) {

        return (
          <td style={{ color: 'green', fontWeight: 'bolder' }}>
            {(label / 100000000).toFixed(0) + '억'}
          </td>
        );
      }
      return (
        <td style={{ color: 'green' }}>
          {(label / 100000000).toFixed(0) + '억'}
        </td>
      );

    }
    else if (label < 0) {
      if (label === max) {
        return (
          <td style={{ color: 'red', fontWeight: 'bolder' }}>
            {(label / 100000000).toFixed(0) + '억'}
          </td>
        );
      }
      return (
        <td style={{ color: 'red' }}>
          {(label / 100000000).toFixed(0) + '억'}
        </td>
      );
    }

    return (<td></td>);
  }

  const formatcost = (props) => {
    const label = props.dataItem.cost
    const max = _.maxBy(cashflow, 'cost')['cost']


    if (label > 0) {
      if (label === max) {

        return (
          <td style={{ color: 'green', fontWeight: 'bolder' }}>
            {(label / 100000000).toFixed(0) + '억'}
          </td>
        );
      }
      return (
        <td style={{ color: 'green' }}>
          {(label / 100000000).toFixed(0) + '억'}
        </td>
      );

    }
    else if (label < 0) {
      if (label === max) {
        return (
          <td style={{ color: 'red', fontWeight: 'bolder' }}>
            {(label / 100000000).toFixed(0) + '억'}
          </td>
        );
      }
      return (
        <td style={{ color: 'red' }}>
          {(label / 100000000).toFixed(0) + '억'}
        </td>
      );
    }

    return (<td></td>);
  }


  const formatc_sails = (props) => {
    const label = props.dataItem.c_sails
    const max = _.maxBy(cashflow, 'c_sails')['c_sails']

    if (label > 0) {
      if (label === max) {

        return (
          <td style={{ color: 'green', fontWeight: 'bolder' }}>
            {(label / 100000000).toFixed(0) + '억'}
          </td>
        );
      }
      return (
        <td style={{ color: 'green' }}>
          {(label / 100000000).toFixed(0) + '억'}
        </td>
      );

    }
    else if (label < 0) {
      if (label === max) {
        return (
          <td style={{ color: 'red', fontWeight: 'bolder' }}>
            {(label / 100000000).toFixed(0) + '억'}
          </td>
        );
      }
      return (
        <td style={{ color: 'red' }}>
          {(label / 100000000).toFixed(0) + '억'}
        </td>
      );
    }

    return (<td></td>);
  }

  const formatc_cost = (props) => {
    const label = props.dataItem.c_cost
    const max = _.maxBy(cashflow, 'c_cost')['c_cost']

    if (label > 0) {
      if (label === max) {

        return (
          <td style={{ color: 'green', fontWeight: 'bolder' }}>
            {(label / 100000000).toFixed(0) + '억'}
          </td>
        );
      }
      return (
        <td style={{ color: 'green' }}>
          {(label / 100000000).toFixed(0) + '억'}
        </td>
      );

    }
    else if (label < 0) {
      if (label === max) {
        return (
          <td style={{ color: 'red', fontWeight: 'bolder' }}>
            {(label / 100000000).toFixed(0) + '억'}
          </td>
        );
      }
      return (
        <td style={{ color: 'red' }}>
          {(label / 100000000).toFixed(0) + '억'}
        </td>
      );
    }

    return (<td></td>);
  }

  const today_month = moment(new Date()).format('YY년 MM월')

  const rowRender = (trElement, props) => {
    const available = props.dataItem.xaxis;
    const green = {
      backgroundColor: "rgb(55, 180, 0,0.32)",
    };

    const none = {
      fontWeight: "noraml",
    };


    const trProps = {
      style: available === today_month ? green : none,
    };
    return React.cloneElement(
      trElement,
      { ...trProps },
      trElement.props.children
    );
  };


  const sumfooter = (props) => {
    const field = props.field || "";
    const total = _.sumBy(cashflow, field)
    return (
      <td colSpan={props.colSpan} style={props.style}>
        합계: {(total / 100000000).toFixed(0) + '억'}
      </td>
    );
  };


  const seriesLabels = {
    visible: true,
    // Note that visible defaults to false
    padding: 0,
    font: "0.7rem Arial, sans-serif",
    background: 'none',
    rotation:{angle:'auto'}
  };


  const seriesLabels2 = {
    visible: true,
    // Note that visible defaults to false
    padding: 0,
    font: "0.8rem Arial, sans-serif",
    background: 'none',
  };


  const axisCrossingValue = [0, cashflow.length];

  return (
    <div>
      <div>
        <GridLayout style={{ height: BoxHeight }}
          rows={[
            {
              height: '12%',
            },
            {
              height: '12%',
            },
            {
              height: '12%',
            },
            {
              height: '12%',
            },
            {
              height: '12%',
            },
            {
              height: '12%',
            },
            {
              height: '12%',
            },
            {
              height: '12%',
            },
            {
              height: '12%',
            },
            {
              height: '12%',
            }



          ]}

          cols={[
            {
              width: '100%',
            },


          ]}
          gap={{
            rows: 5,
            cols: 5,
          }}>

          <GridLayoutItem col={1} row={1} colSpan={1} rowSpan={1}>
            <div style={{ fontSize: `${(BoxHeight / 300) * 15}px`, padding: '5px' }}>실행률: {percent} 매출 이익: {lastprofit}</div>
            <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
              <ButtonGroup>
                <Button
                  title="확대/이동 켜기"
                  iconClass='k-icon k-i-zoom-in'
                  themeColor={zoomableyn ? "primary" : null} fillMode="flat"
                  onClick={toggleZoom}
                >
                </Button>

                <Button
                  title="이미지로 저장"
                  iconClass='k-icon k-i-image-export'
                  fillMode="flat"
                  onClick={onImageExportClick}
                >
                </Button>

                <Button
                  title="테이블로 보기"
                  iconClass='k-icon k-i-table'
                  fillMode="flat"
                  onClick={toggleDialog}

                >
                </Button>
              </ButtonGroup>
            </div>
          </GridLayoutItem>
          <GridLayoutItem col={1} row={3} colSpan={1} rowSpan={9}>
            <Chart ref={chart} pannable={zoomableyn} zoomable={zoomableyn} transitions={false} >
              <ChartArea height={BoxHeight} />
              <ChartLegend position="bottom" orientation="horizontal"labels={seriesLabels2} />

              <ChartValueAxis>

                <ChartValueAxisItem name='left' labels={{ content: labelContentAxis, font: "0.7rem Arial, sans-serif" }} />
                <ChartValueAxisItem name='right' labels={{ content: labelContentAxis, font: "0.7rem Arial, sans-serif"}} orientation="right" visible={true} />


              </ChartValueAxis>
              <ChartTooltip shared={true} render={sharedTooltipRender} />

              <ChartSeries>
                <ChartSeriesItem
                  color={cashflow.color}
                  type="column"
                  data={cashflow}
                  field="sailes"
                  categoryField="xaxis"
                  name="매출"
                  autoFit={true}
                  axis='left'

                />
                <ChartSeriesItem
                  color={cashflow.color}
                  type="column"
                  data={cashflow}
                  field="cost"
                  categoryField="xaxis"
                  name="원가"
                  axis='left'
                  autoFit={true}
                />

           
                <ChartSeriesItem
                  color={cashflow.color}
                  type="line"
                  data={cashflow}
                  field="profit"
                  categoryField="xaxis"
                  name="월간 손익"
                  axis='right'
                  autoFit={true}
                />
                <ChartSeriesItem
                  color={cashflow.color}
                  type="line"
                  data={cashflow}
                  field="c_profit"
                  categoryField="xaxis"
                  name="누적 손익"
                  axis='right'
                  autoFit={true}
                />
                <ChartSeriesItem
                  color={cashflow.color}
                  type="line"
                  data={cashflow}
                  field="c_sails"
                  categoryField="xaxis"
                  axis='right'
                  name="누적 매출"
                  autoFit={true}
                />
                <ChartSeriesItem
                  color={cashflow.color}
                  type="line"
                  data={cashflow}
                  field="c_cost"
                  categoryField="xaxis"
                  name="누적 원가"
                  axis='right'
                  autoFit={true}
                />
              </ChartSeries>

              <ChartCategoryAxis>
                <ChartCategoryAxisItem labels={seriesLabels} axisCrossingValue={axisCrossingValue} >
                </ChartCategoryAxisItem>
              </ChartCategoryAxis>


            </Chart>

          </GridLayoutItem>

        </GridLayout>
      </div>
      <div>
        {!visibleDialog && (
          <Dialog title={"자금수지"} onClose={toggleDialog} width='80%' height='600px'>
            <ExcelExport data={cashflow} ref={_export}>
              <Grid

                data={orderBy(cashflow, sort)}
                sortable={true}
                sort={sort}
                onSortChange={(e) => {
                  setSort(e.sort);
                }}
                rowRender={rowRender}
                style={{height:'500px'}}
              >
                <GridToolbar>
                  <button
                    title="Export Excel"
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                    onClick={excelExport}
                    style={{ position: 'absolute', right: '5px' }}
                  >
                    <span className="k-icon k-i-file-excel"></span>
                  </button>
                </GridToolbar>

                <GridColumn field="xaxis" title="월" />
                <GridColumn field="sailes" title="매출" cell={formatsailes} footerCell={sumfooter} />
                <GridColumn field="cost" title="원가" cell={formatcost} footerCell={sumfooter} />
                <GridColumn field="cashin" title="Cash In" cell={formatcashin} footerCell={sumfooter} />
                <GridColumn field="cashout" title="Cash Out" cell={formatcashout} footerCell={sumfooter} />
                <GridColumn field="variance" title="월간 Cash InOut" cell={formatvariance} footerCell={sumfooter} />
                <GridColumn field="c_variance" title="누적 Cash InOut" cell={formatc_variance} />
                <GridColumn field="profit" title="월간 손익" cell={formatprofit} footerCell={sumfooter} />
                <GridColumn field="c_profit" title="누적 손익" cell={formatc_profit} />
                <GridColumn field="c_sails" title="누적 매출" cell={formatc_sails} />
                <GridColumn field="c_cost" title="누적 원가" cell={formatc_cost} />

              </Grid>
            </ExcelExport>
          </Dialog>
        )
        }
      </div>
    </div>
  );
};

export default Cashflow2


