import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Chart, ChartArea, ChartLegend, ChartSeries, ChartSeriesItem, ChartValueAxis, ChartValueAxisItem, ChartTooltip, ChartTitle, } from "@progress/kendo-react-charts";


const ProcurementStatus = ({ positiondata }) => {

    const [data, setdata] = useState([]);
    const [count, setcount] = useState([]);
    const [total, settotal] = useState([]);
    // const [groups, setgroups] = useState([]);

    const { project_code } = useParams();

    useEffect(() => {
        const fetchData = async () => {
            const res = await axios.get(`https://dash.dtsolution.kr/api/getRFPPlan?path=${project_code}`)
            const data2 = res.data.data.data.map((v) => {
                return {
                    xaxis: v.ContractDatePlan.slice(8, 10) + ' ' + Math.ceil(v.ContractDatePlan.slice(0, 2) / 3) + 'Q',
                    계획분: v.BudgetCA,
                    실적분: v.ContractCA,
                    RFP명: v.title,
                    status: v.status

                }
            })

            var output =
                _(data2)
                    .groupBy('xaxis')
                    .map((objs, key) => ({
                        'xaxis': key,
                        '계획분': _.sumBy(objs, '계획분'),
                        '실적분': _.sumBy(objs, '실적분'),
                        '계획건수': _.countBy(objs, { 'xaxis': key }).true,
                        '실적건수': _.countBy(objs, { 'status': 'Concluded' }).true,

                    }))
                    .value();

            const sort_data = _.sortBy(output, 'xaxis');

            setdata(sort_data)
            const total_count = [{ xaxis: '발주현황(건)', 계획분: _.sumBy(sort_data, '계획건수'), 실적분: _.sumBy(sort_data, '실적건수') }]
            setcount(total_count);

            const filtered = _.filter(data2, { 'status': 'Concluded' })
            const total_total = [{ xaxis: '실행율', 계획분: 100, 실적분: (_.sumBy(filtered, '실적분') / _.sumBy(filtered, '계획분')) * 100 }]
            settotal(total_total);


            // const c_data = await axios.get(`https://dash.dtsolution.kr/api/getcontract?path=${project_code}`)
            // const c_data_o = c_data.data.data.data.map((v) => {
            //     return {
            //         group: v.ContractType,
            //         Amount: v.i_contract_total_amt,
            //         Vendor: v.uveVendorNameTB50,
            //         ContractName: v.uconTitleSD,
            //         ContractDate: v.uuu_effective_date,
            //         volume: v.i_contract_total_amt / 100000000
            //     }
            // });


            // var data = c_data_o,
            //     keys = ['group', 'Vendor'],
            //     result = [],
            //     temp = { _: result };

            // data.forEach(function (a) {
            //     keys.reduce(function (r, k) {
            //         if (!r[a[k]]) {
            //             r[a[k]] = { _: [] };
            //             r._.push({ name: a[k], children: r[a[k]]._ });
            //         }
            //         return r[a[k]];
            //     }, temp)._.push({ name: a.ContractName, Amount: a.Amount });
            // });

            // setgroups(result);

        };

        fetchData();
    }, [project_code]);


    // const current_q = new Date().getFullYear().toString().slice(-2) + ' ' + quarter + 'Q'
    // const treechart = { "name": '전체 계약', "children": groups }

    const [BoxHeight, setBoxHeight] = useState();

    useEffect(() => {
        if (positiondata.length > 0) {
            const boxHeight = positiondata[0];
            setBoxHeight(250 + 150 * (boxHeight.rowSpan - 1));
        }
    }, [positiondata]);
    
    const labelContentAxis = (e) => e.value > 100000000 ? e.value / 100000000 + '억' : e.value;

    const SharedTooltip = (props) => {
        const { category, points } = props;
        return (
          <div>
            <div>{category}</div>
            {points.map((point, index) => (
        
                <div key={index}>
                 {point.series.name} : {new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW' }).format(Number.parseFloat(point.value / 1000000).toFixed(0)) + '백만'}
                </div> 
        
       
            ))}
          </div>
        );
      };
    
      const sharedTooltipRender = (context) => <SharedTooltip {...context} />; 

      const SharedTooltip2 = (props) => {
        const { category, points } = props;
        return (
          <div>
            <div>{category}</div>
            {points.map((point, index) => (
        
                <div key={index}>
                 {point.series.name} : {(point.value) + '건'}
                </div> 
        
       
            ))}
          </div>
        );
      };
    
      const sharedTooltipRender2 = (context) => <SharedTooltip2 {...context} />;

      const SharedTooltip3 = (props) => {
        const { category, points } = props;
        return (
          <div>
            <div>{category}</div>
            {points.map((point, index) => (
        
                <div key={index}>
                 {point.series.name} : {(point.value) + '건'}
                </div> 
        
       
            ))}
          </div>
        );
      };
    
      const sharedTooltipRender3 = (context) => <SharedTooltip3 {...context} />;

      const SharedTooltip4 = (props) => {
        const { category, points } = props;
        return (
          <div>
            {points.map((point, index) => (
        
                <div key={index}>
                 {point.series.name} : {(point.value.toFixed(2)) + '%'}
                </div> 
        
       
            ))}
          </div>
        );
      };
    
      const sharedTooltipRender4 = (context) => <SharedTooltip4 {...context} />;

      const valueAxisLabels = {
        padding: 0,
        font: "10px Arial, sans-serif",
        step: 3,
      };

    const seriesLabelscontent = (e) => (e.value / 100000000).toFixed(2) + '억';
    
      const seriesLabels = {
        visible: true,
        // Note that visible defaults to false
        padding: 3,
        font: "bold 11px Arial, sans-serif",
        position:'center',
        background :'none',
        content: seriesLabelscontent
      };


      const seriesLabelscontent2 = (e) => e.value +'건';
    
      const seriesLabels2 = {
        visible: true,
        // Note that visible defaults to false
        padding: 3,
        font: "bold 11px Arial, sans-serif",
        position:'center',
        background :'none',
        content: seriesLabelscontent2
      };

      const seriesLabelscontent3= (e) => e.value.toFixed(2) +'%';
    
      const seriesLabels3 = {
        visible: true,
        // Note that visible defaults to false
        padding: 3,
        font: "bold 11px Arial, sans-serif",
        position:'center',
        background :'none',
        content: seriesLabelscontent3
      };


      

      

      
    return (

        <GridLayout style={{ height: BoxHeight }} rows={[{ height: '50%', }, { height: '50%', },]} cols={[{ width: '49%', }, { width: '49%', },]} gap={{ rows: 5, cols: 5, }}>

            <GridLayoutItem col={1} row={1} colSpan={1} rowSpan={1}>
            <Chart>
            <ChartTitle  text="외주/구매 발주현황(단위: 백만원)" />
            <ChartArea height={BoxHeight/2} />
            <ChartLegend position="bottom" orientation="horizontal" />
            <ChartTooltip shared={true} render={sharedTooltipRender} />
            <ChartValueAxis>
              <ChartValueAxisItem labels={{ content: labelContentAxis}}/>
            </ChartValueAxis>
            <ChartSeries>
            <ChartSeriesItem color={data.color} type="column"spacing={0}  data={data} field="계획분" categoryField="xaxis" name="계획분" autoFit={true} labels={seriesLabels} />
            <ChartSeriesItem color={data.color} type="column" data={data} field="실적분" categoryField="xaxis" name="실적분" autoFit={true} labels={seriesLabels}/>
            </ChartSeries>
            </Chart>
            </GridLayoutItem>

            <GridLayoutItem col={2} row={1} colSpan={1} rowSpan={1}>
            <Chart>
            <ChartTitle  text="외주/구매 발주현황(단위: 건수)" />
            <ChartArea height={BoxHeight/2} />
            <ChartTooltip shared={true} render={sharedTooltipRender2} />
            <ChartLegend position="bottom" orientation="horizontal" />
            <ChartValueAxis>
            <ChartValueAxisItem labels={valueAxisLabels}/>
            </ChartValueAxis>
            <ChartSeries>
            <ChartSeriesItem color={data.color} type="column"spacing={0}  data={data} field="계획건수" categoryField="xaxis" name="계획분" autoFit={true}labels={seriesLabels2} />
            <ChartSeriesItem color={data.color} type="column" data={data} field="실적건수" categoryField="xaxis" name="실적분" autoFit={true} labels={seriesLabels2}/>
            </ChartSeries>
            </Chart>
            </GridLayoutItem>
            <GridLayoutItem col={1} row={2} colSpan={1} rowSpan={1}>
            <Chart>
            <ChartTitle  text="발주현황(단위: 건)" />
            <ChartArea height={BoxHeight/2} />
            <ChartTooltip shared={true} render={sharedTooltipRender3} />
            <ChartLegend position="bottom" orientation="horizontal" />
            <ChartValueAxis>
              <ChartValueAxisItem />
            </ChartValueAxis>
            <ChartSeries>
            <ChartSeriesItem color={count.color} type="bar"spacing={0}  data={count} field="계획분" categoryField="xaxis" name="계획분" autoFit={true}labels={seriesLabels2} />
            <ChartSeriesItem color={count.color} type="bar" data={count} field="실적분" categoryField="xaxis" name="실적분" autoFit={true} labels={seriesLabels2}/>
            </ChartSeries>

            </Chart>
            </GridLayoutItem>
            <GridLayoutItem col={2} row={2} colSpan={1} rowSpan={1}>
            <Chart>
            <ChartTitle  text="낙찰율(단위: %)" />
            <ChartArea height={BoxHeight/2} />
            <ChartLegend position="bottom" orientation="horizontal" />
            <ChartTooltip shared={true} render={sharedTooltipRender4} />

            <ChartValueAxis>
              <ChartValueAxisItem min={0} max={100} />
            </ChartValueAxis>

            <ChartSeries>
            <ChartSeriesItem color={total.color} type="bar"spacing={0}  data={total} field="계획분" categoryField="xaxis" name="계획분" autoFit={true} labels={seriesLabels3}/>
            <ChartSeriesItem color={total.color} type="bar" data={total} field="실적분" categoryField="xaxis" name="실적분" autoFit={true}labels={seriesLabels3} />
            </ChartSeries>

            </Chart>
            </GridLayoutItem>

        </GridLayout>

    );
};

export default ProcurementStatus

