import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import * as mnode from "../nodelibrary";
import { GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import { Chart, ChartArea, ChartLegend, ChartSeries, ChartSeriesItem, ChartValueAxis, ChartValueAxisItem, ChartTooltip, ChartTitle, } from "@progress/kendo-react-charts";



const ProcurementC = ({positiondata}) => {


  const [result, setresult] = useState([]);

  const getPR = () => {

    let path = `/home/centos/download/file/procurement.json`;
    mnode.getJSON(path, setresult);
  }

  useEffect(getPR, []);



  var output =
    _(result)
      .groupBy('xaxis')
      .map((objs, key) => ({
        'xaxis': key,
        '계획분': _.sumBy(objs, '계획분'),
        '실적분': _.sumBy(objs, '실적분'),
        '계획건수': _.countBy(objs, { 'xaxis': key }).true,
        '실적건수': _.countBy(objs, { 'status': 'Concluded' }).true,

      }))
      .value();

  const sort_data = _.sortBy(output, 'xaxis');


  const total_count = [{ xaxis: '발주현황(건)', 계획분: _.sumBy(sort_data, '계획건수'), 실적분: _.sumBy(sort_data, '실적건수') }]

  const filtered = _.filter(result, { 'status': 'Concluded' })
  const total_total = [{ xaxis: '실행율', 계획분: 100, 실적분: (_.sumBy(filtered, '실적분') / _.sumBy(filtered, '계획분')) * 100 }]

  var today = new Date();
  var quarter = Math.floor((today.getMonth() + 3) / 3);


  
  const [BoxHeight, setBoxHeight] = useState();

  useEffect(() => {
      if (positiondata.length > 0) {
          const boxHeight = positiondata[0];
          setBoxHeight(250 + 150 * (boxHeight.rowSpan - 1));
      }
  }, [positiondata]);
  
  const labelContentAxis = (e) => e.value ===0 || e.value === null || typeof e.value === 'undefined'?'': e.value / 100000000 + '억';

  const SharedTooltip = (props) => {
      const { category, points } = props;
      return (
        <div>
          <div>{category}</div>
          {points.map((point, index) => (
      
              <div key={index}>
               {point.series.name} : {new Intl.NumberFormat('ko-KR', { style: 'currency', currency: 'KRW' }).format(Number.parseFloat(point.value / 1000000).toFixed(0)) + '백만'}
              </div> 
      
     
          ))}
        </div>
      );
    };
  
    const sharedTooltipRender = (context) => <SharedTooltip {...context} />; 

    const SharedTooltip2 = (props) => {
      const { category, points } = props;
      return (
        <div>
          <div>{category}</div>
          {points.map((point, index) => (
      
              <div key={index}>
               {point.series.name} : {(point.value) + '건'}
              </div> 
      
     
          ))}
        </div>
      );
    };
  
    const sharedTooltipRender2 = (context) => <SharedTooltip2 {...context} />;

    const SharedTooltip3 = (props) => {
      const { category, points } = props;
      return (
        <div>
          <div>{category}</div>
          {points.map((point, index) => (
      
              <div key={index}>
               {point.series.name} : {(point.value) + '건'}
              </div> 
      
     
          ))}
        </div>
      );
    };
  
    const sharedTooltipRender3 = (context) => <SharedTooltip3 {...context} />;

    const SharedTooltip4 = (props) => {
      const { category, points } = props;
      return (
        <div>
          <div>{category}</div>
          {points.map((point, index) => (
      
              <div key={index}>
               {point.series.name} : {(point.value.toFixed(2)) + '%'}
              </div> 
      
     
          ))}
        </div>
      );
    };
  
    const sharedTooltipRender4 = (context) => <SharedTooltip4 {...context} />;

    const valueAxisLabels = {
      padding: 0,
      font: "10px Arial, sans-serif",
      step: 3,
    };

  const seriesLabelscontent = (e) => e.value ===0 || e.value === null || typeof e.value === 'undefined' ?'': (e.value / 100000000).toFixed(2) + '억';
  
    const seriesLabels = {
      visible: true,
      // Note that visible defaults to false
      padding: 3,
      font: "bold 11px Arial, sans-serif",
      position:'center',
      background :'none',
      content: seriesLabelscontent
    };


    const seriesLabelscontent2 = (e) =>e.value ===0 || e.value === null || typeof e.value === 'undefined'?'': e.value +'건';
  
    const seriesLabels2 = {
      visible: true,
      // Note that visible defaults to false
      padding: 3,
      font: "bold 11px Arial, sans-serif",
      position:'center',
      background :'none',
      content: seriesLabelscontent2
    };

    const seriesLabelscontent3= (e) =>e.value ===0 || e.value === null || typeof e.value === 'undefined'?'':  e.value.toFixed(2) +'%';
  
    const seriesLabels3 = {
      visible: true,
      // Note that visible defaults to false
      padding: 3,
      font: "bold 11px Arial, sans-serif",
      position:'center',
      background :'none',
      content: seriesLabelscontent3
    };


    

    

  return (
    <GridLayout style={{ height: BoxHeight }} rows={[{ height: '50%', }, { height: '50%', },]} cols={[{ width: '49%', }, { width: '49%', },]} gap={{ rows: 5, cols: 5, }}>

    <GridLayoutItem col={1} row={1} colSpan={1} rowSpan={1}>
    <Chart>
    <ChartTitle  text="외주/구매 발주현황(단위: 백만원)" />
    <ChartArea height={BoxHeight/2} />
    <ChartLegend position="bottom" orientation="horizontal" />
    <ChartTooltip shared={true} render={sharedTooltipRender} />
    <ChartValueAxis>
      <ChartValueAxisItem labels={{ content: labelContentAxis}}/>
    </ChartValueAxis>
    <ChartSeries>
    <ChartSeriesItem color={sort_data.color} type="column"spacing={0}  data={sort_data} field="계획분" categoryField="xaxis" name="계획분" autoFit={true} labels={seriesLabels} />
    <ChartSeriesItem color={sort_data.color} type="column" data={sort_data} field="실적분" categoryField="xaxis" name="실적분" autoFit={true} labels={seriesLabels}/>
    </ChartSeries>
    </Chart>
    </GridLayoutItem>

    <GridLayoutItem col={2} row={1} colSpan={1} rowSpan={1}>
    <Chart>
    <ChartTitle  text="외주/구매 발주현황(단위: 건수)" />
    <ChartArea height={BoxHeight/2} />
    <ChartTooltip shared={true} render={sharedTooltipRender2} />
    <ChartLegend position="bottom" orientation="horizontal" />
    <ChartValueAxis>
    <ChartValueAxisItem labels={valueAxisLabels}/>
    </ChartValueAxis>
    <ChartSeries>
    <ChartSeriesItem color={sort_data.color} type="column"spacing={0}  data={sort_data} field="계획건수" categoryField="xaxis" name="계획분" autoFit={true}labels={seriesLabels2} />
    <ChartSeriesItem color={sort_data.color} type="column" data={sort_data} field="실적건수" categoryField="xaxis" name="실적분" autoFit={true} labels={seriesLabels2}/>
    </ChartSeries>
    </Chart>
    </GridLayoutItem>
    <GridLayoutItem col={1} row={2} colSpan={1} rowSpan={1}>
    <Chart>
    <ChartTitle  text="발주현황(단위: 건)" />
    <ChartArea height={BoxHeight/2} />
    <ChartTooltip shared={true} render={sharedTooltipRender3} />
    <ChartLegend position="bottom" orientation="horizontal" />
    <ChartValueAxis>
      <ChartValueAxisItem />
    </ChartValueAxis>
    <ChartSeries>
    <ChartSeriesItem color={total_count.color} type="bar"spacing={0}  data={total_count} field="계획분" categoryField="xaxis" name="계획분" autoFit={true}labels={seriesLabels2} />
    <ChartSeriesItem color={total_count.color} type="bar" data={total_count} field="실적분" categoryField="xaxis" name="실적분" autoFit={true} labels={seriesLabels2}/>
    </ChartSeries>

    </Chart>
    </GridLayoutItem>
    <GridLayoutItem col={2} row={2} colSpan={1} rowSpan={1}>
    <Chart>
    <ChartTitle  text="낙찰율(단위: %)" />
    <ChartArea height={BoxHeight/2} />
    <ChartLegend position="bottom" orientation="horizontal" />
    <ChartTooltip shared={true} render={sharedTooltipRender4} />

    <ChartValueAxis>
      <ChartValueAxisItem min={0} max={100} />
    </ChartValueAxis>

    <ChartSeries>
    <ChartSeriesItem color={total_total.color} type="bar"spacing={0}  data={total_total} field="계획분" categoryField="xaxis" name="계획분" autoFit={true} labels={seriesLabels3}/>
    <ChartSeriesItem color={total_total.color} type="bar" data={total_total} field="실적분" categoryField="xaxis" name="실적분" autoFit={true}labels={seriesLabels3} />
    </ChartSeries>

    </Chart>
    </GridLayoutItem>

</GridLayout>


  );


};


export default ProcurementC

