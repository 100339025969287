import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';


const containerStyle = {
    width: '100%',
    height: '100%'
};

const m_style = [
    {
        "featureType": "all",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "weight": "2.00"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#9c9c9c"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "all",
        "stylers": [
            {
                "color": "#f2f2f2"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "all",
        "stylers": [
            {
                "saturation": -100
            },
            {
                "lightness": 45
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#eeeeee"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#7b7b7b"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "simplified"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#46bcec"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#c8d7d4"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#070707"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "color": "#ffffff"
            }
        ]
    }
]

const Map = () => {

    const [centerdata, setCenter] = useState({
        lat: -3.745,
        lng: -38.523
    });
    const { project_code } = useParams();


    useEffect(() => {
        const fetchData = async () => {
            const projects = await axios.get(`https://dash.dtsolution.kr/api/getProject`)
            const lat_o = projects.data.data.data.filter(function (el) {
                return el.ugenProjectNumber === project_code
            })[0].uuu_latitude;
            const lon_o = projects.data.data.data.filter(function (el) {
                return el.ugenProjectNumber === project_code
            })[0].uuu_longitude;

            const lat_n = parseFloat(lat_o, 10)

            const lon_n = parseFloat(lon_o, 10)

            const xy = { lat: lat_n, lng: lon_n }

            setCenter(xy)

        };

        fetchData();
    }, [project_code]);
    return (
        <LoadScript
            googleMapsApiKey="AIzaSyBP-17SYLdi3X2v-qh8k-cK8obmONubMUU"
        >


            <GoogleMap options={{
                styles: m_style,
            }}
                mapContainerStyle={containerStyle}

                center={centerdata}

                zoom={10}

            >

                {/* <Marker position={centerdata} /> */}
                <Marker position={centerdata} />
                <></>
            </GoogleMap>
        </LoadScript>
    )
}

export default Map


