import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { Card, CardTitle, CardBody, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
  Chart,
  ChartArea,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis, ChartCategoryAxisItem, ChartValueAxis, ChartValueAxisItem, ChartTooltip
} from "@progress/kendo-react-charts";
import { ResponsivePie } from '@nivo/pie';

const COLORS = ['#f37c21', '#fa9548', '#fa9f5a', '#ed8d45', '#f0a369', '#f89b6c', '#f7a881'];

const Incident = ({ positiondata, setIncidentYear }) => {

  const [response, setreponse] = useState([]);
  const { project_code } = useParams();


  useEffect(() => {
    const fetchData = async () => {

      const res = await axios.get(`https://dash.dtsolution.kr/api/getincidient?path=${project_code}`)
      setreponse(res.data.data.data)
    };

    fetchData();
  }, [project_code]);



  const backdata = response.filter(v => v.IncidentDateDO !== null)
  .reduce((c, v) => {
    const lineItems = v._bp_lineitems || []; // check if _bp_lineitems is defined
    const items = lineItems
      .filter(o => o.uuu_tab_id === '재해자') // check if uuu_tab_id is defined
      .map(o => Object.assign(o, { 
        "Type": o.IRIncidentTypeText, 
        "Death": o.IRDeathText, 
        "Year": v.IncidentDateDO?.slice(8, 10) + "년", // use optional chaining to check if IncidentDateDO is defined
        "Month": v.IncidentDateDO?.slice(0, 2) + "월", // use optional chaining to check if IncidentDateDO is defined
        "YearMonth": v.IncidentDateDO?.slice(8, 10) + "년 " + v.IncidentDateDO?.slice(0, 2) + "월" // use optional chaining to check if IncidentDateDO is defined
      }));
    return c.concat(items);
  }, []);

    
  const piedata2 = _.map(_.countBy(backdata, 'Type'), (val, key) => ({ name: key, value: val })).map((v, index) => ({ ...v, color: COLORS[index], id:v.name }));

  var year = new Date().toISOString().slice(0, 10).slice(2, 4) + "년"
  var month = new Date().toISOString().slice(0, 10).slice(2, 4) + "년 " + new Date().toISOString().slice(0, 10).slice(5, 7) + "월"

  const year_filter = _.filter(backdata, { 'Year': year });

  const monthcount = _.filter(backdata, { 'YearMonth': month }).length

  const count_year = 
  _(year_filter)
  .groupBy("YearMonth")
  .map((objs, key) => ({
      'YearMonth': key,
      "month":objs[0]['Month'],
      'count':objs.filter(v=>v.YearMonth === key).length ,
  }))
  .value();

  



  function dateRange(startDate, endDate) {
    var start = startDate.split('/');
    var end = endDate.split('/');
    var startYear = parseInt(start[2].slice(2, 4));
    var endYear = parseInt(end[2].slice(2, 4));
    var dates = [];

    for (var i = startYear; i <= endYear; i++) {
      var endMonth = i !== endYear ? 11 : parseInt(end[0]) - 1;
      var startMon = i === startYear ? parseInt(start[0]) - 1 : 0;
      for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        var month = j + 1;
        var displayYear = i + "년"
        var displayMonth = month < 10 ? '0' + month + '월' : month + '월';
        dates.push([displayYear, displayMonth].join(' '));
      }
    }
    return dates;
  }

  const chartdate = dateRange(String('01/01/' + (new Date().toISOString().slice(0, 10).slice(0, 4))), String('12/01/' + (new Date().toISOString().slice(0, 10).slice(0, 4)))).map((data) => { return { name: data } });
  const chartdate2 = dateRange(String('01/01/' + (new Date().toISOString().slice(0, 10).slice(0, 4))), String(((new Date().toISOString().slice(0, 10).slice(5, 7))) + '/01/' + (new Date().toISOString().slice(0, 10).slice(0, 4)))).map((data) => { return { name: data, c_value: 0 } });


  let cumsum = 0;

  const composedata_o = chartdate2.map(v => ({ ...v, ...count_year.find(sp => sp.YearMonth === v.name) })).map((v)=>({...v, month:v.name.slice(-3)}))

  const composedata_o2 = composedata_o.map((v ) => ({...v, c_value: cumsum +=(v.count === undefined ? 0:v.count)  }));
  const chartdata =chartdate.map(v => ({ ...v, ...composedata_o2.find(sp => sp.name === v.name) })).map((v)=>({...v, month:v.name.slice(-3)}))
 

  const death_o = backdata.filter(item => item.Death !== undefined).filter(item => item.Death === '사망')
  const death_o_count = _.map(_.countBy(death_o, 'Death'), (val) => ({ name: "사망", value: val }));

  const death_x = backdata.filter(item => item.Death !== undefined).filter(item => item.Death !== '사망')
  const death_x_count = _.map(_.countBy(death_x, 'Death'), (val) => ({ name: "부상", value: val }));

  

  const piedata = [...death_o_count, ...death_x_count].map((v, index) => ({ ...v, color: COLORS[index] , id:v.name}));


  var today = new Date().toISOString().slice(0, 10).slice(5, 7) + "월";
  var today_year = new Date().toISOString().slice(0, 10).slice(0, 4) + "년"

  const [BoxHeight, setBoxHeight] = useState();

  useEffect(() => {
    if (positiondata.length > 0) {
      const boxHeight = positiondata[2];
      setBoxHeight(250 + 150 * (boxHeight.rowSpan - 2));
    }
  }, [positiondata]);


  const valueAxisLabels = {
    padding: 0,
    font: "10px Arial, sans-serif",
    step: 10,
  };

  const labelContent = (props) => {
    return `${props.dataItem.name} ${props.dataItem.value}건 `;
  };

  useEffect(() => {
    setIncidentYear(today_year);
  });


  const seriesLabels = {
    visible: true,
    position:'inside',
    background:'none'
    
  };

  return (
    <GridLayout style={{ height: BoxHeight }}
      rows={[
        {
          height: '13%',
        },
        {
          height: '13%',
        },
        {
          height: '13%',
        },
        {
          height: '10%',
        },
        {
          height: '13%',
        },
        {
          height: '13%',
        },
        {
          height: '13%',
        },
        {
          height: '18%',
        }

      ]}

      cols={[
        {
          width: '24.5%',
        },
        {
          width: '24.5%',
        },
        {
          width: '24.5%',
        },
        {
          width: '24.5%',
        },


      ]}
      gap={{
        rows: 5,
        cols: 5,
      }}>

      <GridLayoutItem col={1} row={1} colSpan={1} rowSpan={2}>

        <Card
          orientation='horizontal'
          style={{ width: '100%', height:BoxHeight/4}}
        >
          <CardBody className='cardcenter'>
            <CardTitle style={{fontSize:'1rem'}}>{today} 현장</CardTitle>
            <CardTitle  style={{fontWeight:'bolder'}}>{monthcount} </CardTitle>
          </CardBody>
        </Card>
      </GridLayoutItem>
      <GridLayoutItem col={2} row={1} colSpan={3} rowSpan={2}>
        <Chart style={{marginTop:((BoxHeight / 100) ) * -1}}>
          <ChartArea  height={BoxHeight/3}/>
          <ChartTooltip />
          <ChartLegend visible={false} />
          <ChartValueAxis>
            <ChartValueAxisItem labels={valueAxisLabels} majorGridLines={0} />
          </ChartValueAxis>

          <ChartSeries>
            <ChartSeriesItem
              type="column"
              data={chartdata}
              field="count"
              categoryField="month"
              autoFit={true}
              labels={seriesLabels} 
            />

            <ChartSeriesItem
              type="line"
              data={chartdata}
              field="c_value"
              categoryField="month"
              autoFit={true}
            />
          </ChartSeries>
        </Chart>
      </GridLayoutItem>
      <GridLayoutItem col={1} row={3} colSpan={2} rowSpan={1}style={{ textAlign: 'center', zIndex: '100' }}></GridLayoutItem>

      
      <GridLayoutItem col={1} row={4} colSpan={2} rowSpan={1}style={{ textAlign: 'center', zIndex: '100' }}><div className='charttitle'>현장누적</div></GridLayoutItem>
      <GridLayoutItem col={3} row={4} colSpan={2} rowSpan={1}style={{ textAlign: 'center', zIndex: '100' }}><div className='charttitle'>재해유형</div></GridLayoutItem>
      <GridLayoutItem col={1} row={5} colSpan={2} rowSpan={4}>

        {piedata.length !== 0 ?
                    <ResponsivePie
                    // colors={['#616CFF', '#429BF4', '#42B7F4', '#9E67FF', '#FF6E6E', '#BC99F2', '#2075A1', '#33B8B4', 'DBF3FF', '#42EEF4']}
                    colors={{ scheme: 'oranges' }}
                    className='pie'
                    data={piedata}
                    margin={{ top: 25, right: 40, bottom: 25, left: 40 }}
                    valueFormat=" >-"
                    cornerRadius={2}
                    innerRadius={0}
                    activeOuterRadiusOffset={8}
                    borderWidth={1}
                    borderColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                0.2
                            ]
                        ]
                    }}
                    arcLinkLabel={d => (
                        <React.Fragment>
                            <tspan style={{ fontSize: 10 }} x="0" dy="-0.6em">{d.id}</tspan>
                            <tspan style={{ fontSize: 10 }} x="0" dy="1.2em">{"(" + ((d.value / _.sumBy(piedata, 'value')) * 100).toFixed(0) + "%)"}</tspan>
                        </React.Fragment>
                    )}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextOffset={8}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsDiagonalLength={10}
                    arcLinkLabelsStraightLength={8}
                    arcLinkLabelsColor={{ from: 'color' }}
                    enableArcLabels={true}
                    arcLabel={function (e) { return e.value }}
                    arcLabelsRadiusOffset={0.5}
                    arcLabelsSkipAngle={0}
                    arcLabelsTextColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                2
                            ]
                        ]
                    }}

                    legends={[]}
                />
          : <div style={{ margin: '0 auto', width:(BoxHeight/2)*1.5, position: 'relative' }}><img src='/image/na.png' alt='n/a' style={{ margin: '10px auto', objectFit: 'contain', width: '70%', position: 'relative', left: '15%' }} /></div>}

      </GridLayoutItem>
      <GridLayoutItem col={3} row={5} colSpan={2} rowSpan={4}>

        {piedata2.length !== 0 ?
                    <ResponsivePie
                    // colors={['#616CFF', '#429BF4', '#42B7F4', '#9E67FF', '#FF6E6E', '#BC99F2', '#2075A1', '#33B8B4', 'DBF3FF', '#42EEF4']}
                    colors={{ scheme: 'oranges' }}
                    className='pie'
                    data={piedata2}
                    margin={{ top: 25, right: 40, bottom: 25, left: 40 }}
                    valueFormat=" >-"
                    cornerRadius={2}
                    innerRadius={0}
                    activeOuterRadiusOffset={8}
                    borderWidth={1}
                    borderColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                0.2
                            ]
                        ]
                    }}
                    arcLinkLabel={d => (
                        <React.Fragment>
                            <tspan style={{ fontSize: 10 }} x="0" dy="-0.6em">{d.id}</tspan>
                            <tspan style={{ fontSize: 10 }} x="0" dy="1.2em">{"(" + ((d.value / _.sumBy(piedata2, 'value')) * 100).toFixed(0) + "%)"}</tspan>
                        </React.Fragment>
                    )}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextOffset={8}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsDiagonalLength={10}
                    arcLinkLabelsStraightLength={8}
                    arcLinkLabelsColor={{ from: 'color' }}
                    enableArcLabels={true}
                    arcLabel={function (e) { return e.value }}
                    arcLabelsRadiusOffset={0.5}
                    arcLabelsSkipAngle={0}
                    arcLabelsTextColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                2
                            ]
                        ]
                    }}

                    legends={[]}
                />
          : <div style={{ margin: '0 auto', width:(BoxHeight/2)*1.5, position: 'relative' }}><img src='/image/na.png' alt='n/a' style={{ margin: '10px auto', objectFit: 'contain', width: '70%', position: 'relative', left: '15%' }} /></div>}
      </GridLayoutItem>


    </GridLayout>

  );
};

export default Incident

