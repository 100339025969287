import React, { useState, useEffect } from 'react';
import { TileLayout } from "@progress/kendo-react-layout";
import '@progress/kendo-theme-default/dist/all.css';
import useBreakpoint from "use-breakpoint";
import _ from 'lodash';
import { Dialog } from "@progress/kendo-react-dialogs";
import { ListView } from "@progress/kendo-react-listview";
import { Switch } from "@progress/kendo-react-inputs";
import "../kendocustom.css";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import ProcurementC from "../company/procurementc";
// import ContractC from "../company/companycontract";


const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280, };


const GridProcurementC = () => {

    const { breakpoint } = useBreakpoint(BREAKPOINTS, 'desktop')


    const GRID_CONFIG = {
        mobile: [

            {
                col: 1,
                colSpan: 4,
                rowSpan: 4,
                status: true,
            },

            // {
            //     col: 1,
            //     colSpan: 4,
            //     rowSpan: 2,
            //     status: true,
            // }


        ],

        tablet: [

            {
                col: 1,
                colSpan: 4,
                rowSpan: 3,
                status: true,
            },

            // {
            //     col: 1,
            //     colSpan: 4,
            //     rowSpan: 2,
            //     status: true,
            // }
        ],

        desktop: [

            {
                col: 1,
                colSpan: 4,
                rowSpan: 3,
                status: true,
            },

            // {
            //     col: 1,
            //     colSpan: 4,
            //     rowSpan: 2,
            //     status: true,
            // }
        ]
    }

    const [GRIDCONFIG, setGRIDCONFIG] = useState(GRID_CONFIG);

    const GRIDCONFIG_filterd = { mobile: _.filter(GRIDCONFIG['mobile'], { 'status': true }), tablet: _.filter(GRIDCONFIG['tablet'], { 'status': true }), desktop: _.filter(GRIDCONFIG['desktop'], { 'status': true }) }

    useEffect(() => {
        setPositionData(GRIDCONFIG_filterd[breakpoint])
    }, [breakpoint,]);

    const [positiondata, setPositionData] = useState(GRID_CONFIG['desktop']);

    const [fixblock, setfixblock] = useState(true);

    const togglefix = () => {
        setfixblock(!fixblock);
    };


    const tiles = [
        {
            item: <ProcurementC positiondata={positiondata} />,
            status: true,
            key: '외주/구매 발주현황',
            resizable: fixblock,
            reorderable: fixblock,


        },
        // {
        //     header: <div>전체 계약 현황</div>,
        //     body: <ContractC/>,
        //     status: true,
        //     key: '전체 계약 현황',
        //     resizable: fixblock,
        //     reorderable: fixblock,


        // },

    ]

    const handleReposition = e => {

        setPositionData(e.value);
        // setPositionData(e.value.map((v) => { return { col: v.col, row: v.row, rowSpan: v.rowSpan, colSpan: v.colSpan } }));
    };

    const [tile, settile] = useState(tiles);
    const tile_filtered = _.filter(tile, ['status', true]);

    useEffect(() => {
        settile(tiles);
    }, [positiondata, fixblock]);


    const toggle_function = (key) => {

        const newData = tile.map(item => {
            if (item.key === key) {
                item.status = item.status === true ? false : true;
            }
            return item;
        })
        settile(newData);


        let col_index = _.findIndex(tiles, ['key', key])

        const mobile = GRIDCONFIG['mobile'].map((item, index) => {
            if (index === col_index) {
                item.status = item.status === true ? false : true;

            }
            return item;
        })
        const tablet = GRIDCONFIG['tablet'].map((item, index) => {
            if (index === col_index) {
                item.status = item.status === true ? false : true;

            }
            return item;
        })
        const desktop = GRIDCONFIG['desktop'].map((item, index) => {
            if (index === col_index) {
                item.status = item.status === true ? false : true;

            }
            return item;
        })

        setGRIDCONFIG({ mobile: mobile, tablet: tablet, desktop: desktop });

    };

    const [visibleDialog, setVisibleDialog] = useState(true);

    const toggleDialog = () => {
        setVisibleDialog(!visibleDialog);
    };


    const MyItemRender = (props) => {
        let item = props.dataItem;
        return (
            <div
                className="border-bottom align-middle"
                style={{
                    margin: 10,
                    display: 'flex',
                    borderBottom: '2px'
                }}
            >
                <div style={{ width: '70%', flex: '1' }}>
                    {item.key}

                </div>
                <div style={{ width: '30%', flex: '1', position: 'absolute', right: '0' }}>
                    <Switch size={'small'} checked={item.status} onChange={() => toggle_function(item.key)} />

                </div>
            </div>
        );
    };

    const flow = "row dense";

    return (
        <div>
            {visibleDialog && (
                <div style={{ position: 'absolute', top: '0px', right: '20px', zIndex: '100' }}>
                    <ButtonGroup>
                        <Button title="블록 온오프" iconClass="k-icon k-i-grid" onClick={toggleDialog}></Button >
                        <Button title="고정하기" iconClass={fixblock ? "k-icon k-i-unpin" : "k-icon k-i-pin"} togglable={true} onClick={togglefix}></Button>
                    </ButtonGroup>
                </div>
            )}

            <TileLayout
                columns={4}
                rowHeight={180}
                positions={positiondata}
                gap={{
                    rows: 10,
                    columns: 10
                }}
                items={tile_filtered}
                onReposition={handleReposition}
                autoFlow={flow}
            />

            {!visibleDialog && (
                <Dialog title={"블록리스트"} onClose={toggleDialog}>
                    <div className='row'>
                        <ListView
                            data={tile}
                            item={MyItemRender}
                            style={{
                                width: "100%",
                                minWidth: 250
                            }}
                        />
                    </div>
                </Dialog>
            )}
        </div>
    )


};

export default GridProcurementC;
