import React, { useEffect, useState, } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { Card, CardTitle, CardBody, GridLayout, GridLayoutItem } from "@progress/kendo-react-layout";
import {
    Chart,
    ChartArea,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis, ChartCategoryAxisItem, ChartValueAxis, ChartValueAxisItem, ChartTooltip
} from "@progress/kendo-react-charts";
import "hammerjs";
import { ResponsivePie } from '@nivo/pie';


const Hse1 = ({ positiondata }) => {

    const [response, setreponse] = useState([]);
    const { project_code } = useParams();

    useEffect(() => {
        const fetchData = async () => {

            const res = await axios.get(`https://dash.dtsolution.kr/api/getCAR?path=${project_code}`)
            setreponse(res.data.data.data)

        };

        fetchData();
    }, [project_code]);


    var today = new Date().toISOString().slice(0, 10).slice(5, 7) + "월";

    const COLORS = ['#f37c21', '#fa9548', '#fa9f5a', '#ed8d45', '#f0a369', '#f89b6c', '#f7a881', '#f37c21', '#fa9548', '#fa9f5a', '#ed8d45', '#f0a369', '#f89b6c', '#f7a881'];

    const counttotalreq = response.length;
    const piedata = _.map(_.countBy(response, 'HSERiskCatSPD'), (val, key) => ({ name: key, value: val })).map((v, index) => ({ ...v, color: COLORS[index], id:v.name }));
    // const piedata = _.map(_.countBy(response, 'HSERiskCatSPD'), (val, key) => ({ name: key, value: val })).map((v, index) => ({ ...v, color:COLORS[index] }));


    const car_with_month = response.map(value => ({ month: value.udateofIssueDOP.slice(8, 10) + "년 " + value.udateofIssueDOP.slice(0, 2) + "월", ...value }));

    var month = new Date().toISOString().slice(0, 10).slice(2, 4) + "년 " + new Date().toISOString().slice(0, 10).slice(5, 7) + "월"

    const current_car = _.filter(car_with_month, { 'month': month })
    const countcurrent = current_car.length
    const current_data_top = _.map(_.countBy(current_car, 'creator_id'), (val, key) => ({ name: key.slice(0, key.indexOf("(")), value: val }));
    const c_data_sort = _.sortBy(current_data_top, 'value').reverse();
    const current = c_data_sort.slice(Math.max(c_data_sort.length - 3, 0));

    const columnData = current.map(v => v.value);
    const categoryData = current.map(v => v.name);


    const [BoxHeight, setBoxHeight] = useState();

    useEffect(() => {
        if (positiondata.length > 0) {
            const boxHeight = positiondata[1];
            setBoxHeight(250 + 150 * (boxHeight.rowSpan - 2));
        }
    }, [positiondata]);

    const labelContent = (props) => {

        return `${props.dataItem.name} ${props.dataItem.value}건 `;
    };


    const valueAxisLabels = {
        padding: 0,
        font: "10px Arial, sans-serif",
        step: 5,
    };


    const seriesLabels = {
        visible: true,
        // Note that visible defaults to false
        padding: 3,
        font: "bold 0.8rem Arial, sans-serif",
        position: 'Top',
        background: 'none',
    };




    return (
        <GridLayout style={{ height: BoxHeight }}
            rows={[
                {
                    height: '13%',
                },
                {
                    height: '13%',
                },
                {
                    height: '13%',
                },
                {
                    height: '13%',
                },
                {
                    height: '13%',
                },
                {
                    height: '13%',
                },
                {
                    height: '13%',
                },
                {
                    height: '13%',
                }

            ]}

            cols={[
                {
                    width: '49%',
                },
                {
                    width: '49%',
                },


            ]}
            gap={{
                rows: 5,
                cols: 5,
            }}>
            <GridLayoutItem col={1} row={1} colSpan={1} rowSpan={2}>
                <Card
                    orientation='horizontal'
                    style={{ width: '100%', zIndex: '100' }}
                >

                    <CardBody className='cardcenter'>
                        <CardTitle>총 누적</CardTitle>
                        <CardTitle style={{ fontWeight: 'bolder' }}>{counttotalreq}</CardTitle>
                    </CardBody>


                </Card>

            </GridLayoutItem>
            <GridLayoutItem col={2} row={1} colSpan={1} rowSpan={2}>
                <Card
                    orientation='horizontal'
                    style={{ width: '100%', zIndex: '100' }}
                >

                    <CardBody className='cardcenter'>

                        <CardTitle >{today} 현장</CardTitle>
                        <CardTitle style={{ fontWeight: 'bolder' }}>{countcurrent} </CardTitle>
                    </CardBody>



                </Card>
            </GridLayoutItem>
            <GridLayoutItem col={1} row={3} colSpan={1} rowSpan={1} style={{ textAlign: 'center', zIndex: '100' }}>
                <div className='charttitle'>위험유형</div>
            </GridLayoutItem>
            <GridLayoutItem col={2} row={3} colSpan={1} rowSpan={1} style={{ textAlign: 'center', zIndex: '100' }}>
                <div className='charttitle'>{today} TOP 3</div>
            </GridLayoutItem>
            <GridLayoutItem col={1} row={4} colSpan={1} rowSpan={5}>
                {piedata.length > 0 ?
                <ResponsivePie
                    // colors={['#616CFF', '#429BF4', '#42B7F4', '#9E67FF', '#FF6E6E', '#BC99F2', '#2075A1', '#33B8B4', 'DBF3FF', '#42EEF4']}
                    colors={{ scheme: 'oranges' }}
                    className='pie'
                    data={piedata}
                    margin={{ top: 25, right: 40, bottom: 25, left: 40 }}
                    valueFormat=" >-"
                    cornerRadius={2}
                    innerRadius={0}
                    activeOuterRadiusOffset={8}
                    borderWidth={1}
                    borderColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                0.2
                            ]
                        ]
                    }}
                    arcLinkLabel={d => (
                        <React.Fragment>
                            <tspan style={{ fontSize: 10 }} x="0" dy="-0.6em">{d.id}</tspan>
                            <tspan style={{ fontSize: 10 }} x="0" dy="1.2em">{"(" + ((d.value / _.sumBy(piedata, 'value')) * 100).toFixed(0) + "%)"}</tspan>
                        </React.Fragment>
                    )}
                    arcLinkLabelsSkipAngle={10}
                    arcLinkLabelsTextOffset={8}
                    arcLinkLabelsTextColor="#333333"
                    arcLinkLabelsDiagonalLength={10}
                    arcLinkLabelsStraightLength={8}
                    arcLinkLabelsColor={{ from: 'color' }}
                    enableArcLabels={true}
                    arcLabel={function (e) { return e.value }}
                    arcLabelsRadiusOffset={0.5}
                    arcLabelsSkipAngle={0}
                    arcLabelsTextColor={{
                        from: 'color',
                        modifiers: [
                            [
                                'darker',
                                2
                            ]
                        ]
                    }}

                    legends={[]}
                /> :
                    <div style={{ margin: '0 auto', width: '60%' , textAlign:'center' }}><img src='/image/na.png' alt='n/a' style={{ margin: '0 auto', objectFit: 'contain', width: '100%' }} /></div>}

            </GridLayoutItem>
            <GridLayoutItem col={2} row={4} colSpan={1} rowSpan={5}>
                {current.length > 0 ?
                    <Chart>
                        <ChartArea height={BoxHeight * 0.8} />

                        <ChartValueAxis>
                            <ChartValueAxisItem labels={valueAxisLabels} majorGridLines={0} />
                        </ChartValueAxis>
                        <ChartTooltip />
                        <ChartCategoryAxis>
                            <ChartCategoryAxisItem categories={categoryData}>
                            </ChartCategoryAxisItem>
                        </ChartCategoryAxis>
                        <ChartSeries>

                            <ChartSeriesItem type="column" data={columnData} autoFit={true} labels={seriesLabels} />
                        </ChartSeries>
                    </Chart> :
                    <div style={{ margin: '0 auto', width: '60%' , textAlign:'center'}}><img src='/image/barna.png' alt='n/a' style={{ margin: '0 auto', objectFit: 'contain', width: '100%' }} /></div>}
            </GridLayoutItem>

        </GridLayout>





    );
};

export default Hse1

