import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';
import {
    GridLayout,
    GridLayoutItem, 
} from "@progress/kendo-react-layout";
import moment from 'moment';
import Progress2 from './progress';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}


function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}





const Meeting3 = () => {

    const { height, width } = useWindowDimensions();


  const { project_code } = useParams();
  const [data1, setdata1] = useState([]);

  const today = moment().format("YYYY-MM")

  useEffect(() => {
    const fetchData = async () => {
        const res = await axios.get(`https://dash.dtsolution.kr/api/getmeeting1?path=${project_code}`) 
        

        setdata1(_.sortBy(_.filter(res.data.data.data,  function (o) { return moment(o.smmMeetingDO).format("YYYY-MM") === today;}),'record_no')
        .map((v)=> ({text:v.smmProgressRT}))[0]
        )

        
   
      };
      fetchData();
  }, [project_code, today]);

  const positiondata = [
    {
      col: 1,
      colSpan: 4,
      rowSpan: 3,
      status: true,
    },
    {
      col: 5,
      colSpan: 8,
      rowSpan: 3,
      status: true,
    },
    {
      col: 1,
      colSpan: 4,
      status: true,
      rowSpan: 3,
    },
    {
      col: 5,
      colSpan: 8,
      status: true,
      rowSpan: 3,
    },
    {
      col: 5,
      colSpan: 8,
      status: true,
      rowSpan: 2,
    },
    {
      col: 5,
      colSpan: 8,
      status: true,
      rowSpan: 2.2,
    },
 
 
  ]

  return (
    <>
     <GridLayout style={{ height: height*0.8 ,width:width}}
                rows={[
                    {height: '50%',}, {height: '50%',},
                ]}

                cols={[   
                    {width: '100%'}, ]}

                gap={{
                    rows: 10,
                    cols: 0,
                }}>

                  <GridLayoutItem row={1} col={1} style={{backgroundColor:'#fafafa',}}>
                    <Progress2 positiondata={positiondata} />
                  </GridLayoutItem>
                  <GridLayoutItem row={2} col={1} style={{backgroundColor:'#fafafa',padding:'10px', overflow:'auto'}}>
                    <div className='ment' dangerouslySetInnerHTML={{ __html:data1.text }}  />
                  </GridLayoutItem>
      

                </GridLayout>
    </>
  )

    
};

export default Meeting3;